import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ActionsRenderer } from "../../components/renderers/ActionsRenderer";
import { useNavigate } from "react-router-dom";
import { availableRoutes } from "../../constants/AvailableRoutes";
import InformationApi, { InformationEntity } from "../../api/InformationApi";

export function InformationTable() {
  const [information, setInformation] = useState<InformationEntity[]>([]);

  const navigate = useNavigate();

  const columnDefs = [
    {
      field: "id",
      headerName: "",
      width: 80,
      cellRenderer: ActionsRenderer,
      cellRendererParams: {
        edit: (field: string) => {
          navigate(availableRoutes.adminInformationTable + "/" + field);
        },
        remove: (field: string) => {
          alert("delete " + field);
        },
        includeRemove: true,
      },
      filter: false,
      sortable: false,
    },
    { field: "title", filter: true, resizable: true },
    {
      field: "excerpt",
      filter: true,
      resizable: true,
      width: 300,
    },
    { field: "slug", filter: true, resizable: true },
    { field: "public", filter: true, resizable: true },
    { field: "country", filter: true, resizable: true },
  ];

  const defaultColDef = {
    sortable: true,
    autosize: true,
  };

  useEffect(() => {
    InformationApi.listInformation().then((result) => {
      setInformation(result.sort((a) => (a.isPublic ? -1 : 1)));
    });
  }, []);

  return (
    <div className="container">
      <div className="flex mb-4 items-center mt-4">
        <div className="title">Information</div>
        <div
          className="button ml-auto"
          onClick={() => {
            navigate(availableRoutes.adminInformationTable + "/new");
          }}
        >
          Add information
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ height: "800px" }}>
        <AgGridReact
          rowData={information}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
        />
      </div>
    </div>
  );
}
