export const headerLinks = [
  "information",
  "academy",
  "startSmart",
  "matchmaking",
  "unbounded",
  "funding",
  "events",
  "inspiration",
  "forCorporates",
  "forHigherEdu",
  "forInvestors",
  "forMentors",
  "faq",
  "coaching",
  "mentoring",
  "advice",
  "training",
  "community",
  "podcasts",
  "startupCafe",
  "contact",
] as const;

export type HeaderLink = typeof headerLinks[number];

export const headerLinkToLabel: { [link in HeaderLink]: string } = {
  information: "Information",
  academy: "Academy",
  startSmart: "Start Smart",
  matchmaking: "Join Forces",
  unbounded: "Unbounded",
  funding: "Funding",
  events: "Events",
  inspiration: "Inspiration",
  forCorporates: "For corporates",
  forHigherEdu: "For higher education",
  forInvestors: "For investors",
  forMentors: "For mentors",
  faq: "FAQ",
  coaching: "Coaching",
  mentoring: "Mentoring",
  advice: "Advice",
  training: "Training",
  community: "Community",
  podcasts: "Podcasts",
  startupCafe: "Startups Café",
  contact: "Contact",
};
