export enum Role {
  ADMIN = "ADMIN",
  STUDENT = "STUDENT",
  PHD = "PHD",
  ALUMNUS = "ALUMNUS",
  COMPANY = "COMPANY",
  INVESTOR = "INVESTOR",
  MENTOR = "MENTOR",
  HIGHER_EDU = "HIGHER_EDU",
}
