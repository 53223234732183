import { Role } from "./enums/Role";
import { languages } from "./constants/Languages";
import { countries } from "./constants/Countries";
import { HeaderLinkItem } from "./components/Header";
import { HeaderLinkVisibility } from "./api/SettingsApi";
import { HTMLAttributeAnchorTarget } from "react";

export function getParsedToken():
  | { [key: string]: string | number }
  | undefined {
  const token = localStorage.getItem("token");
  if (!token) {
    return undefined;
  }

  const base64Url = token.split(".")[1];
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const jsonPayload = decodeURIComponent(
    window
      .atob(base64)
      .split("")
      .map(function (c) {
        return "%" + ("00" + c.charCodeAt(0).toString(16)).slice(-2);
      })
      .join("")
  );

  return JSON.parse(jsonPayload);
}

export function getRoleFromToken(): Role | undefined {
  return getParsedToken()?.role as Role;
}

export function snakeCase(item: string) {
  return item.replaceAll(" ", "_").toLowerCase();
}

export function getLanguageNameFromCode(code: string) {
  return languages.find((l) => l.code === code)?.name || "";
}

export function getCountryNameFromCode(code: string) {
  return countries.find((l) => l.code === code)?.name || "";
}

const imageExtensions = [
  ".apng",
  ".avif",
  ".gif",
  ".jpg",
  ".jpeg",
  ".jfif",
  ".pjpeg",
  ".pjp",
  ".png",
  ".svg",
  ".webp",
];

export function isImage(url: string) {
  let isImage = false;
  for (const extension of imageExtensions) {
    if (url.toLowerCase().includes(extension)) {
      isImage = true;
      break;
    }
  }

  return isImage;
}

export function roleToRoleString(role: Role) {
  switch (role) {
    case Role.ADMIN:
      return "admin";
    case Role.STUDENT:
      return "student";
    case Role.ALUMNUS:
      return "alumnus";
    case Role.PHD:
      return "phd";
    case Role.COMPANY:
      return "corporate";
    case Role.HIGHER_EDU:
      return "higher_education";
    case Role.INVESTOR:
      return "investor";
    case Role.MENTOR:
      return "mentor";
    default:
      return "student";
  }
}

export function roleStringToRole(role: string) {
  switch (role) {
    case "admin":
      return Role.ADMIN;
    case "student":
      return Role.STUDENT;
    case "alumnus":
      return Role.ALUMNUS;
    case "phd":
      return Role.PHD;
    case "corporate":
      return Role.COMPANY;
    case "higher_education":
      return Role.HIGHER_EDU;
    case "investor":
      return Role.INVESTOR;
    case "mentor":
      return Role.MENTOR;
    default:
      return Role.STUDENT;
  }
}

export function getVisibleLinkItems(
  headerLinkVisibility: HeaderLinkVisibility | null,
  items: HeaderLinkItem[],
  selectedCountryAndLanguage: { country: string; language: string }
): HeaderLinkItem[] {
  return headerLinkVisibility
    ? items.filter(
        (item) =>
          headerLinkVisibility &&
          headerLinkVisibility[selectedCountryAndLanguage.country] &&
          headerLinkVisibility[selectedCountryAndLanguage.country][item.name]
      )
    : [];
}

export function getTarget(url: string): HTMLAttributeAnchorTarget | undefined {
  const origin = location.origin;

  if (url.startsWith(origin) || url.startsWith("/")) {
    return undefined;
  }

  return "_blank";
}
