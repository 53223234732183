import { useState } from "react";
import { CmsContentType } from "../../enums/CmsContentType";
import { CmsForm, CmsFormProps } from "./CmsForm";
import {
  getContentIdForType,
  getContentValueForType,
} from "./getContentForType";

export function PodcastForm({
  onSave,
  contents,
  itemType,
  availableItemTypes,
  showItemTypeSelect,
  loading,
  setLoading,
  cmsSection,
}: CmsFormProps) {
  const [title, setTitle] = useState<string>(
    getContentValueForType(CmsContentType.TITLE, contents)
  );

  const [shortDescription, setShortDescription] = useState<string>(
    getContentValueForType(CmsContentType.SHORT_DESCRIPTION, contents)
  );

  const [subtitle, setSubtitle] = useState<string>(
    getContentValueForType(CmsContentType.SUBTITLE, contents)
  );

  const [description, setDescription] = useState<string>(
    getContentValueForType(CmsContentType.DESCRIPTION, contents)
  );

  const [subtitle2, setSubtitle2] = useState<string>(
    getContentValueForType(CmsContentType.SUBTITLE2, contents)
  );

  const [description2, setDescription2] = useState<string>(
    getContentValueForType(CmsContentType.DESCRIPTION2, contents)
  );

  const [youtubeUrl, setYoutubeUrl] = useState<string>(
    getContentValueForType(CmsContentType.IFRAME_URL, contents)
  );

  const [spotifyUrl, setSpotifyUrl] = useState<string>(
    getContentValueForType(CmsContentType.IFRAME_URL2, contents)
  );

  const [topic, setTopic] = useState<string>(
    getContentValueForType(CmsContentType.TOPIC, contents)
  );

  const [original, setOriginal] = useState<string>(
    getContentValueForType(CmsContentType.CHECKBOX, contents)
  );

  const [assetUrl, setAssetUrl] = useState<string>(
    getContentValueForType(CmsContentType.ASSET_URL, contents)
  );

  const [assetFile, setAssetFile] = useState<File>();

  const inputData = [
    {
      label: "Title",
      value: title,
      valueSetter: setTitle,
      contentType: CmsContentType.TITLE,
      contentId: getContentIdForType(CmsContentType.TITLE, contents),
    },
    {
      label: "Short description",
      value: shortDescription,
      valueSetter: setShortDescription,
      contentType: CmsContentType.SHORT_DESCRIPTION,
      contentId: getContentIdForType(
        CmsContentType.SHORT_DESCRIPTION,
        contents
      ),
    },
    {
      label: "Subtitle",
      value: subtitle,
      valueSetter: setSubtitle,
      contentType: CmsContentType.SUBTITLE,
      contentId: getContentIdForType(CmsContentType.SUBTITLE, contents),
    },
    {
      label: "Description",
      value: description,
      valueSetter: setDescription,
      contentType: CmsContentType.DESCRIPTION,
      contentId: getContentIdForType(CmsContentType.DESCRIPTION, contents),
    },
    {
      label: "Subtitle2",
      value: subtitle2,
      valueSetter: setSubtitle2,
      contentType: CmsContentType.SUBTITLE2,
      contentId: getContentIdForType(CmsContentType.SUBTITLE2, contents),
    },
    {
      label: "Description2",
      value: description2,
      valueSetter: setDescription2,
      contentType: CmsContentType.DESCRIPTION2,
      contentId: getContentIdForType(CmsContentType.DESCRIPTION2, contents),
    },
    {
      label: "Youtube Iframe URL",
      value: youtubeUrl,
      valueSetter: setYoutubeUrl,
      contentType: CmsContentType.IFRAME_URL,
      contentId: getContentIdForType(CmsContentType.IFRAME_URL, contents),
    },
    {
      label: "Spotify Iframe URL",
      value: spotifyUrl,
      valueSetter: setSpotifyUrl,
      contentType: CmsContentType.IFRAME_URL2,
      contentId: getContentIdForType(CmsContentType.IFRAME_URL2, contents),
    },
    {
      label: "Topic",
      value: topic,
      valueSetter: setTopic,
      contentType: CmsContentType.TOPIC,
      contentId: getContentIdForType(CmsContentType.TOPIC, contents),
    },
    {
      label: "Original",
      value: original,
      valueSetter: setOriginal,
      contentType: CmsContentType.CHECKBOX,
      contentId: getContentIdForType(CmsContentType.CHECKBOX, contents),
    },
    {
      label: "Image",
      contentType: CmsContentType.ASSET_URL,
      contentId: getContentIdForType(CmsContentType.ASSET_URL, contents),
      value: assetUrl,
      valueSetter: setAssetUrl,
      file: assetFile,
      setFile: setAssetFile,
    },
  ];

  return (
    <CmsForm
      inputData={inputData}
      onSave={onSave}
      showItemTypeSelect={showItemTypeSelect}
      itemType={itemType}
      availableItemTypes={availableItemTypes}
      loading={loading}
      setLoading={setLoading}
      cmsSection={cmsSection}
    />
  );
}
