import ApiInstance from "./ApiInstance";
import { CmsContentType } from "../enums/CmsContentType";
import { CmsItemType } from "../enums/CmsItemType";
import { CmsSection } from "../enums/CmsSection";

export type CmsContent = {
  id: string;
  cmsItemId: string;
  type: CmsContentType;
  value: string;
  language?: {
    id: string;
    name: string;
    code: string;
  };
};

export type CmsItem = {
  id: string;
  type: CmsItemType;
  section: CmsSection;
  country: {
    id: string;
    name: string;
    code: string;
  };
  cmsContents: CmsContent[];
  position: number;
  removed: boolean;
};

class CmsApi {
  public async getCmsItems(
    section: CmsSection,
    country: string,
    language: string
  ): Promise<CmsItem[]> {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set("section", section);
    urlSearchParams.set("country", country);
    urlSearchParams.set("language", language);

    const result = await ApiInstance.getApi().get(
      `/cms/items?${urlSearchParams.toString()}`
    );

    return result.data;
  }

  public async getCmsItem(
    idOrSlug: string,
    language: string
  ): Promise<CmsItem> {
    const urlSearchParams = new URLSearchParams();
    urlSearchParams.set("language", language);

    const result = await ApiInstance.getApi().get(
      `/cms/items/${idOrSlug}?${urlSearchParams.toString()}`
    );

    return result.data;
  }

  public async addCmsItem(
    section: CmsSection,
    country: string,
    language: string,
    itemType: CmsItemType,
    contents: { contentType: CmsContentType; value: string }[]
  ): Promise<CmsItem[]> {
    const result = await ApiInstance.getApi().post(`/cms/items`, {
      itemType: itemType,
      section: section,
      language: language,
      country: country,
      contents: contents,
    });

    return result.data;
  }

  public async editCmsItemType(id: string, type: CmsItemType) {
    await ApiInstance.getApi().put(`/cms/items/${id}`, { itemType: type });
  }

  public async addCmsContent(
    itemId: string,
    type: CmsContentType,
    language: string,
    value: string
  ): Promise<CmsContent> {
    const result = await ApiInstance.getApi().post(`/cms/contents`, {
      itemId,
      type,
      language,
      value,
    });
    return result.data;
  }

  public async editCmsContent(id: string, value: string): Promise<void> {
    await ApiInstance.getApi().put(`/cms/contents/${id}`, {
      value: value,
    });
  }

  public async setPositions(
    data: { id: string; position: number }[],
    section: CmsSection,
    country: string
  ): Promise<void> {
    const params = new URLSearchParams();
    params.set("section", section);
    params.set("country", country);
    await ApiInstance.getApi().put(`/cms/positions?${params.toString()}`, {
      data: data,
    });
  }

  public async deleteCmsItem(id: string): Promise<void> {
    await ApiInstance.getApi().delete(`/cms/items/${id}`);
  }
}

export default new CmsApi();
