import React, { useContext, useEffect, useState } from "react";
import "./IntroductionSection.css";
import ManImage from "../../assets/man.svg";
import CmsApi, { CmsItem } from "../../api/CmsApi";
import { CmsSection } from "../../enums/CmsSection";
import { getContentValueForType } from "../cms/getContentForType";
import { CmsContentType } from "../../enums/CmsContentType";
import { UserCountryLanguageContext } from "../../context/UserCountryLanguageContext";
import { motion } from "framer-motion";
import { Button } from "../Button";
import AnimDiv from "../AnimDiv";

export function IntroductionSection({ section }: { section: CmsSection }) {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItems, setCmsItems] = useState<CmsItem[]>();

  useEffect(() => {
    CmsApi.getCmsItems(
      section,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItems(items));
  }, [selectedCountryAndLanguage]);

  return (
    <AnimDiv className="introduction-wrapper">
      <div className="introduction">
        <div className="left-content flex flex-column">
          <div className="content">
            <h2>
              {cmsItems
                ? getContentValueForType(
                    CmsContentType.TITLE,
                    cmsItems[0]?.cmsContents
                  )
                : null}
            </h2>
            <div className="text">
              {cmsItems
                ? getContentValueForType(
                    CmsContentType.DESCRIPTION,
                    cmsItems[0]?.cmsContents
                  )
                : null}
            </div>
            {cmsItems && cmsItems.length > 0 ? (
              <Button
                section={section}
                item={cmsItems[0]}
                className="cta-button purple"
                textClassName="color-white"
              />
            ) : null}
          </div>
        </div>
        <div className="right-content">
          <div className="image-wrapper">
            <img className="image" src={ManImage} alt="man" />
          </div>
        </div>
      </div>
    </AnimDiv>
  );
}
