import React, { useContext, useEffect, useState } from "react";
import "./HomeSection5.css";
import ArrowLeft from "../../assets/bigArrowLeft.svg";
import ArrowRight from "../../assets/bigArrowRight.svg";
import Placeholder from "../../assets/placeholder.png";
import { UserCountryLanguageContext } from "../../context/UserCountryLanguageContext";
import CmsApi, { CmsItem } from "../../api/CmsApi";
import { CmsSection } from "../../enums/CmsSection";
import { getContentValueForType } from "../cms/getContentForType";
import { CmsContentType } from "../../enums/CmsContentType";
import { ImageOrVideo } from "../ImageOrVideo";
import { getCorrectColorIfSet, getTextColor } from "../../enums/Color";
import { Button } from "../Button";
import AnimDiv from "../AnimDiv";
import { AnimatePresence, motion } from "framer-motion";

export function HomeSection5() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [headCmsItem, setHeadCmsItem] = useState<CmsItem>();
  const [cmsItems, setCmsItems] = useState<CmsItem[]>();
  const [position, setPosition] = useState(0);
  const [direction, setDirection] = useState(1);

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.HOMEPAGE_SECTION5,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItems(items));

    CmsApi.getCmsItems(
      CmsSection.HOMEPAGE_SECTION5_HEAD,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setHeadCmsItem(items[0]));
  }, [selectedCountryAndLanguage]);

  const variants = {
    enter: (direction: number) => {
      return {
        x: direction > 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
    center: {
      x: 0,
      opacity: 1,
    },
    exit: (direction: number) => {
      return {
        x: direction < 0 ? 1000 : -1000,
        opacity: 0,
      };
    },
  };

  const swipeConfidenceThreshold = 10000;
  const swipePower = (offset: number, velocity: number) => {
    return Math.abs(offset) * velocity;
  };

  return (
    <AnimDiv className="section5-wrapper">
      <div className="section5">
        <h2 className="margin">
          {getContentValueForType(
            CmsContentType.TITLE,
            headCmsItem?.cmsContents
          )}
        </h2>
        <div className="flex items-center arrows-container">
          {cmsItems?.length === 1 ? null : (
            <div
              className="arrow-left pointer"
              onClick={() => {
                setDirection(-1);
                setPosition(Math.abs((position - 1) % (cmsItems?.length || 0)));
              }}
            >
              <img src={ArrowLeft} alt="left" />
            </div>
          )}

          <AnimatePresence initial={false} custom={direction}>
            <motion.div
              initial="enter"
              animate="center"
              exit="exit"
              variants={variants}
              custom={direction}
              transition={{
                x: { type: "spring", stiffness: 300, damping: 30 },
                opacity: { duration: 0.2 },
              }}
              drag="x"
              dragConstraints={{ left: 0, right: 0 }}
              dragElastic={1}
              onDragEnd={(e, { offset, velocity }) => {
                const swipe = swipePower(offset.x, velocity.x);

                if (swipe < -swipeConfidenceThreshold) {
                  setDirection(1);
                  setPosition((position + 1) % (cmsItems?.length || 0));
                } else if (swipe > swipeConfidenceThreshold) {
                  setDirection(-1);
                  setPosition(
                    Math.abs((position - 1) % (cmsItems?.length || 0))
                  );
                }
              }}
              className="flex section5-frame"
              key={position}
            >
              <div>
                {cmsItems && cmsItems.length > 0 ? (
                  <ImageOrVideo
                    assetUrl={
                      cmsItems
                        ? getContentValueForType(
                            CmsContentType.ASSET_URL,
                            cmsItems[position]?.cmsContents
                          )
                        : Placeholder
                    }
                    className={"image"}
                    alt={"placeholder"}
                  />
                ) : null}
              </div>
              <div className="w-100 flex flex-column">
                <div
                  className={`section5-title ${
                    cmsItems && cmsItems.length > 0
                      ? getTextColor(
                          getCorrectColorIfSet(
                            CmsSection.HOMEPAGE_SECTION5,
                            CmsContentType.COLOR_TITLE,
                            cmsItems[position].cmsContents
                          ),
                          true
                        )
                      : ""
                  }`}
                >
                  {cmsItems && cmsItems.length > 0
                    ? getContentValueForType(
                        CmsContentType.TITLE,
                        cmsItems[position]?.cmsContents
                      )
                    : null}
                </div>
                <div
                  className={`section5-content ${
                    cmsItems && cmsItems.length > 0
                      ? getTextColor(
                          getCorrectColorIfSet(
                            CmsSection.HOMEPAGE_SECTION5,
                            CmsContentType.COLOR_DESCRIPTION,
                            cmsItems[position].cmsContents
                          ),
                          false
                        )
                      : ""
                  }`}
                >
                  {cmsItems && cmsItems.length > 0
                    ? getContentValueForType(
                        CmsContentType.DESCRIPTION,
                        cmsItems[position]?.cmsContents
                      )
                    : null}
                </div>
                {cmsItems && cmsItems.length > 0 ? (
                  <Button
                    section={CmsSection.HOMEPAGE_SECTION5}
                    item={cmsItems[position]}
                    className="ml-auto section5-button"
                  />
                ) : null}
              </div>
            </motion.div>
          </AnimatePresence>
          <div className="flex section5-frame hide" key={position}>
            <div>
              {cmsItems && cmsItems.length > 0 ? (
                <ImageOrVideo
                  assetUrl={
                    cmsItems
                      ? getContentValueForType(
                          CmsContentType.ASSET_URL,
                          cmsItems[position]?.cmsContents
                        )
                      : Placeholder
                  }
                  className={"image"}
                  alt={"placeholder"}
                />
              ) : null}
            </div>
            <div className="w-100 flex flex-column">
              <div
                className={`section5-title ${
                  cmsItems && cmsItems.length > 0
                    ? getTextColor(
                        getCorrectColorIfSet(
                          CmsSection.HOMEPAGE_SECTION5,
                          CmsContentType.COLOR_TITLE,
                          cmsItems[position].cmsContents
                        ),
                        true
                      )
                    : ""
                }`}
              >
                {cmsItems && cmsItems.length > 0
                  ? getContentValueForType(
                      CmsContentType.TITLE,
                      cmsItems[position]?.cmsContents
                    )
                  : null}
              </div>
              <div
                className={`section5-content ${
                  cmsItems && cmsItems.length > 0
                    ? getTextColor(
                        getCorrectColorIfSet(
                          CmsSection.HOMEPAGE_SECTION5,
                          CmsContentType.COLOR_DESCRIPTION,
                          cmsItems[position].cmsContents
                        ),
                        false
                      )
                    : ""
                }`}
              >
                {cmsItems && cmsItems.length > 0
                  ? getContentValueForType(
                      CmsContentType.DESCRIPTION,
                      cmsItems[position]?.cmsContents
                    )
                  : null}
              </div>
              {cmsItems && cmsItems.length > 0 ? (
                <Button
                  section={CmsSection.HOMEPAGE_SECTION5}
                  item={cmsItems[position]}
                  className="ml-auto section5-button"
                />
              ) : null}
            </div>
          </div>
          {cmsItems?.length === 1 ? null : (
            <div
              className="arrow-right pointer ml-auto"
              onClick={() => {
                setDirection(1);
                setPosition((position + 1) % (cmsItems?.length || 0));
              }}
            >
              <img src={ArrowRight} alt="right" />
            </div>
          )}
        </div>
      </div>
    </AnimDiv>
  );
}
