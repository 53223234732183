import { useState } from "react";
import { CmsContentType } from "../../enums/CmsContentType";
import { CmsForm, CmsFormProps } from "./CmsForm";
import {
  getContentIdForType,
  getContentValueForType,
} from "./getContentForType";

export function TitleForm({
  onSave,
  contents,
  itemType,
  availableItemTypes,
  showItemTypeSelect,
  loading,
  setLoading,
  cmsSection,
}: CmsFormProps) {
  const [title, setTitle] = useState<string>(
    getContentValueForType(CmsContentType.TITLE, contents)
  );

  const inputData = [
    {
      label: "Title",
      value: title,
      valueSetter: setTitle,
      contentType: CmsContentType.TITLE,
      contentId: getContentIdForType(CmsContentType.TITLE, contents),
    },
  ];

  return (
    <CmsForm
      inputData={inputData}
      onSave={onSave}
      showItemTypeSelect={showItemTypeSelect}
      itemType={itemType}
      availableItemTypes={availableItemTypes}
      loading={loading}
      setLoading={setLoading}
      cmsSection={cmsSection}
    />
  );
}
