import { CmsContent } from "../../api/CmsApi";
import { CmsItemType } from "../../enums/CmsItemType";
import { CmsSection } from "../../enums/CmsSection";
import { TitleAssetDescriptionButtonForm } from "./TitleAssetDescriptionButtonForm";
import { AssetButtonForm } from "./AssetButtonForm";
import { TitleDescriptionButtonForm } from "./TitleDescriptionButtonForm";
import { TitleForm } from "./TitleForm";
import { TitleButtonForm } from "./TitleButtonForm";
import { OnSaveCmsItem } from "./CmsTable";
import { TitleAssetURLForm } from "./TitleAssetURLForm";
import { InformationForm } from "./InformationForm";
import { TitleDescriptionForm } from "./TitleDescriptionForm";
import { TitleAssetDescriptionForm } from "./TitleAssetDescriptionForm";
import { InspirationForm } from "./InspirationForm";
import { TrainingForm } from "./TrainingForm";
import { TitleSubtitleDescriptionButtonForm } from "./TitleSubtitleDescriptionButtonForm";
import { TitleAssetDescriptionContentForm } from "./TitleAssetDescriptionContentForm";
import { TitleSubtitleAssetDescriptionForm } from "./TitleSubtitleAssetDescriptionForm";
import { TitleSubtitleAssetDescriptionButtonForm } from "./TitleSubtitleAssetDescriptionButtonForm";
import { PodcastForm } from "./PodcastForm";
import { EventsForm } from "./EventsForm";
import { DealForm } from "./DealForm";
import { TitleAssetDescriptionWithColorsForm } from "./TitleAssetDescriptionWithColorsForm";
import { TitleAssetColorForm } from "./TitleAssetColorForm";
import { DealsPopupForm } from "./DealsPopupForm";
import { AssetForm } from "./AssetForm";

export function CmsFormForSection({
  section,
  contents,
  itemType,
  availableItemTypes,
  showItemTypeSelect,
  onSave,
  loading,
  setLoading,
}: {
  section: CmsSection;
  contents: CmsContent[] | undefined;
  itemType: CmsItemType | undefined;
  availableItemTypes: CmsItemType[] | undefined;
  showItemTypeSelect: boolean;
  onSave: OnSaveCmsItem;
  loading: boolean;
  setLoading: (l: boolean) => void;
}) {
  switch (section) {
    case CmsSection.HOMEPAGE_HERO_SECTION:
    case CmsSection.HOMEPAGE_SECTION1:
    case CmsSection.HOMEPAGE_SECTION2:
    case CmsSection.HOMEPAGE_SECTION3:
    case CmsSection.HOMEPAGE_SECTION5:
    case CmsSection.HOMEPAGE_SECTION6:
    case CmsSection.HOMEPAGE_SECTION7:
    case CmsSection.CORPORATES_HERO:
    case CmsSection.ABOUT_HERO:
    case CmsSection.HIGHER_EDU_HERO:
    case CmsSection.INVESTORS_HERO:
    case CmsSection.MENTORS_HERO:
    case CmsSection.ACADEMY_SECTION1:
    case CmsSection.ACADEMY_SECTION2:
    case CmsSection.ACADEMY_SECTION3:
    case CmsSection.ACADEMY_SECTION4:
    case CmsSection.ACADEMY_SECTION5:
    case CmsSection.CORPORATES_SECTION3:
    case CmsSection.HIGHER_EDU_SECTION3:
    case CmsSection.INVESTORS_SECTION3:
    case CmsSection.MENTORS_SECTION3:
    case CmsSection.FUNDING_SECTION1:
    case CmsSection.FUNDING_SECTION2:
    case CmsSection.FUNDING_SECTION3:
    case CmsSection.PODCAST_RECOMMENDATION:
    case CmsSection.PODCAST_CTA:
    case CmsSection.COMMUNITY_SECTION1:
    case CmsSection.COMMUNITY_SECTION2:
    case CmsSection.COMMUNITY_SECTION3:
    case CmsSection.COMMUNITY_SECTION4:
    case CmsSection.STARTUP_CAFE_HERO:
    case CmsSection.STARTUP_CAFE_SECTION2:
    case CmsSection.STARTUP_CAFE_SECTION3:
    case CmsSection.STARTUP_CAFE_SECTION4:
    case CmsSection.UNBOUNDED_HERO:
    case CmsSection.UNBOUNDED_SECTION2:
    case CmsSection.UNBOUNDED_SECTION3:
    case CmsSection.UNBOUNDED_SECTION4:
    case CmsSection.START_SMART_BOTTOM:
      return (
        <TitleAssetDescriptionButtonForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.HOMEPAGE_SECTION4:
      return (
        <AssetButtonForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.HOMEPAGE_INTRODUCTION:
    case CmsSection.FORCES_SECTION1:
    case CmsSection.STARTUP_CAFE_SECTION1:
    case CmsSection.UNBOUNDED_SECTION1:
      return (
        <TitleDescriptionButtonForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.HOMEPAGE_SECTION2_HEAD:
    case CmsSection.HOMEPAGE_SECTION3_HEAD:
    case CmsSection.HOMEPAGE_SECTION8_HEAD:
    case CmsSection.CORPORATES_SECTION2_HEAD:
    case CmsSection.HIGHER_EDU_SECTION2_HEAD:
    case CmsSection.INVESTORS_SECTION2_HEAD:
    case CmsSection.MENTORS_SECTION2_HEAD:
    case CmsSection.PODCAST_RECOMMENDATION_HEAD:
    case CmsSection.STARTUP_CAFE_SECTION3_HEAD:
    case CmsSection.UNBOUNDED_SECTION3_HEAD:
      return (
        <TitleButtonForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.HOMEPAGE_SECTION5_HEAD:
    case CmsSection.HOMEPAGE_SECTION7_HEAD:
    case CmsSection.TAGS:
    case CmsSection.INSPIRATION_TAGS:
    case CmsSection.TRAINING_TAGS:
    case CmsSection.EVENTS_TAGS:
    case CmsSection.START_SMART_HEAD:
      return (
        <TitleForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.FAQ:
    case CmsSection.ABOUT_SECTION1:
      return (
        <TitleDescriptionForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.HOMEPAGE_SECTION8:
      return (
        <TitleAssetURLForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.FORCES_HERO:
    case CmsSection.LOGIN_SECTION:
      return (
        <TitleAssetColorForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.SIGNUP_SECTION:
      return (
        <AssetForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.INFORMATION:
      return (
        <InformationForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.TRAINING:
      return (
        <TrainingForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.COACHING_TOP:
    case CmsSection.ADVICE_TOP:
    case CmsSection.NATHALIE_BOOKING_TOP:
    case CmsSection.STEVE_BOOKING_TOP:
    case CmsSection.MENTORING_TOP:
    case CmsSection.ABOUT_SECTION2:
    case CmsSection.CONTACT_SECTION:
    case CmsSection.FORCES_SECTION3:
    case CmsSection.FORCES_SECTION4:
    case CmsSection.FORCES_SECTION5:
    case CmsSection.START_SMART_SECTION1:
      return (
        <TitleAssetDescriptionForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.FUNDING_HERO:
      return (
        <TitleAssetDescriptionWithColorsForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.INSPIRATION:
      return (
        <InspirationForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.CORPORATES_SECTION1:
    case CmsSection.HIGHER_EDU_SECTION1:
    case CmsSection.MENTORS_SECTION1:
    case CmsSection.INVESTORS_SECTION1:
      return (
        <TitleSubtitleDescriptionButtonForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.MENTORS_SECTION2:
    case CmsSection.CORPORATES_SECTION2:
    case CmsSection.HIGHER_EDU_SECTION2:
    case CmsSection.INVESTORS_SECTION2:
      return (
        <TitleAssetDescriptionContentForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.FORCES_SECTION2:
      return (
        <TitleSubtitleAssetDescriptionForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.COACHING_BOTTOM:
    case CmsSection.ADVICE_BOTTOM:
    case CmsSection.MENTORING_BOTTOM:
    case CmsSection.FORCES_SECTION6:
    case CmsSection.NATHALIE_BOOKING_BOTTOM:
    case CmsSection.STEVE_BOOKING_BOTTOM:
      return (
        <TitleSubtitleAssetDescriptionButtonForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.PODCAST:
      return (
        <PodcastForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.EVENTS:
      return (
        <EventsForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.DEAL:
      return (
        <DealForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    case CmsSection.DEALS_POPUP:
      return (
        <DealsPopupForm
          contents={contents}
          onSave={onSave}
          showItemTypeSelect={showItemTypeSelect}
          itemType={itemType}
          availableItemTypes={availableItemTypes}
          loading={loading}
          setLoading={setLoading}
          cmsSection={section}
        />
      );
    default:
      return <div>CMS Form not implemented yet.</div>;
  }
}
