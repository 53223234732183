import React from "react";
import "./Dropdown.css";

export function Dropdown({
  value,
  options,
  onChange,
}: {
  value: string;
  onChange: (value: string) => void;
  options: { label: string; value: string }[];
}) {
  return (
    <div className="dropdown-wrapper flex pointer">
      <select
        className="dropdown pointer"
        value={value}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}
