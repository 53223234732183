import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./Faq.css";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { CmsSection } from "../enums/CmsSection";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import { FaqItem } from "../components/FaqItem";
import AnimDiv from "../components/AnimDiv";

export function Faq() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);

  const { t } = useTranslation();
  const [cmsItems, setCmsItems] = useState<CmsItem[]>();

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.FAQ,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItems(items));
  }, []);

  return (
    <AnimDiv className="faq">
      <h2>{t("common:faqLong")}</h2>
      <div className="faq-items">
        {cmsItems?.map((item) => {
          return <FaqItem cmsItem={item} />;
        })}
      </div>
    </AnimDiv>
  );
}
