import React from "react";
import { ImageOrVideo } from "./ImageOrVideo";
import { Link } from "react-router-dom";
import "./Grid.css";
import AnimDiv from "./AnimDiv";
import { getTarget } from "../util";

export function Grid({
  pattern,
  items,
  defaultRoute,
  full,
}: {
  pattern: string[];
  items: {
    id: string;
    title: string;
    assetUrl: string;
    buttonText: string;
    buttonUrl: string;
    topic: string;
    description: string;
    content: string;
  }[];
  defaultRoute: string;
  full?: boolean;
}) {
  return (
    <div className="grid">
      <div className="grid-wrapper">
        <div className={`grid-grid ${full ? "full" : ""}`}>
          {items.map((item, i) => {
            const size = pattern[i % pattern.length];

            return (
              <AnimDiv className={`grid-box ${size}`} key={i}>
                <div className={`grid-content ${item.assetUrl ? "asset" : ""}`}>
                  <div className="figure" />
                  {item.assetUrl ? (
                    <div className="image-wrapper">
                      <ImageOrVideo
                        assetUrl={item.assetUrl}
                        className={"image"}
                        alt={"placeholder"}
                      />
                    </div>
                  ) : null}
                  <div className="content-wrapper flex flex-column">
                    <div className="topic">{item.topic}</div>
                    <div className="grid-title">{item.title}</div>
                    {item.assetUrl && size === "w1-3" ? null : (
                      <div className="excerpt">{item.description}</div>
                    )}
                    {!item.buttonText ? null : !item.content &&
                      !item.buttonUrl ? null : (
                      <div
                        className={`ml-auto button-wrapper button cta uppercase color-white ${
                          size === "w2-3" ? "purple" : ""
                        }`}
                      >
                        {!item.buttonUrl && item.content ? (
                          <Link
                            to={`${defaultRoute}/${item.id}`}
                            className={`uppercase color-white ${
                              size === "w2-3" ? "purple" : ""
                            }`}
                          >
                            {item.buttonText}
                          </Link>
                        ) : null}
                        {item.buttonUrl ? (
                          <a
                            href={item.buttonUrl}
                            className={`b color-white ${
                              size === "w2-3" ? "purple" : ""
                            }`}
                            target={getTarget(item.buttonUrl)}
                          >
                            {item.buttonText}
                          </a>
                        ) : null}
                      </div>
                    )}
                  </div>
                </div>
              </AnimDiv>
            );
          })}
        </div>
      </div>
    </div>
  );
}
