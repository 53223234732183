import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { Input } from "../components/Input";
import AuthApi from "../api/AuthApi";
import Bg from "../assets/bg.png";
import "./Login.css";

export function ForgotPassword() {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation(["forgot-pw", "common"]);

  return (
    <div className="login-container-wrapper flex">
      <div className="left-content">
        <img className="image" src={Bg} />
      </div>
      <div className="right-content">
        <div className="login-container">
          <h2>{t("title")}</h2>
          <div className="background-shadow flex forgot">
            <form
              className="content forgot"
              onSubmit={(e) => {
                e.preventDefault();
                setSubmitted(true);
                AuthApi.requestPasswordReset(email);
              }}
            >
              <Input
                type={"email"}
                label={t("common:email")}
                value={email}
                onChange={(e) => setEmail(e)}
                required
              />
              {submitted && <span>{t("confirmSubmit")}</span>}
              <button type="submit" className="button mt-4  flex ml-auto">
                {t("sendEmail")}
              </button>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
