import React from "react";

export function Select({
  label,
  value,
  onChange,
  required = true,
  options,
  className,
}: {
  label: string | null;
  value: string;
  required?: boolean;
  onChange: (value: string) => void;
  options: { label: string; value: string }[];
  className?: string;
}) {
  return (
    <div className={`form__group field ${className ? className : ""} flex`}>
      <select
        className="form__field mt-2"
        value={value}
        required={required}
        onChange={(e) => {
          onChange(e.target.value);
        }}
      >
        {options.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
      {label ? (
        <label htmlFor="name" className="form__label">
          {label}
        </label>
      ) : null}

      {required ? <div className="required-asterix">*</div> : null}
    </div>
  );
}
