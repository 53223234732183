import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import "./SignupOptions.css";
import { Link } from "react-router-dom";
import { availableRoutes } from "../constants/AvailableRoutes";
import { Role } from "../enums/Role";
import { roleToRoleString } from "../util";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { CmsSection } from "../enums/CmsSection";
import { ImageOrVideo } from "../components/ImageOrVideo";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";
import AnimDiv from "../components/AnimDiv";

export function SignupOptions() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItem, setCmsItem] = useState<CmsItem>();
  const { t } = useTranslation(["signup", "common"]);

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.SIGNUP_SECTION,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItem(items[0]));
  }, []);

  return (
    <AnimDiv className="signup-options">
      <div className="left-content">
        <ImageOrVideo
          assetUrl={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItem?.cmsContents
          )}
          className={"image"}
          alt={"bg"}
        />
      </div>
      <div className="content">
        <h2>{t("common:signup")}</h2>
        <div className="inner-content">
          <div className="signup-title">{t("signingUp")}</div>
          <div className="boxes">
            <Link
              to={`${availableRoutes.signup}/${roleToRoleString(Role.STUDENT)}`}
              className="box"
            >
              {t("studentEntrepreneur")}
            </Link>
            <Link
              to={`${availableRoutes.signup}/${roleToRoleString(Role.PHD)}`}
              className="box"
            >
              {t("phdPostdoc")}
            </Link>
            <Link
              to={`${availableRoutes.signup}/${roleToRoleString(Role.ALUMNUS)}`}
              className="box"
            >
              {t("alumnus")}
            </Link>
            <Link
              to={`${availableRoutes.signup}/${roleToRoleString(Role.COMPANY)}`}
              className="box"
            >
              {t("corporate")}
            </Link>
            <Link
              to={`${availableRoutes.signup}/${roleToRoleString(
                Role.HIGHER_EDU
              )}`}
              className="box"
            >
              {t("higherEducation")}
            </Link>
            <Link
              to={`${availableRoutes.signup}/${roleToRoleString(
                Role.INVESTOR
              )}`}
              className="box"
            >
              {t("investor")}
            </Link>
          </div>
        </div>
      </div>
    </AnimDiv>
  );
}
