import { CmsContentType } from "../../enums/CmsContentType";
import { CmsContent } from "../../api/CmsApi";

/**
 * Get content with given type from array of contents. If `contents`
 * is undefined, or content with type `type` doesn't exist, return `undefined`.
 */
export function getContentForType(
  type: CmsContentType,
  contents: CmsContent[] | undefined
): CmsContent | undefined {
  return contents?.find((c) => c.type === type);
}

/**
 * Get value of content with given type from array of contents. If `contents`
 * is undefined, or content with type `type` doesn't exist, return empty string.
 */
export function getContentValueForType(
  type: CmsContentType,
  contents: CmsContent[] | undefined
): string {
  return getContentForType(type, contents)?.value || "";
}

/**
 * Get id of content with given type from array of contents. If `contents`
 * is `undefined`, or content with type `type` doesn't exist, return `undefined`.
 */
export function getContentIdForType(
  type: CmsContentType,
  contents?: CmsContent[]
): string | undefined {
  return getContentForType(type, contents)?.id;
}
