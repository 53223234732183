import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./PodcastsDetail.css";
import Bg from "../assets/detail-bg.png";
import { CmsSection } from "../enums/CmsSection";
import { HomeSection2 } from "../components/home/HomeSection2";
import { ImageOrVideo } from "../components/ImageOrVideo";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import { Link, useNavigate, useParams } from "react-router-dom";
import { isMobile } from "react-device-detect";
import { getCorrectColorIfSet, getTextColor } from "../enums/Color";
import { Button } from "../components/Button";
import AnimDiv from "../components/AnimDiv";
import { availableRoutes } from "../constants/AvailableRoutes";
import { jsonLdObjects } from "../constants/JsonLdObjects";

export function PodcastsDetail() {
  const { id } = useParams();

  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItem, setCmsItem] = useState<CmsItem>();
  const [cmsItemCTA, setCmsItemCTA] = useState<CmsItem>();

  useEffect(() => {
    if (!id) {
      return;
    }

    CmsApi.getCmsItem(id, selectedCountryAndLanguage.language).then((item) =>
      setCmsItem(item)
    );
    CmsApi.getCmsItems(
      CmsSection.PODCAST_CTA,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemCTA(items[0]));
  }, [id]);

  const youtubeUrl = getContentValueForType(
    CmsContentType.IFRAME_URL,
    cmsItem?.cmsContents
  );
  const spotifyUrl = getContentValueForType(
    CmsContentType.IFRAME_URL2,
    cmsItem?.cmsContents
  );

  const podcastDetailJsonLdData = jsonLdObjects.podcastEpisode(cmsItem);

  return (
    <AnimDiv className="podcasts-detail">
      <img className="bg" src={Bg} alt="bg" />
      <div className="back pointer">
        <Link to={availableRoutes.podcasts}>&lt; back</Link>
      </div>
      <AnimDiv className="wrapper">
        <h2>
          {getContentValueForType(CmsContentType.TITLE, cmsItem?.cmsContents)}
        </h2>
        <div className="flex items-center flex-column">
          {youtubeUrl ? (
            <iframe
              width={isMobile ? "100%" : "660"}
              height={isMobile ? "215" : "415"}
              src={youtubeUrl}
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            />
          ) : null}
          {spotifyUrl ? (
            <iframe
              style={{
                borderRadius: "12px",
                marginTop: youtubeUrl ? "20px" : "",
              }}
              src={spotifyUrl}
              width={isMobile ? "100%" : "660"}
              height="152"
              frameBorder="0"
              allow="autoplay; clipboard-write; encrypted-media; fullscreen; picture-in-picture"
              loading="lazy"
            />
          ) : null}
        </div>
        {getContentValueForType(
          CmsContentType.SUBTITLE,
          cmsItem?.cmsContents
        ) ? (
          <div className="subtitle">
            {getContentValueForType(
              CmsContentType.SUBTITLE,
              cmsItem?.cmsContents
            )}
          </div>
        ) : null}
        {getContentValueForType(
          CmsContentType.DESCRIPTION,
          cmsItem?.cmsContents
        ) ? (
          <div className="content-column">
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItem?.cmsContents
            )}
          </div>
        ) : null}
        <div className="content-bottom">
          {getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItem?.cmsContents
          ) ? (
            <ImageOrVideo
              assetUrl={getContentValueForType(
                CmsContentType.ASSET_URL,
                cmsItem?.cmsContents
              )}
              className={"image"}
              alt={"bg"}
            />
          ) : null}
          <div className="text">
            {getContentValueForType(
              CmsContentType.SUBTITLE2,
              cmsItem?.cmsContents
            ) ? (
              <div className="bottom-subtitle">
                {getContentValueForType(
                  CmsContentType.SUBTITLE2,
                  cmsItem?.cmsContents
                )}
              </div>
            ) : null}
            {getContentValueForType(
              CmsContentType.DESCRIPTION2,
              cmsItem?.cmsContents
            ) ? (
              <div>
                {getContentValueForType(
                  CmsContentType.DESCRIPTION2,
                  cmsItem?.cmsContents
                )}
              </div>
            ) : null}
          </div>
        </div>
      </AnimDiv>
      <HomeSection2
        sectionHead={CmsSection.PODCAST_RECOMMENDATION_HEAD}
        section={CmsSection.PODCAST_RECOMMENDATION}
      />
      {getContentValueForType(
        CmsContentType.ASSET_URL,
        cmsItemCTA?.cmsContents
      ) ? (
        <AnimDiv className="bottom gradient-overlay">
          <ImageOrVideo
            assetUrl={getContentValueForType(
              CmsContentType.ASSET_URL,
              cmsItemCTA?.cmsContents
            )}
            className={"image z-1"}
            alt={"bg"}
          />
          <div className="content">
            <div
              className={`content-title ${getTextColor(
                getCorrectColorIfSet(
                  CmsSection.PODCAST_CTA,
                  CmsContentType.COLOR_TITLE,
                  cmsItemCTA?.cmsContents
                ),
                true
              )}`}
            >
              {getContentValueForType(
                CmsContentType.TITLE,
                cmsItemCTA?.cmsContents
              )}
            </div>
            <div
              className={`${getTextColor(
                getCorrectColorIfSet(
                  CmsSection.PODCAST_CTA,
                  CmsContentType.COLOR_DESCRIPTION,
                  cmsItemCTA?.cmsContents
                ),
                false
              )}`}
            >
              {getContentValueForType(
                CmsContentType.DESCRIPTION,
                cmsItemCTA?.cmsContents
              )}
            </div>
            <div className="flex buttons">
              {cmsItemCTA ? (
                <Button section={CmsSection.PODCAST_CTA} item={cmsItemCTA} />
              ) : null}
            </div>
          </div>
        </AnimDiv>
      ) : null}
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(podcastDetailJsonLdData)}
        </script>
      </Helmet>
    </AnimDiv>
  );
}
