import GlobeIcon from "../assets/globe.svg";
import { getCountryNameFromCode, getLanguageNameFromCode } from "../util";
import React, { Fragment, useContext, useEffect, useState } from "react";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import Dialog from "./Dialog";
import CrossIcon from "../assets/cross.svg";
import ArrowRightIcon from "../assets/arrowRight.svg";
import SettingsApi, { CountryLanguages } from "../api/SettingsApi";
import "./CountrySelector.css";

export default function CountrySelector({ mobile }: { mobile?: boolean }) {
  const { selectedCountryAndLanguage, setCountryAndLanguage } = useContext(
    UserCountryLanguageContext
  );
  const [openCountryPopup, setOpenCountryPopup] = useState(false);
  const [openLanguagePopup, setOpenLanguagePopup] = useState<false | string>(
    false
  );
  const [countryLanguages, setCountryLanguages] = useState<CountryLanguages[]>(
    []
  );

  useEffect(() => {
    SettingsApi.getCountryLanguages().then((result) =>
      setCountryLanguages(result)
    );
  }, []);

  return (
    <Fragment>
      <div
        className={`flex pointer country-wrapper ${mobile ? "ml-auto" : ""}`}
        onClick={() => {
          setOpenCountryPopup(true);
        }}
      >
        <img src={GlobeIcon} alt="globe" className={mobile ? "black" : ""} />
        <div className={`ml-4 ${mobile ? "color-black" : ""}`}>
          {getLanguageNameFromCode(selectedCountryAndLanguage.language)} -{" "}
          {getCountryNameFromCode(selectedCountryAndLanguage.country)}
        </div>
      </div>
      <Dialog
        isOpen={openCountryPopup}
        onRequestClose={() => {
          setOpenCountryPopup(false);
        }}
        title={""}
        size={"small"}
        padding={false}
        overflow={false}
      >
        <div className="country-popup">
          <h2>Select your country</h2>
          <img
            className="close pointer"
            src={CrossIcon}
            alt="close"
            onClick={() => {
              setOpenCountryPopup(false);
            }}
          />
          <div className="content">
            {countryLanguages.map((item) => {
              const liveLanguages = item.languages.filter((l) => l.live);

              if (liveLanguages.length === 0) {
                return null;
              }

              return (
                <div
                  className="content-item"
                  onClick={() => {
                    setOpenCountryPopup(false);
                    setOpenLanguagePopup(item.country);
                  }}
                >
                  <div>{getCountryNameFromCode(item.country)}</div>
                  <img src={ArrowRightIcon} alt={"arrow"} />
                </div>
              );
            })}
          </div>
        </div>
      </Dialog>
      <Dialog
        isOpen={openLanguagePopup != false}
        onRequestClose={() => {
          setOpenLanguagePopup(false);
        }}
        title={""}
        size={"small"}
        padding={false}
        overflow={false}
      >
        <div className="country-popup">
          <h2>Select your language</h2>
          <img
            className="close pointer"
            src={CrossIcon}
            alt="close"
            onClick={() => {
              setOpenLanguagePopup(false);
            }}
          />
          <div className="content">
            {countryLanguages
              .find((item) => item.country === openLanguagePopup)
              ?.languages.filter((l) => l.live)
              .map((item) => {
                return (
                  <div
                    className="content-item"
                    onClick={() => {
                      if (!openLanguagePopup) {
                        return;
                      }

                      setCountryAndLanguage({
                        country: openLanguagePopup,
                        language: item.code,
                      });
                      setOpenLanguagePopup(false);
                    }}
                  >
                    <div>{getLanguageNameFromCode(item.code)}</div>
                    <img src={ArrowRightIcon} alt={"arrow"} />
                  </div>
                );
              })}
          </div>
        </div>
      </Dialog>
    </Fragment>
  );
}
