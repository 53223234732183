import React, { useState } from "react";
import { CmsContentType } from "../../enums/CmsContentType";
import { CmsForm, CmsFormProps } from "./CmsForm";
import {
  getContentIdForType,
  getContentValueForType,
} from "./getContentForType";
import { Deal } from "../../enums/Deal";

export function DealForm({
  onSave,
  contents,
  itemType,
  availableItemTypes,
  showItemTypeSelect,
  loading,
  setLoading,
  cmsSection,
}: CmsFormProps) {
  const [title, setTitle] = useState<string>(
    getContentValueForType(CmsContentType.TITLE, contents)
  );

  const [topic, setTopic] = useState<string>(
    getContentValueForType(CmsContentType.TOPIC, contents)
  );

  const [description, setDescription] = useState<string>(
    getContentValueForType(CmsContentType.DESCRIPTION, contents)
  );

  const [shortDescription, setShortDescription] = useState<string>(
    getContentValueForType(CmsContentType.SHORT_DESCRIPTION, contents)
  );

  const [subtitle, setSubtitle] = useState<string>(
    getContentValueForType(CmsContentType.SUBTITLE, contents)
  );

  const [price, setPrice] = useState<string>(
    getContentValueForType(CmsContentType.SUBTITLE2, contents)
  );

  const [description2, setDescription2] = useState<string>(
    getContentValueForType(CmsContentType.DESCRIPTION2, contents)
  );

  const [ctaText, setCtaText] = useState<string>(
    getContentValueForType(CmsContentType.CALL_TO_ACTION_TEXT, contents)
  );

  const [assetUrl, setAssetUrl] = useState<string>(
    getContentValueForType(CmsContentType.ASSET_URL, contents)
  );

  const [controls, setControls] = useState<string>(
    getContentValueForType(CmsContentType.VIDEO_CONTROLS, contents) || "false"
  );

  const [logoUrl, setLogoUrl] = useState<string>(
    getContentValueForType(CmsContentType.LOGO_URL, contents)
  );

  const [deal, setDeal] = useState<string>(
    getContentValueForType(CmsContentType.DEAL, contents)
  );

  const [email, setEmail] = useState<string>(
    getContentValueForType(CmsContentType.EMAIL, contents)
  );

  const [claimButtonText, setClaimButtonText] = useState<string>(
    getContentValueForType(CmsContentType.CLAIM_BUTTON_TEXT, contents)
  );

  const [claimButtonUrl, setClaimButtonUrl] = useState<string>(
    getContentValueForType(CmsContentType.CLAIM_BUTTON_URL, contents)
  );

  const [assetFile, setAssetFile] = useState<File>();
  const [logoFile, setLogoFile] = useState<File>();

  const inputData = [
    {
      label: "Title",
      value: title,
      valueSetter: setTitle,
      contentType: CmsContentType.TITLE,
      contentId: getContentIdForType(CmsContentType.TITLE, contents),
    },
    {
      label: "Topic",
      value: topic,
      valueSetter: setTopic,
      contentType: CmsContentType.TOPIC,
      contentId: getContentIdForType(CmsContentType.TOPIC, contents),
    },
    {
      label: "Short description",
      value: shortDescription,
      valueSetter: setShortDescription,
      contentType: CmsContentType.SHORT_DESCRIPTION,
      contentId: getContentIdForType(
        CmsContentType.SHORT_DESCRIPTION,
        contents
      ),
    },
    {
      label: "Price",
      value: price,
      valueSetter: setPrice,
      contentType: CmsContentType.SUBTITLE2,
      contentId: getContentIdForType(CmsContentType.SUBTITLE2, contents),
    },
    {
      label: "Subtitle",
      value: subtitle,
      valueSetter: setSubtitle,
      contentType: CmsContentType.SUBTITLE,
      contentId: getContentIdForType(CmsContentType.SUBTITLE, contents),
    },
    {
      label: "Description",
      value: description,
      valueSetter: setDescription,
      contentType: CmsContentType.DESCRIPTION,
      contentId: getContentIdForType(CmsContentType.DESCRIPTION, contents),
    },
    {
      label: "Description 2",
      value: description2,
      valueSetter: setDescription2,
      contentType: CmsContentType.DESCRIPTION2,
      contentId: getContentIdForType(CmsContentType.DESCRIPTION2, contents),
    },
    {
      label: "Button text",
      value: ctaText,
      valueSetter: setCtaText,
      contentType: CmsContentType.CALL_TO_ACTION_TEXT,
      contentId: getContentIdForType(
        CmsContentType.CALL_TO_ACTION_TEXT,
        contents
      ),
    },
    {
      label: "Deal type",
      contentType: CmsContentType.DEAL,
      contentId: getContentIdForType(CmsContentType.DEAL, contents),
      value: deal,
      valueSetter: setDeal,
      options: [{ label: "", value: "" }].concat(
        Object.values(Deal).map((item) => {
          return { value: item.toString(), label: item.toString() };
        })
      ),
    },
    {
      label: "Deal form receiver email",
      contentType: CmsContentType.EMAIL,
      contentId: getContentIdForType(CmsContentType.EMAIL, contents),
      value: email,
      valueSetter: setEmail,
    },
    {
      label: "Claim button text",
      contentType: CmsContentType.CLAIM_BUTTON_TEXT,
      contentId: getContentIdForType(
        CmsContentType.CLAIM_BUTTON_TEXT,
        contents
      ),
      value: claimButtonText,
      valueSetter: setClaimButtonText,
    },
    {
      label: "Claim button url",
      contentType: CmsContentType.CLAIM_BUTTON_URL,
      contentId: getContentIdForType(CmsContentType.CLAIM_BUTTON_URL, contents),
      value: claimButtonUrl,
      valueSetter: setClaimButtonUrl,
    },
    {
      label: "Logo",
      contentType: CmsContentType.LOGO_URL,
      contentId: getContentIdForType(CmsContentType.LOGO_URL, contents),
      file: logoFile,
      setFile: setLogoFile,
      value: logoUrl,
      valueSetter: setLogoUrl,
    },
    {
      label: "Image",
      contentType: CmsContentType.ASSET_URL,
      contentId: getContentIdForType(CmsContentType.ASSET_URL, contents),
      file: assetFile,
      setFile: setAssetFile,
      value: assetUrl,
      valueSetter: setAssetUrl,
    },
    {
      label: "Allow controls?",
      value: controls,
      valueSetter: setControls,
      contentType: CmsContentType.VIDEO_CONTROLS,
      contentId: getContentIdForType(CmsContentType.VIDEO_CONTROLS, contents),
    },
  ];

  return (
    <CmsForm
      inputData={inputData}
      onSave={onSave}
      showItemTypeSelect={showItemTypeSelect}
      itemType={itemType}
      availableItemTypes={availableItemTypes}
      loading={loading}
      setLoading={setLoading}
      cmsSection={cmsSection}
    />
  );
}
