export enum Deal {
  ACCOUNTABLE = "accountable",
  DEXXTER = "dexxter",
  COMBELL_SHARED_HOSTING = "combellSharedHosting",
  COMBELL_SITEBUILDER = "combellSitebuilder",
  COMBELL_DOMAIN = "combellDomain",
  COMBELL_M365 = "combellM365",
  COMBELL_CLOUD = "combellCloud",
  TEAMLEADER = "teamleader",
  CONNUS = "connus",
  WORK_SMARTER = "workSmarter",
  BIZZY = "bizzy",
  ORDER_BILLY = "orderBilly",
  NOTION = "notion",
  URL_DEAL = "urlDeal",
  GENERAL_DEAL_FORM = "generalFormDeal",
}

export enum DealType {
  URL = "URL",
  DISCOUNT_CODE = "DISCOUNT_CODE",
  FORM = "FORM",
}

export const dealFormTitle: Record<string, string> = {
  combellCloud: "StudentStartups Cloud Request",
  connus: "Connus Credit Request",
  workSmarter: "Work Smarter Ticket Request",
  bizzy: "StudentStartups Bizzy Professional",
};
