import ApiInstance from "./ApiInstance";

/**
 * Upload media and return its URL.
 */
export async function uploadMedia(file: File): Promise<string> {
  const formData = new FormData();
  formData.set("file", file);
  const response = await ApiInstance.getApi().post("/media", formData, {
    headers: { "Content-Type": "multipart/formdata" },
  });
  return response.data.url;
}
