import ApiInstance from "./ApiInstance";
import { Country } from "./InformationApi";

export type CoachEntity = {
  name: string;
  email: string;
  description: string;
  avatarUrl: string;
  schedulingUrl: string;
  onHome: boolean;
  isPublic: boolean;
  position: number;
  homePosition: number;
  country: Country;
};

class CoachApi {
  public async listCoaches(): Promise<CoachEntity[]> {
    const results = await ApiInstance.getApi().get<{ data: CoachEntity[] }>(
      "/coach"
    );

    if (!results.data) {
      return [];
    }

    if (!results.data.data) {
      return [];
    }

    return results.data.data;
  }
}

export default new CoachApi();
