import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { AxiosError } from "axios";
import { toast } from "react-toastify";
import "./Signup.css";
import { Input } from "../components/Input";
import { Select } from "../components/Select";
import { languages } from "../constants/Languages";
import { countries } from "../constants/Countries";
import AuthApi, { Gender } from "../api/AuthApi";
import { Dropdown } from "../components/Dropdown";
import { Role } from "../enums/Role";
import { Checkbox } from "../components/Checkbox";
import { availableRoutes } from "../constants/AvailableRoutes";
import { roleStringToRole, roleToRoleString } from "../util";
import { industries } from "../constants/Industries";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { CmsSection } from "../enums/CmsSection";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";
import { ImageOrVideo } from "../components/ImageOrVideo";
import { HigherEductionSubjects } from "../constants/HigherEductionSubjects";
import NewsletterApi from "../api/NewsletterApi";
import AnimDiv from "../components/AnimDiv";

function companyInfoShown(role: Role) {
  return role !== Role.HIGHER_EDU;
}

function eduInfoShown(role: Role) {
  return [Role.STUDENT, Role.PHD, Role.ALUMNUS, Role.HIGHER_EDU].includes(role);
}

function subjectShown(role: Role) {
  return role === Role.HIGHER_EDU;
}

function subjectAreaShown(role: Role) {
  return eduInfoShown(role) && role !== Role.ALUMNUS;
}

function functionShown(role: Role) {
  return role === Role.HIGHER_EDU;
}

export function Signup() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItem, setCmsItem] = useState<CmsItem>();
  const { type } = useParams<{ type: string }>();
  const [role, setRole] = useState<Role>(roleStringToRole(type || "student"));
  const [firstName, setFirstName] = useState<string>("");
  const [lastName, setLastName] = useState<string>("");
  const [email, setEmail] = useState<string>("");
  const [gender, setGender] = useState<Gender>("M");
  const [language, setLanguage] = useState<string>("en"); // TODO detect language
  const [nationality, setNationality] = useState<string>("BE"); // TODO detect country
  const [birthDate, setBirthDate] = useState<string>("");
  const [phoneNumber, setPhoneNumber] = useState<string>("");

  const [companyName, setCompanyName] = useState<string>("");
  const [legalForm, setLegalForm] = useState<string>("");
  const [vat, setVat] = useState<string>("");
  const [companyIndustry, setCompanyIndustry] = useState<string>("FinTech");
  const [country, setCountry] = useState<string>("BE");
  const [street, setStreet] = useState<string>("");
  const [streetNumber, setStreetNumber] = useState<string>("");
  const [city, setCity] = useState<string>("");
  const [zip, setZip] = useState<string>("");

  const [institution, setInstitution] = useState<string>("");
  const [subject, setSubject] = useState<string>("");
  const [subjectArea, setSubjectArea] = useState("BE");
  const [eduFunction, setEduFunction] = useState("");

  const [expertise, setExpertise] = useState("");

  const [investorType, setInvestorType] = useState("Private");

  const [password, setPassword] = useState<string>("");
  const [repeatPassword, setRepeatPassword] = useState<string>("");

  const [acceptTerms, setAcceptTerms] = useState(false);
  const [newsletter, setNewsletter] = useState(false);

  const navigate = useNavigate();

  const { t } = useTranslation(["signup", "common"]);

  async function onSubmitSignup(): Promise<void> {
    if (password !== repeatPassword) {
      toast.error(t("passwordsMustMatch"), {
        position: toast.POSITION.BOTTOM_CENTER,
        hideProgressBar: true,
      });
      return;
    }

    if (!acceptTerms) {
      toast.error(t("mustAcceptTerms"), {
        position: toast.POSITION.BOTTOM_CENTER,
        hideProgressBar: true,
      });
      return;
    }

    try {
      await AuthApi.registerAccount({
        name: firstName,
        surname: lastName,
        email,
        sex: gender,
        birthDate,
        language,
        password,
        nationality,
        phoneNumber,
        role,
        companyInfo: companyInfoShown(role)
          ? {
              name: companyName,
              vatNumber: vat,
              legalForm: legalForm,
              industry: companyIndustry,
              street,
              streetNumber,
              city,
              zipCode: zip,
              country,
            }
          : undefined,
        educationInfo: eduInfoShown(role)
          ? {
              institution,
              subject: subjectShown(role) ? subject : undefined,
              subjectArea: subjectAreaShown(role) ? subjectArea : undefined,
              function: functionShown(role) ? eduFunction : undefined,
            }
          : undefined,
        investorInfo:
          role === Role.INVESTOR
            ? {
                investorType,
              }
            : undefined,
        mentorInfo:
          role === Role.MENTOR
            ? {
                expertise,
              }
            : undefined,
      });

      if (newsletter) {
        await NewsletterApi.subscribeToNewsletter(email);
      }

      navigate(availableRoutes.checkEmail);
    } catch (e) {
      if (e instanceof AxiosError && e.response?.status === 409) {
        // email already exists
        toast.error(t("chooseDifferentEmail"), {
          position: toast.POSITION.BOTTOM_CENTER,
          hideProgressBar: true,
        });
      } else {
        throw e;
      }
    }
  }

  useEffect(() => {
    if (!role) {
      return;
    }

    if (role === type) {
      return;
    }

    navigate(`${availableRoutes.signup}/${roleToRoleString(role)}`, {
      replace: true,
    });
  }, [role]);

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.SIGNUP_SECTION,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItem(items[0]));
  }, []);

  return (
    <AnimDiv className="signup-wrapper flex">
      <div className="left-content">
        <ImageOrVideo
          assetUrl={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItem?.cmsContents
          )}
          className={"image"}
          alt={"bg"}
        />
      </div>
      <div className="form-container">
        <div className="purple-box">
          <div className="purple-box-text">{t("common:signup")}</div>
        </div>
        <form
          className="content flex flex-column"
          onSubmit={(e) => {
            e.preventDefault();
            onSubmitSignup();
          }}
        >
          <div className="title flex">
            <Trans i18nKey="signingUpAs" t={t}>
              <Dropdown
                value={role}
                onChange={(e) => {
                  setRole(e as Role);
                }}
                options={[
                  { label: t("student"), value: Role.STUDENT },
                  { label: t("phdPostdoc"), value: Role.PHD },
                  { label: t("alumnus"), value: Role.ALUMNUS },
                  { label: t("corporate"), value: Role.COMPANY },
                  { label: t("higherEducation"), value: Role.HIGHER_EDU },
                  { label: t("investor"), value: Role.INVESTOR },
                  { label: t("mentor"), value: Role.MENTOR },
                ]}
              />
            </Trans>
          </div>
          <div className="form-input-container flex flex-row">
            <Input
              label={t("firstName")}
              value={firstName}
              type="input"
              onChange={(e) => {
                setFirstName(e);
              }}
              autocomplete={"given-name"}
            />
            <Input
              label={t("lastName")}
              value={lastName}
              type="input"
              onChange={(e) => {
                setLastName(e);
              }}
              autocomplete={"family-name"}
            />
            <Input
              label={t("common:email")}
              value={email}
              type="email"
              onChange={(e) => {
                setEmail(e);
              }}
              autocomplete={"username"}
            />
            <Select
              label={t("gender")}
              value={gender}
              onChange={(e) => {
                setGender(e as Gender);
              }}
              options={[
                { label: "Male", value: "M" },
                { label: "Female", value: "F" },
                { label: "Other", value: "X" },
              ]}
            />
            <Select
              label={t("language")}
              value={language}
              onChange={(e) => {
                setLanguage(e);
              }}
              options={languages.map((language) => {
                return {
                  label: language.name,
                  value: language.code,
                };
              })}
            />
            <Select
              label={t("nationality")}
              value={nationality}
              onChange={(e) => {
                setNationality(e);
              }}
              options={countries.map((country) => {
                return {
                  label: country.name,
                  value: country.code,
                };
              })}
            />
            <Input
              label={t("birthDate")}
              value={birthDate}
              type="date"
              onChange={(e) => {
                setBirthDate(e);
              }}
              autocomplete={"bday"}
            />
            {functionShown(role) && (
              <Input
                label={t("function")}
                value={eduFunction}
                type="text"
                onChange={(e) => {
                  setEduFunction(e);
                }}
                required={role !== Role.HIGHER_EDU}
              />
            )}
            {role !== Role.INVESTOR && (
              <Input
                label={t("phoneNumber")}
                value={phoneNumber}
                type="text"
                pattern={
                  "^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$"
                }
                title={"Please check your phone number."}
                onChange={(e) => {
                  setPhoneNumber(e);
                }}
              />
            )}
          </div>
          {role === Role.INVESTOR && (
            <Fragment>
              <div className="title color-purple">
                {t("aboutYourInvesting")}
              </div>
              <div className="form-input-container flex flex-row">
                <Select
                  label={t("investorType")}
                  value={investorType}
                  onChange={setInvestorType}
                  options={[
                    { label: "Private", value: "Private" },
                    { label: "Business Angel", value: "Business Angel" },
                    { label: "Other", value: "Other" },
                  ]}
                />
              </div>
            </Fragment>
          )}
          {companyInfoShown(role) && (
            <Fragment>
              <div className="title color-purple">{t("aboutYourCompany")}</div>
              <div className="form-input-container flex flex-row">
                <Input
                  label={t("companyName")}
                  value={companyName}
                  type="input"
                  onChange={(e) => {
                    setCompanyName(e);
                  }}
                  required={
                    role !== Role.PHD &&
                    role !== Role.INVESTOR &&
                    role !== Role.MENTOR
                  }
                />
                <Input
                  label={t("legalForm")}
                  value={legalForm}
                  type="input"
                  onChange={(e) => {
                    setLegalForm(e);
                  }}
                  required={
                    role !== Role.PHD &&
                    role !== Role.INVESTOR &&
                    role !== Role.MENTOR
                  }
                />
                <Input
                  label={t("vatNumber")}
                  value={vat}
                  type="input"
                  onChange={(e) => {
                    setVat(e);
                  }}
                  required={
                    role !== Role.PHD &&
                    role !== Role.INVESTOR &&
                    role !== Role.MENTOR
                  }
                />
                <Select
                  label={t("industry")}
                  value={companyIndustry}
                  onChange={setCompanyIndustry}
                  options={industries}
                  required={role !== Role.PHD && role !== Role.MENTOR}
                />
                <Select
                  label={t("country")}
                  value={country}
                  onChange={(e) => {
                    setCountry(e);
                  }}
                  options={countries.map((country) => {
                    return {
                      label: country.name,
                      value: country.code,
                    };
                  })}
                  required={
                    role !== Role.PHD &&
                    role !== Role.INVESTOR &&
                    role !== Role.MENTOR
                  }
                />
                <Input
                  label={t("street")}
                  value={street}
                  onChange={(e) => {
                    setStreet(e);
                  }}
                  required={
                    role !== Role.PHD &&
                    role !== Role.INVESTOR &&
                    role !== Role.MENTOR
                  }
                />
                <Input
                  label={t("streetNumber")}
                  className="nr"
                  value={streetNumber}
                  onChange={(e) => {
                    setStreetNumber(e);
                  }}
                  required={
                    role !== Role.PHD &&
                    role !== Role.INVESTOR &&
                    role !== Role.MENTOR
                  }
                />
                <Input
                  label={t("city")}
                  className="city"
                  value={city}
                  onChange={(e) => {
                    setCity(e);
                  }}
                  required={
                    role !== Role.PHD &&
                    role !== Role.INVESTOR &&
                    role !== Role.MENTOR
                  }
                />
                <Input
                  label={t("zipCode")}
                  className="zip"
                  value={zip}
                  onChange={(e) => {
                    setZip(e);
                  }}
                  autocomplete={"postal-code"}
                  required={
                    role !== Role.PHD &&
                    role !== Role.INVESTOR &&
                    role !== Role.MENTOR
                  }
                />
              </div>
            </Fragment>
          )}
          {role === Role.MENTOR && (
            <Fragment>
              <div className="title color-purple">
                {t("aboutYourExperience")}
              </div>
              <div className="form-input-container flex flex-row">
                <Input
                  label={t("Expertise")}
                  value={expertise}
                  type="input"
                  onChange={(e) => {
                    setExpertise(e);
                  }}
                />
              </div>
            </Fragment>
          )}
          {eduInfoShown(role) && (
            <Fragment>
              <div className="title color-purple">
                {t("aboutYourEducation")}
              </div>
              <div className="form-input-container flex flex-row">
                <Input
                  label={t("eduInstitution")}
                  value={institution}
                  type="input"
                  onChange={(e) => {
                    setInstitution(e);
                  }}
                />
                {subjectAreaShown(role) && (
                  <Select
                    label={t("subjectArea")}
                    value={subjectArea}
                    onChange={(e) => {
                      setSubjectArea(e);
                    }}
                    options={countries.map((country) => {
                      return {
                        label: country.name,
                        value: country.code,
                      };
                    })}
                  />
                )}
                {subjectShown(role) && (
                  <Select
                    label={t("subject")}
                    value={subject}
                    onChange={(e) => {
                      setSubject(e);
                    }}
                    options={HigherEductionSubjects.map((item) => {
                      return {
                        label: item,
                        value: item,
                      };
                    })}
                  />
                )}
              </div>
            </Fragment>
          )}
          <div className="title color-purple">{t("aboutYourAccount")}</div>
          <div className="form-input-container flex flex-row">
            <Input
              label={t("common:password")}
              type="password"
              value={password}
              onChange={(e) => {
                setPassword(e);
              }}
              autocomplete={"new-password"}
            />
            <Input
              label={t("repeatPassword")}
              type="password"
              value={repeatPassword}
              onChange={(e) => {
                setRepeatPassword(e);
              }}
              autocomplete={"new-password"}
            />
          </div>
          <div className="checkbox">
            <Checkbox
              title={
                <Trans i18nKey="iAcceptTerms" t={t}>
                  <Link to={"/"} className="color-black" />
                </Trans>
              }
              value={acceptTerms}
              onClick={(v) => {
                setAcceptTerms(v);
              }}
            />
            <Checkbox
              title={<Fragment>{t("keepMeUpdated")}</Fragment>}
              value={newsletter}
              onClick={(v) => {
                setNewsletter(v);
              }}
            />
          </div>
          <div className="flex">
            <span className="color-red mr-1">*</span>
            {t("requiredFields")}
          </div>
          <button type="submit" className="button ml-auto purple uppercase">
            {t("createAccount")}
          </button>
        </form>
      </div>
    </AnimDiv>
  );
}
