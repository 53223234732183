import React, { useEffect, useState } from "react";
import { ActionsRenderer } from "../renderers/ActionsRenderer";
import DealsApi, { DealsAccessDiscountCode } from "../../api/DealsApi";
import { AgGridReact } from "ag-grid-react";
import { ValueFormatterParams } from "ag-grid-community";
import { DateTime } from "luxon";
import Dialog from "../Dialog";
import { Input } from "../Input";
import { Select } from "../Select";

export function DealsDiscountTable({
  discountCodes,
  setDiscountCodes,
}: {
  discountCodes: DealsAccessDiscountCode[];
  setDiscountCodes: (newCodes: DealsAccessDiscountCode[]) => void;
}) {
  const [create, setCreate] = useState(false);
  const [codeToEdit, setCodeToEdit] = useState<
    undefined | DealsAccessDiscountCode
  >(undefined);
  const [discountCode, setDiscountCode] = useState<string>("");
  const [discountPercentage, setDiscountPercentage] = useState<string>("");
  const [discountUses, setDiscountUses] = useState<string>("");
  const [expirationDate, setExpirationDate] = useState<string>("");
  const [active, setActive] = useState<string>("Active");

  useEffect(() => {
    if (codeToEdit) {
      setDiscountCode(codeToEdit.code);
      setDiscountUses(codeToEdit.usesLeft.toString());
      setDiscountPercentage(codeToEdit.discountPercentage.toString());
      const date = new Date(codeToEdit.expiry);
      setExpirationDate(date.toISOString().split("T")[0]);
      setActive(codeToEdit.active ? "Active" : "Inactive");
    }
  }, [codeToEdit]);

  const columnDefs = [
    { field: "code", filter: true },
    {
      field: "discountPercentage",
      headerName: "Discount (%)",
    },
    {
      field: "expiry",
      headerName: "Expiration date",
      valueFormatter: (
        params: ValueFormatterParams<DealsAccessDiscountCode>
      ) => {
        return DateTime.fromISO(params.value).toFormat("dd/LL/y");
      },
    },
    { field: "usesLeft" },
    {
      field: "active",
      cellRenderer: ({ value }: { value: boolean }) => (value ? "Yes" : "No"),
    },
    {
      field: "id",
      headerName: "",
      cellRenderer: ActionsRenderer,
      cellRendererParams: {
        edit: (id: string) => {
          setCodeToEdit(discountCodes.find((code) => code.id === id));
        },
        remove: async (id: string) => {
          const codeToDelete = discountCodes.find((code) => code.id === id);
          if (codeToDelete) {
            await DealsApi.deleteAccessDiscountCode(codeToDelete.id);
            setDiscountCodes(
              discountCodes.filter((item) => item.id !== codeToDelete.id)
            );
          }
        },
        includeRemove: true,
      },
      sortable: false,
    },
  ];

  const defaultColDef = {
    sortable: true,
    autosize: true,
  };

  return (
    <div className="mb-4">
      <div className="flex items-center">
        <div className="title small mt-4 mb-4">Deals access discount codes</div>
        <div
          className="button ml-auto"
          onClick={() => {
            setCreate(true);
            setDiscountCode("");
            setDiscountUses("");
            setDiscountPercentage("");
            setExpirationDate("");
            setActive("Active");
          }}
        >
          Add code
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ height: "400px" }}>
        <AgGridReact
          rowData={discountCodes}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
        />
      </div>
      <Dialog
        isOpen={create || codeToEdit !== undefined}
        onRequestClose={() => {
          setCreate(false);
          setCodeToEdit(undefined);
        }}
        title={`${codeToEdit !== null ? "Edit" : "Add new"} discount code`}
        size="large"
        padding={true}
      >
        <form
          onSubmit={async (e) => {
            e.preventDefault();

            const expirationDateDate = new Date(expirationDate);
            const newDiscountCode = {
              code: discountCode,
              discountPercentage: parseFloat(discountPercentage),
              active: active === "Active",
              usesLeft: parseFloat(discountUses),
              expiry: expirationDateDate,
            };

            if (create) {
              const result = await DealsApi.createAccessDiscountCode(
                newDiscountCode
              );

              setCreate(false);
              setDiscountCodes([
                ...discountCodes,
                {
                  ...result,
                },
              ]);
            }

            if (codeToEdit) {
              const result = await DealsApi.updateAccessDiscountCode(
                codeToEdit.id,
                newDiscountCode
              );
              setDiscountCodes([
                ...discountCodes,
                {
                  ...newDiscountCode,
                  expiry: result.expiry,
                  id: result.id,
                },
              ]);
              setCodeToEdit(undefined);
            }
          }}
        >
          <Input
            label={"Discount code"}
            value={discountCode}
            onChange={setDiscountCode}
            type={"text"}
          />
          <Input
            label={"Discount percentage"}
            value={discountPercentage}
            type={"number"}
            onChange={setDiscountPercentage}
          />
          <Input
            label={"Number of users"}
            value={discountUses}
            type={"number"}
            onChange={setDiscountUses}
          />
          <Input
            label={"Expiration date"}
            value={expirationDate}
            type={"date"}
            onChange={setExpirationDate}
          />
          <Select
            label={"Discount code state"}
            value={active}
            onChange={setActive}
            options={[
              { label: "Active", value: "Active" },
              { label: "Inactive", value: "Inactive" },
            ]}
          />
          <button type="submit" className="button mt-4">
            Save
          </button>
        </form>
      </Dialog>
    </div>
  );
}
