import React, { useState } from "react";
import { CmsHeader } from "../../components/cms/CmsHeader";
import { CmsSidebar } from "../../components/cms/CmsSidebar";
import { CmsTable } from "../../components/cms/CmsTable";
import { CmsContentType } from "../../enums/CmsContentType";
import { CmsSection } from "../../enums/CmsSection";
import { CopyPopup } from "../../components/cms/CopyPopup";

export function CmsFaq() {
  const [openCopy, setOpenCopy] = useState(false);

  return (
    <div>
      <CmsHeader />
      <div className="flex">
        <CmsSidebar />
        <div className="container">
          <div className="flex mb-4 items-center mt-4">
            <div className="title">FAQ</div>
            <div
              className="button small cta purple ml-4"
              onClick={() => {
                setOpenCopy(true);
              }}
            >
              Copy content
            </div>
          </div>
          <CmsTable
            sectionName="FAQ"
            section={CmsSection.FAQ}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={false}
            showItemTypeSelect={false}
            height={"800px"}
          />
        </div>
      </div>
      <CopyPopup
        page={"FAQ"}
        open={openCopy}
        setOpen={setOpenCopy}
        cmsSections={[CmsSection.FAQ]}
      />
    </div>
  );
}
