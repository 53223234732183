import { useState } from "react";
import { CmsContentType } from "../../enums/CmsContentType";
import { CmsForm, CmsFormProps } from "./CmsForm";
import {
  getContentValueForType,
  getContentIdForType,
} from "./getContentForType";

export function DealsPopupForm({
  onSave,
  contents,
  itemType,
  availableItemTypes,
  showItemTypeSelect,
  loading,
  setLoading,
  cmsSection,
}: CmsFormProps) {
  const [title, setTitle] = useState<string>(
    getContentValueForType(CmsContentType.TITLE, contents)
  );

  const [description, setDescription] = useState<string>(
    getContentValueForType(CmsContentType.DESCRIPTION, contents)
  );

  const [description2, setDescription2] = useState<string>(
    getContentValueForType(CmsContentType.DESCRIPTION2, contents)
  );

  const [ctaText, setCtaText] = useState<string>(
    getContentValueForType(CmsContentType.CALL_TO_ACTION_TEXT, contents)
  );

  const [discountText, setDiscountText] = useState<string>(
    getContentValueForType(CmsContentType.SUBTITLE, contents)
  );

  const inputData = [
    {
      label: "Title",
      value: title,
      valueSetter: setTitle,
      contentType: CmsContentType.TITLE,
      contentId: getContentIdForType(CmsContentType.TITLE, contents),
    },
    {
      label: "Description",
      value: description,
      valueSetter: setDescription,
      contentType: CmsContentType.DESCRIPTION,
      contentId: getContentIdForType(CmsContentType.DESCRIPTION, contents),
    },
    {
      label: "Checkbox text",
      value: description2,
      valueSetter: setDescription2,
      contentType: CmsContentType.DESCRIPTION2,
      contentId: getContentIdForType(CmsContentType.DESCRIPTION2, contents),
    },
    {
      label: "Discount entry text",
      value: discountText,
      valueSetter: setDiscountText,
      contentType: CmsContentType.SUBTITLE,
      contentId: getContentIdForType(CmsContentType.SUBTITLE, contents),
    },
    {
      label: "Button text",
      value: ctaText,
      valueSetter: setCtaText,
      contentType: CmsContentType.CALL_TO_ACTION_TEXT,
      contentId: getContentIdForType(
        CmsContentType.CALL_TO_ACTION_TEXT,
        contents
      ),
    },
  ];

  return (
    <CmsForm
      inputData={inputData}
      onSave={onSave}
      showItemTypeSelect={showItemTypeSelect}
      itemType={itemType}
      availableItemTypes={availableItemTypes}
      loading={loading}
      setLoading={setLoading}
      cmsSection={cmsSection}
    />
  );
}
