import ApiInstance from "./ApiInstance";

type NewDealsAccessDiscountCode = {
  code: string;
  discountPercentage: number;
  active: boolean;
  usesLeft: number;
  expiry: Date;
};

export type DealsAccessDiscountCode = NewDealsAccessDiscountCode & {
  id: string;
};

type valid = { valid: boolean };

class DealsApi {
  public async updateDealsPermission(
    permission: boolean,
    userId: string,
    discountCode?: string
  ): Promise<void> {
    await ApiInstance.getApi().put(`/deals/permissions/${userId}`, {
      permission,
      accessDiscountCode: discountCode,
    });
  }

  public async getAllAccessDiscountCodes(): Promise<DealsAccessDiscountCode[]> {
    const result = await ApiInstance.getApi().get<DealsAccessDiscountCode[]>(
      "/deals/access-discount-codes"
    );

    return result.data;
  }

  public async getDiscountCodeValidity(discountCode: string): Promise<valid> {
    const result = await ApiInstance.getApi().get<valid>(
      `deals/access-discount-codes/${discountCode}/validity`
    );

    return result.data;
  }

  public async createAccessDiscountCode(
    discountCode: NewDealsAccessDiscountCode
  ): Promise<DealsAccessDiscountCode> {
    const result = await ApiInstance.getApi().post<DealsAccessDiscountCode>(
      "/deals/access-discount-codes",
      discountCode
    );

    return result.data;
  }

  public async updateAccessDiscountCode(
    id: string,
    discountCode: NewDealsAccessDiscountCode
  ): Promise<DealsAccessDiscountCode> {
    const result = await ApiInstance.getApi().put<DealsAccessDiscountCode>(
      `/deals/access-discount-codes/${id}`,
      discountCode
    );

    return result.data;
  }

  public async deleteAccessDiscountCode(
    id: string
  ): Promise<DealsAccessDiscountCode> {
    const result = await ApiInstance.getApi().delete<DealsAccessDiscountCode>(
      `/deals/access-discount-codes/${id}`
    );

    return result.data;
  }

  public async getDeal(deal: string): Promise<{ type: string; value: string }> {
    const result = await ApiInstance.getApi().get<{
      type: string;
      value: string;
    }>(`/deals/get/${deal}`);

    return result.data;
  }
}

export default new DealsApi();
