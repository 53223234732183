import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { ActionsRenderer } from "../renderers/ActionsRenderer";
import SettingsApi, {
  VisibilityForCountry,
  HeaderLinkVisibility,
} from "../../api/SettingsApi";
import Dialog from "../Dialog";
import { SETTING_HEADER_LINK_VISIBILITY } from "../../constants/SettingsKeys";
import { HeaderLinkForm } from "./HeaderLinkForm";
import { useTranslation } from "react-i18next";

export function HeaderLinkTable() {
  const [headerLinkVisibility, setHeaderLinkVisibility] =
    useState<HeaderLinkVisibility>({});
  const [create, setCreate] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<VisibilityForCountry | null>(
    null
  );
  const [itemToEditCountry, setItemToEditCountry] = useState<string | null>(
    null
  );
  const [countryToDelete, setCountryToDelete] = useState<string | null>(null);
  const { t } = useTranslation();

  useEffect(() => {
    SettingsApi.getHeaderLinkVisibility().then((result) =>
      setHeaderLinkVisibility(result)
    );
  }, []);

  const columnDefs = [
    {
      field: "country",
      filter: true,
      resizable: true,
      suppressSizeToFit: true,
    },
    {
      field: "country",
      headerName: "Actions",
      width: 100,
      cellRenderer: ActionsRenderer,
      cellRendererParams: {
        edit: (country: string) => {
          setItemToEdit(headerLinkVisibility[country] || null);
          setItemToEditCountry(country);
        },
        remove: (country: string) => {
          setCountryToDelete(country);
        },
        includeRemove: true,
      },
      filter: false,
      sortable: false,
      suppressSizeToFit: true,
    },
  ];

  const defaultColDef = {
    sortable: true,
    autosize: true,
  };

  return (
    <div>
      <div className="flex mb-4 items-center mt-4">
        <div className="title small">Header link visibility</div>
        <div
          className="button ml-auto"
          onClick={() => {
            setCreate(true);
          }}
        >
          Add country
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ height: "800px" }}>
        <AgGridReact
          rowData={Object.keys(headerLinkVisibility).map((country) => ({
            country,
          }))}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="single"
          onGridReady={(grid) => {
            grid.api.sizeColumnsToFit();
          }}
        />
      </div>
      <Dialog
        isOpen={create || itemToEdit !== null}
        onRequestClose={() => {
          setCreate(false);
          setItemToEdit(null);
          setItemToEditCountry(null);
        }}
        title={`${create ? "Add new" : "Edit"} country`}
        size={"small"}
        padding={true}
      >
        <HeaderLinkForm
          currentSelectedCountries={
            itemToEdit
              ? Object.keys(headerLinkVisibility).filter(
                  (country) => country !== itemToEditCountry
                )
              : Object.keys(headerLinkVisibility)
          }
          save={async (newVisibilityForCountry, country) => {
            try {
              const newHeaderLinkVisibility = { ...headerLinkVisibility };
              newHeaderLinkVisibility[country] = newVisibilityForCountry;

              if (itemToEditCountry !== null && itemToEditCountry !== country) {
                delete newHeaderLinkVisibility[itemToEditCountry];
              }

              await SettingsApi.updateSettings(
                SETTING_HEADER_LINK_VISIBILITY,
                newHeaderLinkVisibility
              );
              setHeaderLinkVisibility(newHeaderLinkVisibility);

              setItemToEdit(null);
              setItemToEditCountry(null);
              setCreate(false);
              window.location.reload();
            } catch (e) {
              Sentry.captureException(e);
              toast.error(t("error"), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
              });
            }
          }}
          initialVisibility={itemToEdit || undefined}
          initialCountry={itemToEditCountry || undefined}
        />
      </Dialog>
      <Dialog
        isOpen={countryToDelete !== null}
        onRequestClose={() => {
          setCountryToDelete(null);
        }}
        title={"Are you sure you want to delete this?"}
        size={"small"}
        padding={true}
      >
        <div className="flex">
          <div
            className="button purple"
            onClick={async () => {
              if (!countryToDelete) {
                return;
              }

              try {
                const newHeaderLinkVisibility = { ...headerLinkVisibility };
                delete newHeaderLinkVisibility[countryToDelete];
                await SettingsApi.updateSettings(
                  SETTING_HEADER_LINK_VISIBILITY,
                  newHeaderLinkVisibility
                );
                setHeaderLinkVisibility(newHeaderLinkVisibility);
                setCountryToDelete(null);
                window.location.reload();
              } catch (e) {
                Sentry.captureException(e);
                toast.error(t("error"), {
                  position: toast.POSITION.TOP_CENTER,
                  hideProgressBar: true,
                });
              }
            }}
          >
            Delete
          </div>
          <div
            className="button ml-4"
            onClick={() => {
              setCountryToDelete(null);
            }}
          >
            Cancel
          </div>
        </div>
      </Dialog>
    </div>
  );
}
