export enum CmsItemType {
  DEFAULT = "DEFAULT",
  IMG_SQUARE = "IMG_SQUARE",
  IMG_RECT = "IMG_RECT",
  TEXT_SQUARE = "TEXT_SQUARE",
  TEXT_RECT = "TEXT_RECT",
}

export function getLabelForItemType(type: CmsItemType) {
  switch (type) {
    case CmsItemType.IMG_SQUARE:
      return "Square image";
    case CmsItemType.IMG_RECT:
      return "Rectangle image";
    case CmsItemType.TEXT_SQUARE:
      return "Square text";
    case CmsItemType.TEXT_RECT:
      return "Rectangle text";
    case CmsItemType.DEFAULT:
    default:
      return "Default";
  }
}

export function getCSSForItemType(type: CmsItemType) {
  switch (type) {
    case CmsItemType.IMG_SQUARE:
      return "img-square";
    case CmsItemType.IMG_RECT:
      return "img-rect";
    case CmsItemType.TEXT_SQUARE:
      return "text-square";
    case CmsItemType.TEXT_RECT:
      return "text-rect";
    case CmsItemType.DEFAULT:
    default:
      return "Default";
  }
}
