import React, { Fragment, useContext, useEffect, useState } from "react";
import "./HomeSection8.css";
import { UserCountryLanguageContext } from "../../context/UserCountryLanguageContext";
import CmsApi, { CmsItem } from "../../api/CmsApi";
import { CmsSection } from "../../enums/CmsSection";
import { getContentValueForType } from "../cms/getContentForType";
import { CmsContentType } from "../../enums/CmsContentType";
import { ImageOrVideo } from "../ImageOrVideo";
import Placeholder from "../../assets/placeholder.png";
import { Button } from "../Button";
import AnimDiv from "../AnimDiv";
import { Link } from "react-router-dom";
import { getTarget } from "../../util";

export function HomeSection8() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [headCmsItem, setHeadCmsItem] = useState<CmsItem>();
  const [cmsItems, setCmsItems] = useState<CmsItem[]>();

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.HOMEPAGE_SECTION8,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItems(items));

    CmsApi.getCmsItems(
      CmsSection.HOMEPAGE_SECTION8_HEAD,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setHeadCmsItem(items[0]));
  }, [selectedCountryAndLanguage]);

  const sizes = ["w1-3", "w1-3", "w1-3", "w2-3", "w1-3", "w3-3"];

  return (
    <AnimDiv className="section8-wrapper">
      <div className="flex flex-mobile gap">
        <div className="title-wrapper">
          <h2>
            {getContentValueForType(
              CmsContentType.TITLE,
              headCmsItem?.cmsContents
            )}
          </h2>
        </div>
        {headCmsItem ? (
          <Button
            section={CmsSection.HOMEPAGE_SECTION8_HEAD}
            item={headCmsItem}
            className="ml-auto purple"
            textClassName="color-black"
          />
        ) : null}
      </div>
      <div className="boxes">
        {cmsItems?.map((item, index) => {
          const ctaButtonUrl =
            getContentValueForType(
              CmsContentType.CALL_TO_ACTION_URL,
              item.cmsContents
            ) || "";
          const content = getContentValueForType(
            CmsContentType.CONTENT,
            item.cmsContents
          );

          const boxContent = (
            <Fragment>
              <div className="bg" />
              {item ? (
                <ImageOrVideo
                  assetUrl={
                    cmsItems
                      ? getContentValueForType(
                          CmsContentType.ASSET_URL,
                          item.cmsContents
                        )
                      : Placeholder
                  }
                  className={"image"}
                  alt={"placeholder"}
                />
              ) : null}
              <div className="box-title">
                {getContentValueForType(CmsContentType.TITLE, item.cmsContents)}
              </div>
            </Fragment>
          );

          return content && !ctaButtonUrl ? (
            <Link
              className={`box pointer ${sizes[index % sizes.length]}`}
              to={`/detail/${item.id}`}
            >
              {boxContent}
            </Link>
          ) : ctaButtonUrl !== "" ? (
            <a
              className={`box pointer ${sizes[index % sizes.length]}`}
              href={ctaButtonUrl}
              target={getTarget(ctaButtonUrl)}
            >
              {boxContent}
            </a>
          ) : (
            <div className={`box ${sizes[index % sizes.length]}`}>
              {boxContent}
            </div>
          );
        })}
      </div>
    </AnimDiv>
  );
}
