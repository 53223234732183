import React from "react";
import { CmsSection } from "../enums/CmsSection";
import { CmsItem } from "../api/CmsApi";
import {
  getButtonColor,
  getCorrectColorIfSet,
  getTextColor,
} from "../enums/Color";
import { CmsContentType } from "../enums/CmsContentType";
import { getContentValueForType } from "./cms/getContentForType";
import { Link } from "react-router-dom";
import { getTarget } from "../util";

export function Button({
  section,
  item,
  className,
  textClassName,
}: {
  section: CmsSection;
  item: CmsItem;
  className?: string;
  textClassName?: string;
}) {
  const ctaButtonText =
    getContentValueForType(
      CmsContentType.CALL_TO_ACTION_TEXT,
      item.cmsContents
    ) || "";

  const ctaButtonUrl =
    getContentValueForType(
      CmsContentType.CALL_TO_ACTION_URL,
      item.cmsContents
    ) || "";

  const content = getContentValueForType(
    CmsContentType.CONTENT,
    item.cmsContents
  );

  if (content && !ctaButtonUrl) {
    return (
      <Link
        to={`/detail/${item.id}`}
        className={`cta-button button cta uppercase ${className} ${textClassName} ${getButtonColor(
          getCorrectColorIfSet(
            section,
            CmsContentType.COLOR_CALL_TO_ACTION,
            item.cmsContents
          )
        )} ${getTextColor(
          getCorrectColorIfSet(
            section,
            CmsContentType.COLOR_CALL_TO_ACTION_TEXT,
            item.cmsContents
          ),
          false
        )}`}
      >
        {ctaButtonText}
      </Link>
    );
  }

  if (ctaButtonUrl === "" || ctaButtonText === "") {
    return null;
  }

  return (
    <div
      className={`cta-button button cta uppercase ${className} ${getButtonColor(
        getCorrectColorIfSet(
          section,
          CmsContentType.COLOR_CALL_TO_ACTION,
          item.cmsContents
        )
      )}`}
    >
      <a
        href={ctaButtonUrl}
        className={`${textClassName} ${getTextColor(
          getCorrectColorIfSet(
            section,
            CmsContentType.COLOR_CALL_TO_ACTION_TEXT,
            item.cmsContents
          ),
          false
        )}`}
        target={getTarget(ctaButtonUrl)}
      >
        {ctaButtonText}
      </a>
    </div>
  );
}
