import React, { Fragment, useEffect, useState } from "react";
import "./ConfirmEmail.css";
import AuthApi from "../api/AuthApi";
import { ImageOrVideo } from "../components/ImageOrVideo";
import background from "../assets/headerBackgroundLarge.png";
import { useParams, useSearchParams } from "react-router-dom";
import AnimDiv from "../components/AnimDiv";

export function ConfirmEmail() {
  const [confirmed, setConfirmed] = useState(false);
  const [params] = useSearchParams();

  useEffect(() => {
    const email = params.get("email");
    const token = params.get("token");

    if (token && email) {
      AuthApi.confirmEmail(email, token).then(() => setConfirmed(true));
    }
  }, [params]);

  return (
    <div className="confirm">
      <Fragment>
        <AnimDiv className="confirm-hero flex">
          <ImageOrVideo assetUrl={background} className={"image"} alt={"bg"} />
          {confirmed ? (
            <div className="content flex justify-center items-center">
              <h2>Congratulations</h2>
              <div className="text">Your account has been activated.</div>
              <div className="text">
                You can now log into your Student Startups account!
              </div>
            </div>
          ) : (
            <div className="content text">
              Something went wrong, are you sure you visited a correct email
              confirmation link?
            </div>
          )}
        </AnimDiv>
      </Fragment>
    </div>
  );
}
