import React, { useContext, useEffect, useState } from "react";
import { ImageOrVideo } from "../components/ImageOrVideo";
import "./JoinForces.css";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";
import ManImage from "../assets/man.svg";
import { Link } from "react-router-dom";
import { Input } from "../components/Input";
import { Select } from "../components/Select";
import { Role } from "../enums/Role";
import { Checkbox } from "../components/Checkbox";
import { useTranslation } from "react-i18next";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { CmsSection } from "../enums/CmsSection";
import ContactApi from "../api/ContactApi";
import { getCorrectColorIfSet, getTextColor } from "../enums/Color";
import { Button } from "../components/Button";
import AnimDiv from "../components/AnimDiv";
import { ImageRhombus } from "../components/ImageRhombus";
import { toast } from "react-toastify";

export function JoinForces() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItemHero, setCmsItemHero] = useState<CmsItem>();
  const [cmsItemSection1, setCmsItemSection1] = useState<CmsItem>();
  const [cmsItemSection2, setCmsItemSection2] = useState<CmsItem>();
  const [cmsItemSection3, setCmsItemSection3] = useState<CmsItem>();
  const [cmsItemSection4, setCmsItemSection4] = useState<CmsItem>();
  const [cmsItemSection5, setCmsItemSection5] = useState<CmsItem>();
  const [cmsItemSection6, setCmsItemSection6] = useState<CmsItem>();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [iAm, setIAM] = useState(Role.STUDENT);
  const [lookingFor, setLookingFor] = useState(Role.COMPANY);
  const [message, setMessage] = useState("");
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const { t } = useTranslation(["contact", "signup"]);

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.FORCES_HERO,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemHero(items[0]));

    CmsApi.getCmsItems(
      CmsSection.FORCES_SECTION1,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection1(items[0]));

    CmsApi.getCmsItems(
      CmsSection.FORCES_SECTION2,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection2(items[0]));

    CmsApi.getCmsItems(
      CmsSection.FORCES_SECTION3,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection3(items[0]));

    CmsApi.getCmsItems(
      CmsSection.FORCES_SECTION4,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection4(items[0]));

    CmsApi.getCmsItems(
      CmsSection.FORCES_SECTION5,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection5(items[0]));

    CmsApi.getCmsItems(
      CmsSection.FORCES_SECTION6,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection6(items[0]));
  }, []);

  return (
    <AnimDiv className="forces">
      <div className="forces-hero transparent">
        <ImageOrVideo
          assetUrl={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItemHero?.cmsContents
          )}
          className={"image"}
          alt={"bg"}
        />
        <div
          className={`content flex ${getTextColor(
            getCorrectColorIfSet(
              CmsSection.FORCES_HERO,
              CmsContentType.COLOR_TITLE,
              cmsItemHero?.cmsContents
            ),
            true
          )}`}
        >
          <span className="purple">“</span>
          {getContentValueForType(
            CmsContentType.TITLE,
            cmsItemHero?.cmsContents
          )}
          <span className="purple mt-auto">”</span>
        </div>
      </div>
      <div className="forces-introduction-wrapper">
        <div className="introduction">
          <div className="left-content flex flex-column">
            <h2>
              {getContentValueForType(
                CmsContentType.TITLE,
                cmsItemSection1?.cmsContents
              )}
            </h2>
            <div className="text">
              {getContentValueForType(
                CmsContentType.DESCRIPTION,
                cmsItemSection1?.cmsContents
              )}
            </div>
            {cmsItemSection1 ? (
              <Button
                section={CmsSection.FORCES_SECTION1}
                item={cmsItemSection1}
                className="cta-button purple"
                textClassName="color-white"
              />
            ) : null}
          </div>
          <div className="right-content">
            <div className="image-wrapper">
              <img className="image" src={ManImage} alt="man" />
            </div>
          </div>
        </div>
      </div>
      <AnimDiv className="section-title">
        <h2>
          {getContentValueForType(
            CmsContentType.TITLE,
            cmsItemSection2?.cmsContents
          )}
        </h2>
      </AnimDiv>
      <div className="section">
        <ImageRhombus
          imgSrc={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItemSection2?.cmsContents
          )}
        />
        <div className="content content1">
          <div className="forces-title color-purple">
            {getContentValueForType(
              CmsContentType.SUBTITLE,
              cmsItemSection2?.cmsContents
            )}
          </div>
          <div className="text">
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemSection2?.cmsContents
            )}
          </div>
        </div>
      </div>
      <div className="line line1" />
      <div className="section black">
        <div className="content content2">
          <div className="forces-title color-purple">
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsItemSection3?.cmsContents
            )}
          </div>
          <div className="text">
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemSection3?.cmsContents
            )}
          </div>
        </div>
        <div className="circle">
          <img
            className="image"
            src={getContentValueForType(
              CmsContentType.ASSET_URL,
              cmsItemSection3?.cmsContents
            )}
          />
        </div>
      </div>
      <div className="line rotate line2" />
      <div className="section">
        <ImageRhombus
          imgSrc={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItemSection4?.cmsContents
          )}
        />
        <div className="content content3">
          <div className="forces-title color-purple">
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsItemSection4?.cmsContents
            )}
          </div>
          <div className="text">
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemSection4?.cmsContents
            )}
          </div>
        </div>
      </div>
      <div className="line line3" />
      <div className="section black">
        <div className="content">
          <div className="forces-title color-purple">
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsItemSection5?.cmsContents
            )}
          </div>
          <div className="text">
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemSection5?.cmsContents
            )}
          </div>
        </div>
        <div className="circle">
          <img
            className="image"
            src={getContentValueForType(
              CmsContentType.ASSET_URL,
              cmsItemSection5?.cmsContents
            )}
          />
        </div>
      </div>
      <div className="bg" />
      <AnimDiv className="wrapper">
        <h2>
          {getContentValueForType(
            CmsContentType.TITLE,
            cmsItemSection6?.cmsContents
          )}
        </h2>
        <div className="grid-box-wrapper">
          <img
            className="image"
            src={getContentValueForType(
              CmsContentType.ASSET_URL,
              cmsItemSection6?.cmsContents
            )}
          />
          <div className={`grid-box`}>
            <div className={`grid-content`}>
              <div className="figure" />
              <div className="content-wrapper flex flex-column overflow-hidden">
                <div className="grid-title">
                  {getContentValueForType(
                    CmsContentType.SUBTITLE,
                    cmsItemSection6?.cmsContents
                  )}
                </div>
                <div className="forces-excerpt">
                  {getContentValueForType(
                    CmsContentType.DESCRIPTION,
                    cmsItemSection6?.cmsContents
                  )}
                </div>
                {cmsItemSection6 ? (
                  <Button
                    section={CmsSection.FORCES_SECTION6}
                    item={cmsItemSection6}
                    className="ml-auto button-wrapper"
                    textClassName="color-white"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </AnimDiv>
      <AnimDiv className="contact-wrapper">
        <h2>Contact us</h2>
        <form
          className="contact-form"
          onSubmit={async (e) => {
            e.preventDefault();

            await ContactApi.sendJoinForcesMessage(
              `${firstName} ${lastName}`,
              phone,
              message,
              email,
              iAm,
              lookingFor
            );
            setFirstName("");
            setLastName("");
            setEmail("");
            setPhone("");
            setMessage("");
            toast.info("Successfully submitted your request!", {
              position: toast.POSITION.BOTTOM_CENTER,
              hideProgressBar: true,
            });
          }}
        >
          <div className="input-wrapper">
            <Input
              label={t("signup:firstName")}
              value={firstName}
              onChange={setFirstName}
              type="text"
              required={true}
            />
            <Input
              label={t("signup:lastName")}
              value={lastName}
              onChange={setLastName}
              type="text"
              required={true}
            />
          </div>
          <div className="input-wrapper">
            <Input
              label={"Email"}
              value={email}
              onChange={setEmail}
              type="email"
              required={true}
            />
            <Input
              label={"Phone"}
              value={phone}
              onChange={setPhone}
              type="text"
              required={true}
              pattern={
                "^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$"
              }
              title={"Please check your phone number."}
            />
          </div>
          <div className="input-wrapper">
            <div className="sentence">
              <div className="text-1">I am a:</div>
              <Select
                label={null}
                value={iAm}
                onChange={(v) => {
                  setIAM(v as Role);
                }}
                options={[
                  { label: t("signup:student"), value: Role.STUDENT },
                  { label: t("signup:phdPostdoc"), value: Role.PHD },
                  { label: t("signup:alumnus"), value: Role.ALUMNUS },
                  { label: t("signup:corporate"), value: Role.COMPANY },
                  {
                    label: t("signup:higherEducation"),
                    value: Role.HIGHER_EDU,
                  },
                  { label: t("signup:investor"), value: Role.INVESTOR },
                  { label: t("signup:mentor"), value: Role.MENTOR },
                ]}
              />
            </div>
            <div className="sentence">
              <div className="text-2">looking for a:</div>
              <Select
                label={null}
                value={lookingFor}
                onChange={(v) => {
                  setLookingFor(v as Role);
                }}
                options={[
                  { label: t("signup:student"), value: Role.STUDENT },
                  { label: t("signup:phdPostdoc"), value: Role.PHD },
                  { label: t("signup:alumnus"), value: Role.ALUMNUS },
                  { label: t("signup:corporate"), value: Role.COMPANY },
                  {
                    label: t("signup:higherEducation"),
                    value: Role.HIGHER_EDU,
                  },
                  { label: t("signup:investor"), value: Role.INVESTOR },
                  { label: t("signup:mentor"), value: Role.MENTOR },
                ]}
              />
            </div>
          </div>
          <Input
            label={t("contact:what")}
            value={message}
            onChange={setMessage}
            textArea={true}
          />
          <div className="flex mt-4 wrap">
            <Checkbox
              title={
                <div className={"color-black-80-opacity"}>
                  I accept the{" "}
                  <Link
                    to={"/privacy-policy"}
                    className={"color-black"}
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    privacy policy
                  </Link>
                </div>
              }
              value={acceptPolicy}
              onClick={setAcceptPolicy}
              required={true}
            />
            <button
              type="submit"
              className="button ml-auto uppercase purple cta"
            >
              {t("contact:submit")}
            </button>
          </div>
        </form>
      </AnimDiv>
    </AnimDiv>
  );
}
