import React from "react";
import Dialog from "./Dialog";
import CrossIcon from "../assets/cross.svg";

export function RevokedPopup({
  open,
  setOpen,
}: {
  open: boolean;
  setOpen: (v: boolean) => void;
}) {
  return (
    <Dialog
      isOpen={open}
      onRequestClose={() => {
        setOpen(false);
      }}
      title={""}
      size={"small"}
      padding={false}
      overflow={false}
    >
      <div className="country-popup">
        <h2>Access revoked</h2>
        <img
          className="close pointer"
          src={CrossIcon}
          alt="close"
          onClick={() => {
            setOpen(false);
          }}
        />
        <div className="content program">
          <div>
            Your access has been revoked, you don't have permissions anymore to
            view this resource.
          </div>
        </div>
      </div>
    </Dialog>
  );
}
