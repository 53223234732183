import React, { Fragment, useEffect, useState } from "react";
import "./ConfirmEmail.css";
import AuthApi from "../api/AuthApi";
import { ImageOrVideo } from "../components/ImageOrVideo";
import background from "../assets/headerBackgroundLarge.png";
import { useParams, useSearchParams } from "react-router-dom";

export function CheckEmail() {
  return (
    <div className="confirm">
      <Fragment>
        <div className="confirm-hero flex">
          <ImageOrVideo assetUrl={background} className={"image"} alt={"bg"} />
          <div className="content flex justify-center items-center">
            <h2>Thank you!</h2>
            <div className="text">
              We will send you an email to verify your account.
            </div>
          </div>
        </div>
      </Fragment>
    </div>
  );
}
