import React, { useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { useTranslation } from "react-i18next";
import { CmsHeader } from "../../components/cms/CmsHeader";
import { CmsSidebar } from "../../components/cms/CmsSidebar";
import AuthApi, { User } from "../../api/AuthApi";
import { CmsTable } from "../../components/cms/CmsTable";
import { CmsSection } from "../../enums/CmsSection";
import { CmsContentType } from "../../enums/CmsContentType";
import { CopyPopup } from "../../components/cms/CopyPopup";
import { DealsModerationTable } from "../../components/cms/DealsModerationTable";
import DealsApi, { DealsAccessDiscountCode } from "../../api/DealsApi";
import { DealsDiscountTable } from "../../components/cms/DealsDiscountTable";

export function CmsStartSmart() {
  const [openCopy, setOpenCopy] = useState(false);
  const [users, setUsers] = useState<User[]>([]);
  const [discountCodes, setDiscountCodes] = useState<DealsAccessDiscountCode[]>(
    []
  );
  const { t } = useTranslation("common");

  useEffect(() => {
    AuthApi.getAllUsers()
      .then((users) =>
        setUsers(
          users.filter(
            (user) => user.permittedForDeals || user.dealsPermissionRevoked
          )
        )
      )
      .catch((e) => {
        Sentry.captureException(e);
        toast.error(t("error"), {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      });

    DealsApi.getAllAccessDiscountCodes().then((discountCodes) => {
      setDiscountCodes(discountCodes);
    });
  }, []);

  return (
    <div>
      <CmsHeader />
      <div className="flex">
        <CmsSidebar />
        <div className="container">
          <div className="flex mb-4 items-center mt-4">
            <div className="title">Start Smart</div>
            <div
              className="button small cta purple ml-4"
              onClick={() => {
                setOpenCopy(true);
              }}
            >
              Copy content
            </div>
          </div>
          <CmsTable
            sectionName="Section 1"
            section={CmsSection.START_SMART_SECTION1}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
          />
          <CmsTable
            sectionName="Head"
            section={CmsSection.START_SMART_HEAD}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
          />
          <CmsTable
            sectionName="Deal"
            section={CmsSection.DEAL}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={false}
          />
          <CmsTable
            sectionName="Bottom"
            section={CmsSection.START_SMART_BOTTOM}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
          />
          <div className="flex mb-4 items-center mt-4">
            <div className="title">Access popup</div>
          </div>
          <CmsTable
            sectionName="Popup"
            section={CmsSection.DEALS_POPUP}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
          />
          <div className="title small mt-4 mb-4">Deals moderation</div>
          <DealsModerationTable users={users} />
          <DealsDiscountTable
            discountCodes={discountCodes}
            setDiscountCodes={setDiscountCodes}
          />
        </div>
      </div>
      <CopyPopup
        page={"Start Smart"}
        open={openCopy}
        setOpen={setOpenCopy}
        cmsSections={[
          CmsSection.START_SMART_SECTION1,
          CmsSection.START_SMART_HEAD,
          CmsSection.DEAL,
          CmsSection.START_SMART_BOTTOM,
          CmsSection.DEALS_POPUP,
        ]}
      />
    </div>
  );
}
