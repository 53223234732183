import React, { PropsWithChildren } from "react";
import CrossIcon from "../../assets/cross.svg";
import Dialog from "../Dialog";

export function GeneralPopup({
  open,
  setOpen,
  title,
  children,
  className,
}: PropsWithChildren<{
  open: boolean;
  title: string;
  setOpen: (v: boolean) => void;
  className?: string;
}>) {
  return (
    <Dialog
      isOpen={open}
      onRequestClose={() => {
        setOpen(false);
      }}
      title={""}
      size={"small"}
      padding={false}
      overflow={false}
    >
      <div className="country-popup">
        <h2>{title}</h2>
        <img
          className="close pointer"
          src={CrossIcon}
          alt="close"
          onClick={() => {
            setOpen(false);
          }}
        />
        <div className={`content program ${className}`}>{children}</div>
      </div>
    </Dialog>
  );
}
