import React, { useContext, useEffect, useRef, useState } from "react";
import "./HomeSection3.css";
import Placeholder from "../../assets/placeholder2.png";
import { motion } from "framer-motion";
import CmsApi, { CmsItem } from "../../api/CmsApi";
import { CmsSection } from "../../enums/CmsSection";
import { getContentValueForType } from "../cms/getContentForType";
import { CmsContentType } from "../../enums/CmsContentType";
import { CmsItemType, getCSSForItemType } from "../../enums/CmsItemType";
import { UserCountryLanguageContext } from "../../context/UserCountryLanguageContext";
import { ImageOrVideo } from "../ImageOrVideo";
import { getCorrectColorIfSet, getTextColor } from "../../enums/Color";
import { Button } from "../Button";
import AnimDiv from "../AnimDiv";

export function HomeSection3() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [width, setWidth] = useState(10);
  const dragSlider = useRef<HTMLDivElement | null>(null);
  const [headCmsItem, setHeadCmsItem] = useState<CmsItem>();
  const [cmsItems, setCmsItems] = useState<CmsItem[]>();

  useEffect(() => {
    if (!dragSlider.current) {
      return;
    }

    setWidth(dragSlider.current?.scrollWidth - dragSlider.current?.offsetWidth);
  }, [cmsItems]);

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.HOMEPAGE_SECTION3,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItems(items));

    CmsApi.getCmsItems(
      CmsSection.HOMEPAGE_SECTION3_HEAD,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setHeadCmsItem(items[0]));
  }, [selectedCountryAndLanguage]);

  return (
    <div className="section3-wrapper">
      <AnimDiv>
        <div className="flex justify-center">
          <h2>
            {getContentValueForType(
              CmsContentType.TITLE,
              headCmsItem?.cmsContents
            )}
          </h2>
        </div>
      </AnimDiv>
      <div className={"boxes-fade"}>
        <motion.div className="boxes" ref={dragSlider}>
          <motion.div
            className="flex scroll"
            ref={dragSlider}
            drag="x"
            dragConstraints={{ right: 0, left: -width }}
          >
            {cmsItems?.map((cmsItem, index) => {
              return (
                <AnimDiv
                  className={`box ${getCSSForItemType(cmsItem.type)}`}
                  key={index}
                >
                  <div className="content">
                    <div className="figure" />
                    {cmsItem.type === CmsItemType.IMG_RECT ||
                    cmsItem.type === CmsItemType.IMG_SQUARE ? (
                      <ImageOrVideo
                        assetUrl={
                          cmsItems
                            ? getContentValueForType(
                                CmsContentType.ASSET_URL,
                                cmsItem.cmsContents
                              )
                            : Placeholder
                        }
                        className={"image"}
                        alt={"placeholder"}
                      />
                    ) : (
                      <div
                        className={`box-content ${getTextColor(
                          getCorrectColorIfSet(
                            CmsSection.HOMEPAGE_SECTION3,
                            CmsContentType.COLOR_DESCRIPTION,
                            cmsItems[index].cmsContents
                          ),
                          false
                        )}`}
                      >
                        {cmsItems
                          ? getContentValueForType(
                              CmsContentType.DESCRIPTION,
                              cmsItems[index].cmsContents
                            )
                          : ""}
                      </div>
                    )}
                    <div
                      className={`box-title ${getTextColor(
                        getCorrectColorIfSet(
                          CmsSection.HOMEPAGE_SECTION3,
                          CmsContentType.COLOR_TITLE,
                          cmsItems[index].cmsContents
                        ),
                        true
                      )}`}
                    >
                      {cmsItems
                        ? getContentValueForType(
                            CmsContentType.TITLE,
                            cmsItems[index].cmsContents
                          )
                        : null}
                    </div>
                    <Button
                      section={CmsSection.HOMEPAGE_SECTION3}
                      item={cmsItem}
                    />
                  </div>
                </AnimDiv>
              );
            })}
          </motion.div>
        </motion.div>
      </div>
      <AnimDiv className="container section3-footer flex flex-column">
        {headCmsItem ? (
          <Button
            section={CmsSection.HOMEPAGE_SECTION3_HEAD}
            item={headCmsItem}
            className="cta-button purple"
            textClassName="color-white"
          />
        ) : null}
      </AnimDiv>
    </div>
  );
}
