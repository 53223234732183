import { motion } from "framer-motion";
import { CSSProperties, MouseEventHandler, PropsWithChildren } from "react";

export default function AnimDiv<T>({
  children,
  className,
  style,
  onClick,
}: {
  className?: string;
  style?: CSSProperties;
  onClick?: MouseEventHandler<HTMLDivElement>;
} & PropsWithChildren) {
  return (
    <motion.div
      initial={{
        opacity: 0,
      }}
      whileInView={{
        opacity: 1,
        transition: {
          duration: 0.8,
        },
      }}
      viewport={{ once: true }}
      style={style}
      className={className}
      onClick={onClick}
    >
      {children}
    </motion.div>
  );
}
