import React, { useContext, useEffect, useState } from "react";
import { IntroductionSection } from "../components/home/IntroductionSection";
import { HomeSection1 } from "../components/home/HomeSection1";
import { HomeSection2 } from "../components/home/HomeSection2";
import { CmsSection } from "../enums/CmsSection";
import { ImageOrVideo } from "../components/ImageOrVideo";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { Input } from "../components/Input";
import ContactApi from "../api/ContactApi";
import { getCorrectColorIfSet, getTextColor } from "../enums/Color";
import { Button } from "../components/Button";
import AnimDiv from "../components/AnimDiv";
import { toast } from "react-toastify";

export function Unbounded() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItemHero, setCmsItemHero] = useState<CmsItem>();
  const [cmsItemSection4, setCmsItemSection4] = useState<CmsItem>();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.UNBOUNDED_HERO,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemHero(items[0]));
    CmsApi.getCmsItems(
      CmsSection.UNBOUNDED_SECTION4,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection4(items[0]));
  }, [selectedCountryAndLanguage]);

  return (
    <div className="corporates about academy">
      <AnimDiv className="corporate-hero">
        <ImageOrVideo
          assetUrl={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItemHero?.cmsContents
          )}
          className={"image"}
          alt={"bg"}
        />
        <div className="content">
          <h2
            className={getTextColor(
              getCorrectColorIfSet(
                CmsSection.UNBOUNDED_HERO,
                CmsContentType.COLOR_TITLE,
                cmsItemHero?.cmsContents
              ),
              true
            )}
          >
            <div>
              {getContentValueForType(
                CmsContentType.TITLE,
                cmsItemHero?.cmsContents
              )}
            </div>
          </h2>
          <div
            className={`text ${getTextColor(
              getCorrectColorIfSet(
                CmsSection.UNBOUNDED_HERO,
                CmsContentType.COLOR_DESCRIPTION,
                cmsItemHero?.cmsContents
              ),
              false
            )}`}
          >
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemHero?.cmsContents
            )}
          </div>
          {cmsItemHero ? (
            <Button
              section={CmsSection.UNBOUNDED_HERO}
              item={cmsItemHero}
              className="ml-auto button-wrapper"
            />
          ) : null}
        </div>
      </AnimDiv>
      <IntroductionSection section={CmsSection.UNBOUNDED_SECTION1} />
      <HomeSection1 section={CmsSection.UNBOUNDED_SECTION2} />
      <HomeSection2
        section={CmsSection.UNBOUNDED_SECTION3}
        sectionHead={CmsSection.UNBOUNDED_SECTION3_HEAD}
      />
      <AnimDiv className="bottom gradient-overlay">
        <ImageOrVideo
          assetUrl={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItemSection4?.cmsContents
          )}
          className={"image z-1"}
          alt={"bg"}
        />
        <div className="content form-type">
          <div className="flex content-container">
            <form
              className="flex flex-column left"
              onSubmit={async (e) => {
                e.preventDefault();

                await ContactApi.sendUnboundedMessage(
                  `${firstName} ${lastName}`,
                  email,
                  phone,
                  message
                );
                setFirstName("");
                setLastName("");
                setEmail("");
                setPhone("");
                setMessage("");
                toast.info("Successfully submitted your request!", {
                  position: toast.POSITION.BOTTOM_CENTER,
                  hideProgressBar: true,
                });
              }}
            >
              <div className="form-title">Apply now</div>
              <div className="input-wrapper">
                <Input
                  color={"color-white opacity-80"}
                  transparent={true}
                  label={"Firstname"}
                  value={firstName}
                  onChange={setFirstName}
                  required={true}
                />
                <Input
                  color={"color-white opacity-80"}
                  transparent={true}
                  label={"Lastname"}
                  value={lastName}
                  onChange={setLastName}
                  required={true}
                />
              </div>
              <div className="input-wrapper">
                <Input
                  color={"color-white opacity-80"}
                  transparent={true}
                  label={"Email"}
                  value={email}
                  onChange={setEmail}
                  required={true}
                />
                <Input
                  color={"color-white opacity-80"}
                  transparent={true}
                  label={"Phone"}
                  value={phone}
                  onChange={setPhone}
                  pattern={
                    "^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$"
                  }
                  title={"Please check your phone number."}
                  required={true}
                />
              </div>
              <Input
                color={"color-white opacity-80"}
                transparent={true}
                label={"What's your idea?"}
                value={message}
                onChange={setMessage}
                textArea={true}
                required={true}
                rows={5}
              />
              <button
                type="submit"
                className="button ml-auto purple color-white uppercase cta mt-4"
              >
                Send application
              </button>
            </form>
            <div className="flex flex-column right">
              <div
                className={`content-title ${getTextColor(
                  getCorrectColorIfSet(
                    CmsSection.UNBOUNDED_SECTION4,
                    CmsContentType.COLOR_TITLE,
                    cmsItemSection4?.cmsContents
                  ),
                  true
                )}`}
              >
                {getContentValueForType(
                  CmsContentType.TITLE,
                  cmsItemSection4?.cmsContents
                )}
              </div>
              <div
                className={`text opacity-80 ${getTextColor(
                  getCorrectColorIfSet(
                    CmsSection.UNBOUNDED_SECTION4,
                    CmsContentType.COLOR_DESCRIPTION,
                    cmsItemSection4?.cmsContents
                  ),
                  false
                )}`}
              >
                {getContentValueForType(
                  CmsContentType.DESCRIPTION,
                  cmsItemSection4?.cmsContents
                )}
              </div>
              <div className="flex buttons">
                {cmsItemSection4 ? (
                  <Button
                    section={CmsSection.UNBOUNDED_SECTION4}
                    item={cmsItemSection4}
                    className="ml-auto button-wrapper"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </AnimDiv>
    </div>
  );
}
