import React, { Fragment, useContext, useEffect, useState } from "react";
import "./HomeSection7.css";
import { UserCountryLanguageContext } from "../../context/UserCountryLanguageContext";
import CmsApi, { CmsItem } from "../../api/CmsApi";
import { CmsSection } from "../../enums/CmsSection";
import { getContentValueForType } from "../cms/getContentForType";
import { CmsContentType } from "../../enums/CmsContentType";
import { ImageOrVideo } from "../ImageOrVideo";
import Placeholder from "../../assets/placeholder.png";
import { getCorrectColorIfSet, getTextColor } from "../../enums/Color";
import { Button } from "../Button";
import AnimDiv from "../AnimDiv";
import { getTarget } from "../../util";

export function HomeSection7() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [headCmsItem, setHeadCmsItem] = useState<CmsItem>();
  const [cmsItems, setCmsItems] = useState<CmsItem[]>();

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.HOMEPAGE_SECTION7,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItems(items));

    CmsApi.getCmsItems(
      CmsSection.HOMEPAGE_SECTION7_HEAD,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setHeadCmsItem(items[0]));
  }, [selectedCountryAndLanguage]);

  return (
    <div className="section7-wrapper">
      <div className="title-wrapper">
        <AnimDiv>
          <h2>
            {getContentValueForType(
              CmsContentType.TITLE,
              headCmsItem?.cmsContents
            )}
          </h2>
        </AnimDiv>
      </div>
      <div className="section7-bg" />
      <AnimDiv className="section7">
        {cmsItems?.map((item) => {
          const content = (
            <Fragment>
              {item ? (
                <ImageOrVideo
                  assetUrl={
                    cmsItems
                      ? getContentValueForType(
                          CmsContentType.ASSET_URL,
                          item.cmsContents
                        )
                      : Placeholder
                  }
                  className={"image"}
                  alt={"placeholder"}
                />
              ) : null}
              <div className="content">
                <div
                  className={`frame-title ${getTextColor(
                    getCorrectColorIfSet(
                      CmsSection.HOMEPAGE_SECTION7,
                      CmsContentType.COLOR_TITLE,
                      item.cmsContents
                    ),
                    true
                  )}`}
                >
                  {getContentValueForType(
                    CmsContentType.TITLE,
                    item.cmsContents
                  )}
                </div>
                <div
                  className={`frame-content ${getTextColor(
                    getCorrectColorIfSet(
                      CmsSection.HOMEPAGE_SECTION7,
                      CmsContentType.COLOR_DESCRIPTION,
                      item.cmsContents
                    ),
                    false
                  )}`}
                >
                  {getContentValueForType(
                    CmsContentType.DESCRIPTION,
                    item.cmsContents
                  )}
                </div>
                <Button
                  section={CmsSection.HOMEPAGE_SECTION7}
                  item={item}
                  className="ml-auto mt-auto"
                />
              </div>
            </Fragment>
          );

          const ctaButtonText =
            getContentValueForType(
              CmsContentType.CALL_TO_ACTION_TEXT,
              item.cmsContents
            ) || "";

          const ctaButtonUrl =
            getContentValueForType(
              CmsContentType.CALL_TO_ACTION_URL,
              item.cmsContents
            ) || "";

          if (ctaButtonText && ctaButtonUrl) {
            return (
              <a
                className="flex flex-column frame no-decoration"
                href={ctaButtonUrl}
                target={getTarget(ctaButtonUrl)}
              >
                {content}
              </a>
            );
          }

          return <div className="flex flex-column frame">{content}</div>;
        })}
      </AnimDiv>
    </div>
  );
}
