import React, { useContext, useEffect, useState } from "react";
import "./HomeSection4.css";
import Placeholder from "../../assets/placeholder.png";
import CmsApi, { CmsItem } from "../../api/CmsApi";
import { CmsSection } from "../../enums/CmsSection";
import { getContentValueForType } from "../cms/getContentForType";
import { CmsContentType } from "../../enums/CmsContentType";
import { UserCountryLanguageContext } from "../../context/UserCountryLanguageContext";
import { ImageOrVideo } from "../ImageOrVideo";
import { Button } from "../Button";
import AnimDiv from "../AnimDiv";

export function HomeSection4() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItems, setCmsItems] = useState<CmsItem[]>();

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.HOMEPAGE_SECTION4,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItems(items));
  }, [selectedCountryAndLanguage]);

  return (
    <AnimDiv className="section4-wrapper">
      <div>
        <div className="flex justify-center">
          <h2>
            {cmsItems && cmsItems.length > 0
              ? getContentValueForType(
                  CmsContentType.TITLE,
                  cmsItems[0]?.cmsContents
                )
              : null}
          </h2>
        </div>
      </div>
      {cmsItems && cmsItems.length > 0 ? (
        <ImageOrVideo
          assetUrl={
            cmsItems
              ? getContentValueForType(
                  CmsContentType.ASSET_URL,
                  cmsItems[0].cmsContents
                )
              : Placeholder
          }
          className={"image"}
          alt={"placeholder"}
        />
      ) : null}
      <div className="section4-footer flex justify-center">
        {cmsItems && cmsItems.length > 0 ? (
          <Button
            section={CmsSection.HOMEPAGE_SECTION4}
            item={cmsItems[0]}
            className="cta-button purple"
            textClassName="color-white"
          />
        ) : null}
      </div>
    </AnimDiv>
  );
}
