function getConfigVar(key: string): string {
  const val = process.env[key];
  if (val === undefined) {
    throw new Error(`The variable with name ${key} is not set in .env.`);
  }
  return val;
}

export class Config {
  static getBackendUrl(): string {
    return getConfigVar("REACT_APP_BACKEND_URL");
  }
  static getCalendlyRedirectUri(): string {
    return getConfigVar("REACT_APP_CALENDLY_REDIRECT_URI");
  }

  static getCalendlyClientId(): string {
    return getConfigVar("REACT_APP_CALENDLY_CLIENT_ID");
  }

  static getCalendlySchedulingUrlCoach(): string {
    return getConfigVar("REACT_APP_CALENDLY_SCHEDULING_URL_COACH");
  }

  static getCalendlySchedulingUrlMentor(): string {
    return getConfigVar("REACT_APP_CALENDLY_SCHEDULING_URL_MENTOR");
  }

  static getCalendlySchedulingUrlSteve(): string {
    return getConfigVar("REACT_APP_CALENDLY_SCHEDULING_URL_STEVE");
  }

  static getCalendlySchedulingUrlNathalie(): string {
    return getConfigVar("REACT_APP_CALENDLY_SCHEDULING_URL_NATHALIE");
  }

  static getCalendlySchedulingUrlAdvice(): string {
    return getConfigVar("REACT_APP_CALENDLY_SCHEDULING_URL_ADVICE");
  }

  static getSentryDsn(): string {
    return getConfigVar("REACT_APP_SENTRY_DSN");
  }

  static isDebug(): boolean {
    return getConfigVar("REACT_APP_DEBUG") === "true";
  }
}
