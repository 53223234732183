import React, {
  Fragment,
  useContext,
  useEffect,
  useLayoutEffect,
  useState,
} from "react";
import "./Header.css";
import Logo from "../assets/logo.png";
import Hamburger from "../assets/hamburger.svg";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { availableRoutes } from "../constants/AvailableRoutes";
import { HeaderLink } from "../constants/headerLinks";
import { RxCross2 } from "react-icons/rx";
import { useTranslation } from "react-i18next";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import { getRoleFromToken, getVisibleLinkItems } from "../util";
import SettingsApi, { HeaderLinkVisibility } from "../api/SettingsApi";
import { getParsedToken } from "../util";
import { LinkWithSubmenu } from "./LinkWithSubmenu";
import { Role } from "../enums/Role";
import CountrySelector from "./CountrySelector";

export type HeaderLinkItem = {
  name: HeaderLink;
  route: string;
  submenu?: { name: HeaderLink; route: string }[];
};

const hoverEffectHeight = 2;

export function Header() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [onHome, setOnHome] = useState(false);
  const [onJoinForces, setOnJoinForces] = useState(false);
  const [openMenu, setOpenMenu] = useState(false);
  const [headerLinkVisibility, setHeaderLinkVisibility] =
    useState<HeaderLinkVisibility | null>(null);
  const location = useLocation();
  const { t } = useTranslation(["header-footer", "common"]);
  const navigate = useNavigate();

  const [currentLinkLocation, setCurrentLinkLocation] = useState({
    x: 0,
    y: 0,
    width: 0,
  });
  const [hoveredLinkLocation, setHoveredLinkLocation] = useState<{
    x: number;
    y: number;
    width: number;
  } | null>(null);

  useEffect(() => {
    if (location.pathname === availableRoutes.home) {
      setOnHome(true);
    } else {
      setOnHome(false);
    }

    if (location.pathname === availableRoutes.joinForces) {
      setOnJoinForces(true);
    } else {
      setOnJoinForces(false);
    }

    setOpenMenu(false);
    const htmlElement = document.getElementById("html");

    if (htmlElement) {
      htmlElement.classList.remove("no-scroll");
    }
  }, [location]);

  useEffect(() => {
    SettingsApi.getHeaderLinkVisibility().then((result) =>
      setHeaderLinkVisibility(result)
    );
  }, []);

  const updateBar: React.MouseEventHandler<HTMLDivElement> = (event) => {
    const bounding = event.currentTarget.getBoundingClientRect();
    setHoveredLinkLocation({
      x: bounding.x,
      y: bounding.y + bounding.height + hoverEffectHeight,
      width: bounding.width,
    });
  };

  const primaryLinkItems: HeaderLinkItem[] = [
    {
      name: "information",
      route: availableRoutes.information,
    },
    {
      name: "inspiration",
      route: availableRoutes.inspiration,
    },
    {
      name: "academy",
      route: availableRoutes.academy,
      submenu: [
        { name: "training", route: availableRoutes.training },
        { name: "coaching", route: availableRoutes.coaching },
        { name: "mentoring", route: availableRoutes.mentoring },
        { name: "advice", route: availableRoutes.advice },
      ],
    },
    {
      name: "startSmart",
      route: availableRoutes.startSmart,
    },
    {
      name: "matchmaking",
      route: availableRoutes.joinForces,
    },
    {
      name: "unbounded",
      route: availableRoutes.unbounded,
    },
    {
      name: "funding",
      route: availableRoutes.funding,
    },
    {
      name: "community",
      route: availableRoutes.community,
      submenu: [
        { name: "events", route: availableRoutes.events },
        { name: "podcasts", route: availableRoutes.podcasts },
        { name: "startupCafe", route: availableRoutes.startupCafe },
      ],
    },
  ];

  const secondaryLinkItems: HeaderLinkItem[] = [
    { name: "forCorporates", route: availableRoutes.forCorporates },
    { name: "forHigherEdu", route: availableRoutes.forHigherEdu },
    { name: "forInvestors", route: availableRoutes.forInvestors },
    { name: "forMentors", route: availableRoutes.forMentors },
    { name: "faq", route: availableRoutes.faq },
    { name: "contact", route: availableRoutes.contact },
  ];

  useLayoutEffect(() => {
    const current =
      document.getElementsByClassName("text-main-navigation active")[0] ||
      document.getElementsByClassName("text-top-navigation active")[0];

    if (current) {
      const newLocation = {
        x: current.getBoundingClientRect().x,
        y:
          current.getBoundingClientRect().y +
          current.getBoundingClientRect().height +
          hoverEffectHeight,
        width: current.getBoundingClientRect().width,
      };

      if (
        newLocation.x !== currentLinkLocation.x ||
        newLocation.y !== currentLinkLocation.y
      ) {
        setCurrentLinkLocation(newLocation);
      }
    }
  });

  const barLocation = hoveredLinkLocation || currentLinkLocation;

  return (
    <Fragment>
      {barLocation.x ? (
        <div
          className="navbar-line"
          style={{
            left: barLocation.x,
            top: barLocation.y,
            width: `${barLocation.width}px`,
            height: `${hoverEffectHeight}px`,
          }}
        />
      ) : null}
      <div
        className="header"
        onMouseLeave={() => {
          setHoveredLinkLocation(null);
        }}
      >
        <div className="top-navigation flex flex-row">
          <CountrySelector />
          {getVisibleLinkItems(
            headerLinkVisibility,
            secondaryLinkItems,
            selectedCountryAndLanguage
          ).map((item, index) => {
            return (
              <LinkWithSubmenu
                item={item}
                className={`text-top-navigation ${
                  index === 0 ? "ml-auto" : ""
                }`}
                headerLinkVisibility={headerLinkVisibility}
                selectedCountryAndLanguage={selectedCountryAndLanguage}
                onMouseEnter={updateBar}
              />
            );
          })}
        </div>
        <div
          className={`main-navigation flex flex-row ${
            onHome || onJoinForces ? "transparent" : null
          }`}
        >
          <Link className="logo-wrapper" to={availableRoutes.home}>
            <img className="logo" src={Logo} alt="logo" />
          </Link>
          {getVisibleLinkItems(
            headerLinkVisibility,
            primaryLinkItems,
            selectedCountryAndLanguage
          ).map((item, index) => {
            if (
              getParsedToken() &&
              getParsedToken()?.role !== Role.STUDENT &&
              getParsedToken()?.role !== Role.PHD &&
              getParsedToken()?.role !== Role.ALUMNUS &&
              getParsedToken()?.role !== Role.ADMIN &&
              getParsedToken()?.role !== Role.HIGHER_EDU &&
              item.route === availableRoutes.startSmart
            ) {
              return null;
            }

            return (
              <LinkWithSubmenu
                item={item}
                className={`text-main-navigation ${
                  index === 0 ? "ml-auto" : ""
                }`}
                headerLinkVisibility={headerLinkVisibility}
                selectedCountryAndLanguage={selectedCountryAndLanguage}
                onMouseEnter={updateBar}
              />
            );
          })}
          <div className="flex flex-column login-wrapper">
            {!getParsedToken() ? (
              <Fragment>
                <Link
                  to={availableRoutes.login}
                  className="button header blue color-primary"
                >
                  {t("common:login")}
                </Link>
                <Link
                  to={availableRoutes.signup}
                  className="sign-up color-white"
                >
                  {t("common:orSignup")}
                </Link>
              </Fragment>
            ) : (
              <Fragment>
                <div
                  className="button header blue color-primary"
                  onClick={() => {
                    navigate("/");
                    localStorage.removeItem("token");
                    localStorage.removeItem("refreshToken");
                    localStorage.removeItem("type");
                  }}
                >
                  {t("common:logout")}
                </div>
                {getRoleFromToken() === Role.ADMIN ? (
                  <Link
                    to={availableRoutes.cms.root}
                    className="sign-up color-white"
                  >
                    Go to CMS
                  </Link>
                ) : null}
              </Fragment>
            )}
          </div>
        </div>
      </div>
      <div
        className={`mobile-navigation ${
          onHome || onJoinForces ? "header-margin" : ""
        }`}
      >
        <Link to={availableRoutes.home}>
          <img className="logo" src={Logo} alt="logo" />
        </Link>
        <div
          className="ml-auto pointer"
          onClick={() => {
            const htmlElement = document.getElementById("html");

            if (htmlElement) {
              htmlElement.classList.toggle("no-scroll");
            }

            setOpenMenu(!openMenu);
          }}
        >
          {openMenu ? (
            <RxCross2 className="icon color-white" />
          ) : (
            <img src={Hamburger} alt={"menu"} />
          )}
        </div>
      </div>
      {openMenu ? (
        <div className="menu">
          <div className="main">
            <div className="flex flex-column">
              {getVisibleLinkItems(
                headerLinkVisibility,
                primaryLinkItems,
                selectedCountryAndLanguage
              ).map((item) => {
                if (
                  getParsedToken() &&
                  getParsedToken()?.role !== Role.STUDENT &&
                  getParsedToken()?.role !== Role.PHD &&
                  getParsedToken()?.role !== Role.ALUMNUS &&
                  getParsedToken()?.role !== Role.ADMIN &&
                  getParsedToken()?.role !== Role.HIGHER_EDU &&
                  item.route === availableRoutes.startSmart
                ) {
                  return null;
                }

                return (
                  <LinkWithSubmenu
                    item={item}
                    className="menu-item"
                    headerLinkVisibility={headerLinkVisibility}
                    selectedCountryAndLanguage={selectedCountryAndLanguage}
                    mobile={true}
                  />
                );
              })}
            </div>
            <div className="flex flex-column login-wrapper ml-auto">
              {!getParsedToken() ? (
                <Fragment>
                  <Link
                    to={availableRoutes.login}
                    className="button header blue color-primary"
                  >
                    {t("common:login")}
                  </Link>
                  <Link
                    to={availableRoutes.signup}
                    className="sign-up color-white"
                  >
                    {t("common:orSignup")}
                  </Link>
                </Fragment>
              ) : (
                <Fragment>
                  <div
                    className="button header blue color-primary"
                    onClick={() => {
                      navigate("/");
                      localStorage.removeItem("token");
                      localStorage.removeItem("refreshToken");
                      localStorage.removeItem("type");
                    }}
                  >
                    {t("common:logout")}
                  </div>
                  {getRoleFromToken() === Role.ADMIN ? (
                    <Link
                      to={availableRoutes.cms.root}
                      className="sign-up color-white"
                    >
                      Go to CMS
                    </Link>
                  ) : null}
                </Fragment>
              )}
            </div>
          </div>
          <div className="secondary flex flex-column">
            {getVisibleLinkItems(
              headerLinkVisibility,
              secondaryLinkItems,
              selectedCountryAndLanguage
            ).map((item) => {
              return (
                <LinkWithSubmenu
                  item={item}
                  className="menu-item"
                  headerLinkVisibility={headerLinkVisibility}
                  selectedCountryAndLanguage={selectedCountryAndLanguage}
                />
              );
            })}
            <CountrySelector mobile={true} />
          </div>
        </div>
      ) : null}
    </Fragment>
  );
}
