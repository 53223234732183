import ApiInstance from "./ApiInstance";

class ContactApi {
  public async sendMessage(
    name: string,
    userType: string,
    message: string,
    email: string
  ): Promise<void> {
    await ApiInstance.getApi().post(`/contact`, {
      name,
      userType,
      message,
      email,
    });
  }

  public async sendFundingMessage(
    name: string,
    phone: string,
    message: string,
    email: string
  ): Promise<void> {
    await ApiInstance.getApi().post(`/contact/funding`, {
      name,
      phone,
      message,
      email,
    });
  }

  public async sendUnboundedMessage(
    name: string,
    phone: string,
    message: string,
    email: string
  ): Promise<void> {
    await ApiInstance.getApi().post(`/contact/unbounded`, {
      name,
      phone,
      message,
      email,
    });
  }

  public async sendJoinForcesMessage(
    name: string,
    phone: string,
    message: string,
    email: string,
    userType: string,
    lookingFor: string
  ): Promise<void> {
    await ApiInstance.getApi().post(`/contact/join-forces`, {
      name,
      phone,
      message,
      email,
      userType,
      lookingFor,
    });
  }

  public async requestCombellCloud(
    name: string,
    phone: string,
    message: string,
    email: string,
    company: string
  ): Promise<void> {
    await ApiInstance.getApi().post(`/contact/combell-cloud`, {
      name,
      phone,
      message,
      email,
      company,
    });
  }

  public async requestConnus(
    name: string,
    phone: string,
    message: string,
    email: string,
    company: string
  ): Promise<void> {
    await ApiInstance.getApi().post(`/contact/connus`, {
      name,
      phone,
      message,
      email,
      company,
    });
  }

  public async requestBizzy(
    name: string,
    phone: string,
    message: string,
    email: string,
    company: string
  ): Promise<void> {
    await ApiInstance.getApi().post(`/contact/bizzy`, {
      name,
      phone,
      message,
      email,
      company,
    });
  }

  public async requestGeneralDealForm(
    name: string,
    phone: string,
    message: string,
    email: string,
    company: string,
    receiver: string
  ): Promise<void> {
    await ApiInstance.getApi().post(`/contact/general`, {
      name,
      phone,
      message,
      email,
      company,
      receiver,
    });
  }
}

export default new ContactApi();
