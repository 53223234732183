export enum CmsSection {
  HOMEPAGE_HERO_SECTION = "HOMEPAGE_HERO_SECTION",
  HOMEPAGE_INTRODUCTION = "HOMEPAGE_INTRODUCTION",
  HOMEPAGE_SECTION1 = "HOMEPAGE_SECTION1",
  HOMEPAGE_SECTION2_HEAD = "HOMEPAGE_SECTION2_HEAD",
  HOMEPAGE_SECTION2 = "HOMEPAGE_SECTION2",
  HOMEPAGE_SECTION3_HEAD = "HOMEPAGE_SECTION3_HEAD",
  HOMEPAGE_SECTION3 = "HOMEPAGE_SECTION3",
  HOMEPAGE_SECTION4 = "HOMEPAGE_SECTION4",
  HOMEPAGE_SECTION5 = "HOMEPAGE_SECTION5",
  HOMEPAGE_SECTION5_HEAD = "HOMEPAGE_SECTION5_HEAD",
  HOMEPAGE_SECTION6 = "HOMEPAGE_SECTION6",
  HOMEPAGE_SECTION7 = "HOMEPAGE_SECTION7",
  HOMEPAGE_SECTION7_HEAD = "HOMEPAGE_SECTION7_HEAD",
  HOMEPAGE_SECTION8 = "HOMEPAGE_SECTION8",
  HOMEPAGE_SECTION8_HEAD = "HOMEPAGE_SECTION8_HEAD",
  INFORMATION = "INFORMATION",
  TAGS = "TAGS",
  INSPIRATION = "INSPIRATION",
  INSPIRATION_TAGS = "INSPIRATION_TAGS",
  COACHING_TOP = "COACHING_TOP",
  COACHING_BOTTOM = "COACHING_BOTTOM",
  ADVICE_TOP = "ADVICE_TOP",
  ADVICE_BOTTOM = "ADVICE_BOTTOM",
  MENTORING_TOP = "MENTORING_TOP",
  MENTORING_BOTTOM = "MENTORING_BOTTOM",
  FAQ = "FAQ",
  TRAINING = "TRAINING",
  TRAINING_TAGS = "TRAINING_TAGS",
  CORPORATES_HERO = "CORPORATES_HERO",
  CORPORATES_SECTION1 = "CORPORATES_SECTION1",
  CORPORATES_SECTION2_HEAD = "CORPORATES_SECTION2_HEAD",
  CORPORATES_SECTION2 = "CORPORATES_SECTION2",
  CORPORATES_SECTION3 = "CORPORATES_SECTION3",
  INVESTORS_HERO = "INVESTORS_HERO",
  INVESTORS_SECTION1 = "INVESTORS_SECTION1",
  INVESTORS_SECTION2_HEAD = "INVESTORS_SECTION2_HEAD",
  INVESTORS_SECTION2 = "INVESTORS_SECTION2",
  INVESTORS_SECTION3 = "INVESTORS_SECTION3",
  MENTORS_HERO = "MENTORS_HERO",
  MENTORS_SECTION1 = "MENTORS_SECTION1",
  MENTORS_SECTION2_HEAD = "MENTORS_SECTION2_HEAD",
  MENTORS_SECTION2 = "MENTORS_SECTION2",
  MENTORS_SECTION3 = "MENTORS_SECTION3",
  ABOUT_HERO = "ABOUT_HERO",
  ABOUT_SECTION1 = "ABOUT_SECTION1",
  ABOUT_SECTION2 = "ABOUT_SECTION2",
  HIGHER_EDU_HERO = "HIGHER_EDU_HERO",
  HIGHER_EDU_SECTION1 = "HIGHER_EDU_SECTION1",
  HIGHER_EDU_SECTION2_HEAD = "HIGHER_EDU_SECTION2_HEAD",
  HIGHER_EDU_SECTION2 = "HIGHER_EDU_SECTION2",
  HIGHER_EDU_SECTION3 = "HIGHER_EDU_SECTION3",
  CONTACT_SECTION = "CONTACT_SECTION",
  NATHALIE_BOOKING_TOP = "NATHALIE_BOOKING_TOP",
  NATHALIE_BOOKING_BOTTOM = "NATHALIE_BOOKING_BOTTOM",
  STEVE_BOOKING_TOP = "STEVE_BOOKING_TOP",
  STEVE_BOOKING_BOTTOM = "STEVE_BOOKING_BOTTOM",
  ACADEMY_SECTION1 = "ACADEMY_SECTION1",
  ACADEMY_SECTION2 = "ACADEMY_SECTION2",
  ACADEMY_SECTION3 = "ACADEMY_SECTION3",
  ACADEMY_SECTION4 = "ACADEMY_SECTION4",
  ACADEMY_SECTION5 = "ACADEMY_SECTION5",
  FORCES_HERO = "FORCES_HERO",
  FORCES_SECTION1 = "FORCES_SECTION1",
  FORCES_SECTION2 = "FORCES_SECTION2",
  FORCES_SECTION3 = "FORCES_SECTION3",
  FORCES_SECTION4 = "FORCES_SECTION4",
  FORCES_SECTION5 = "FORCES_SECTION5",
  FORCES_SECTION6 = "FORCES_SECTION6",
  FUNDING_HERO = "FUNDING_HERO",
  FUNDING_SECTION1 = "FUNDING_SECTION1",
  FUNDING_SECTION2 = "FUNDING_SECTION2",
  FUNDING_SECTION3 = "FUNDING_SECTION3",
  PODCAST = "PODCAST",
  PODCAST_RECOMMENDATION = "PODCAST_RECOMMENDATION",
  PODCAST_RECOMMENDATION_HEAD = "PODCAST_RECOMMENDATION_HEAD",
  PODCAST_CTA = "PODCAST_CTA",
  COMMUNITY_SECTION1 = "COMMUNITY_SECTION1",
  COMMUNITY_SECTION2 = "COMMUNITY_SECTION2",
  COMMUNITY_SECTION3 = "COMMUNITY_SECTION3",
  COMMUNITY_SECTION4 = "COMMUNITY_SECTION4",
  EVENTS = "EVENTS",
  EVENTS_TAGS = "EVENTS_TAGS",
  STARTUP_CAFE_HERO = "STARTUP_CAFE_HERO",
  STARTUP_CAFE_SECTION1 = "STARTUP_CAFE_SECTION1",
  STARTUP_CAFE_SECTION2 = "STARTUP_CAFE_SECTION2",
  STARTUP_CAFE_SECTION3_HEAD = "STARTUP_CAFE_SECTION3_HEAD",
  STARTUP_CAFE_SECTION3 = "STARTUP_CAFE_SECTION3",
  STARTUP_CAFE_SECTION4 = "STARTUP_CAFE_SECTION4",
  UNBOUNDED_HERO = "UNBOUNDED_HERO",
  UNBOUNDED_SECTION1 = "UNBOUNDED_SECTION1",
  UNBOUNDED_SECTION2 = "UNBOUNDED_SECTION2",
  UNBOUNDED_SECTION3_HEAD = "UNBOUNDED_SECTION3_HEAD",
  UNBOUNDED_SECTION3 = "UNBOUNDED_SECTION3",
  UNBOUNDED_SECTION4 = "UNBOUNDED_SECTION4",
  START_SMART_SECTION1 = "START_SMART_SECTION1",
  START_SMART_HEAD = "START_SMART_HEAD",
  DEAL = "DEAL",
  START_SMART_BOTTOM = "START_SMART_BOTTOM",
  LOGIN_SECTION = "LOGIN_SECTION",
  SIGNUP_SECTION = "SIGNUP_SECTION",
  DEALS_POPUP = "DEALS_POPUP",
}
