import React, { Fragment, PropsWithChildren, useEffect, useState } from "react";
import { availableRoutes } from "../constants/AvailableRoutes";
import { Navigate, useLocation } from "react-router-dom";
import { getRoleFromToken } from "../util";
import { Role } from "../enums/Role";

export function RedirectToHomeIfNotAdmin({ children }: PropsWithChildren) {
  const [isAdmin, setIsAdmin] = useState(getRoleFromToken() === Role.ADMIN);
  const location = useLocation();

  useEffect(() => {
    setIsAdmin(getRoleFromToken() === Role.ADMIN);
  }, [location]);

  if (!isAdmin) {
    return <Navigate to={availableRoutes.home} />;
  }

  return <Fragment>{children}</Fragment>;
}
