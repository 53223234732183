import React, { useContext, useEffect, useState } from "react";
import "./StartSmartDetail.css";
import Bg from "../assets/detail-bg.png";
import { CmsSection } from "../enums/CmsSection";
import { ImageOrVideo } from "../components/ImageOrVideo";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import { useParams } from "react-router-dom";
import DealsApi from "../api/DealsApi";
import { Deal, DealType } from "../enums/Deal";
import AuthApi, { User } from "../api/AuthApi";
import { RedirectToPageIfNoPermission } from "../components/RedirectToPageIfNoPermission";
import { availableRoutes } from "../constants/AvailableRoutes";
import { RequestDealForm } from "../components/RequestDealForm";
import { GeneralPopup } from "../components/cms/GeneralPopup";
import { getCorrectColorIfSet, getTextColor } from "../enums/Color";
import { Button } from "../components/Button";
import AnimDiv from "../components/AnimDiv";
import { Role } from "../enums/Role";

export function StartSmartDetail() {
  const { id } = useParams();

  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItem, setCmsItem] = useState<CmsItem>();
  const [cmsItemCTA, setCmsItemCTA] = useState<CmsItem>();
  const [discountCode, setDiscountCode] = useState("");
  const [user, setUser] = useState<User | null>(null);
  const [form, setForm] = useState("");
  const [confirmed, setConfirmed] = useState(false);

  useEffect(() => {
    AuthApi.getCurrentUser().then((result) => {
      setUser(result);
    });

    if (!id) {
      return;
    }

    CmsApi.getCmsItem(id, selectedCountryAndLanguage.language).then((item) =>
      setCmsItem(item)
    );
    CmsApi.getCmsItems(
      CmsSection.START_SMART_BOTTOM,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemCTA(items[0]));
  }, [id]);

  const claimButtonText = getContentValueForType(
    CmsContentType.CLAIM_BUTTON_TEXT,
    cmsItem?.cmsContents
  );
  const claimButtonUrl = getContentValueForType(
    CmsContentType.CLAIM_BUTTON_URL,
    cmsItem?.cmsContents
  );

  if (!user) {
    return null;
  }

  return (
    <AnimDiv className="podcasts-detail sm-deal">
      <RedirectToPageIfNoPermission
        link={availableRoutes.startSmart}
        permission={user?.permittedForDeals || user?.role === Role.HIGHER_EDU}
      />
      <img className="bg" src={Bg} alt="bg" />
      <AnimDiv className="wrapper">
        <h2>
          {getContentValueForType(CmsContentType.TITLE, cmsItem?.cmsContents)}
        </h2>
        <div className="mb">
          <div className="content-wrapper">
            <div className="left">
              <ImageOrVideo
                assetUrl={getContentValueForType(
                  CmsContentType.LOGO_URL,
                  cmsItem?.cmsContents
                )}
                className={"logo"}
                alt={"bg"}
              />
              <div className="text">
                {getContentValueForType(
                  CmsContentType.SHORT_DESCRIPTION,
                  cmsItem?.cmsContents
                )}
              </div>
              {user && user.role !== Role.HIGHER_EDU ? (
                <div className="flex">
                  <div
                    className="button ml-auto cta purple uppercase"
                    onClick={async () => {
                      const dealName = getContentValueForType(
                        CmsContentType.DEAL,
                        cmsItem?.cmsContents
                      );

                      const result = await DealsApi.getDeal(dealName);

                      if (result.type === DealType.FORM) {
                        setForm(dealName);
                        return;
                      }

                      if (
                        result.type === DealType.URL &&
                        dealName === Deal.URL_DEAL
                      ) {
                        window.open(claimButtonUrl, "_blank");
                        return;
                      }

                      if (result.type === DealType.DISCOUNT_CODE) {
                        setDiscountCode(result.value);
                      } else {
                        window.open(result.value, "_blank");
                      }
                    }}
                  >
                    Claim deal
                  </div>
                </div>
              ) : null}
              <div className="subtitle">
                {getContentValueForType(
                  CmsContentType.SUBTITLE,
                  cmsItem?.cmsContents
                )}
              </div>
              <div>
                {getContentValueForType(
                  CmsContentType.DESCRIPTION,
                  cmsItem?.cmsContents
                )}
              </div>
            </div>
            <div className="right">
              <ImageOrVideo
                assetUrl={getContentValueForType(
                  CmsContentType.ASSET_URL,
                  cmsItem?.cmsContents
                )}
                className={"image"}
                alt={"bg"}
                controls={getContentValueForType(
                  CmsContentType.VIDEO_CONTROLS,
                  cmsItem?.cmsContents
                )}
              />
            </div>
          </div>
          <div className="description2">
            {getContentValueForType(
              CmsContentType.DESCRIPTION2,
              cmsItem?.cmsContents
            )}
          </div>
        </div>
      </AnimDiv>
      <AnimDiv className="bottom">
        <ImageOrVideo
          assetUrl={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItemCTA?.cmsContents
          )}
          className={"image"}
          alt={"bg"}
        />
        <div className="content">
          <div
            className={`content-title ${getTextColor(
              getCorrectColorIfSet(
                CmsSection.START_SMART_BOTTOM,
                CmsContentType.COLOR_TITLE,
                cmsItemCTA?.cmsContents
              ),
              true
            )}`}
          >
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsItemCTA?.cmsContents
            )}
          </div>
          <div
            className={`${getTextColor(
              getCorrectColorIfSet(
                CmsSection.START_SMART_BOTTOM,
                CmsContentType.COLOR_DESCRIPTION,
                cmsItemCTA?.cmsContents
              ),
              false
            )}`}
          >
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemCTA?.cmsContents
            )}
          </div>
          <div className="flex buttons">
            {cmsItemCTA ? (
              <Button
                section={CmsSection.START_SMART_BOTTOM}
                item={cmsItemCTA}
              />
            ) : null}
          </div>
        </div>
      </AnimDiv>
      <GeneralPopup
        open={form !== ""}
        title={"Request access"}
        setOpen={(v) => {
          if (!v) {
            setForm("");
          }
        }}
        className="less-margin"
      >
        {user ? (
          <RequestDealForm
            user={user}
            close={() => {
              setForm("");
              setConfirmed(true);
            }}
            deal={form as Deal}
            cmsItem={cmsItem}
          />
        ) : null}
      </GeneralPopup>
      <GeneralPopup
        open={confirmed}
        title={"Request confirmed"}
        setOpen={setConfirmed}
      >
        Thank you for your interest! We are processing your request right now.
      </GeneralPopup>
      <GeneralPopup
        open={discountCode !== ""}
        title={"Discount code"}
        setOpen={(v) => {
          if (!v) {
            setDiscountCode("");
          }
        }}
      >
        <div>
          You can use the following discount code: <b>{discountCode}</b>
        </div>
        {claimButtonText && claimButtonUrl ? (
          <a
            href={claimButtonUrl}
            className="button uppercase cta purple"
            target={"_blank"}
          >
            {claimButtonText}
          </a>
        ) : null}
      </GeneralPopup>
    </AnimDiv>
  );
}
