import React, { useState } from "react";
import { CmsContentType } from "../../enums/CmsContentType";
import { CmsForm, CmsFormProps } from "./CmsForm";
import {
  getContentIdForType,
  getContentValueForType,
} from "./getContentForType";
import { defaultContent } from "../../constants/DefaultContent";

export function TitleAssetDescriptionContentForm({
  onSave,
  contents,
  itemType,
  availableItemTypes,
  showItemTypeSelect,
  loading,
  setLoading,
  cmsSection,
}: CmsFormProps) {
  const [title, setTitle] = useState<string>(
    getContentValueForType(CmsContentType.TITLE, contents)
  );

  const [description, setDescription] = useState<string>(
    getContentValueForType(CmsContentType.DESCRIPTION, contents)
  );

  const [ctaText, setCtaText] = useState<string>(
    getContentValueForType(CmsContentType.CALL_TO_ACTION_TEXT, contents)
  );

  const [ctaUrl, setCtaUrl] = useState<string>(
    getContentValueForType(CmsContentType.CALL_TO_ACTION_URL, contents)
  );

  const [assetUrl, setAssetUrl] = useState<string>(
    getContentValueForType(CmsContentType.ASSET_URL, contents)
  );

  const [content, setContent] = useState<string>(
    getContentValueForType(CmsContentType.CONTENT, contents)
  );

  const [contentObject, setContentObject] = useState<string>(
    getContentValueForType(CmsContentType.CONTENT_OBJECT, contents) ||
      JSON.stringify(defaultContent)
  );

  const [assetFile, setAssetFile] = useState<File>();

  const inputData = [
    {
      label: "Title",
      value: title,
      valueSetter: setTitle,
      contentType: CmsContentType.TITLE,
      contentId: getContentIdForType(CmsContentType.TITLE, contents),
    },
    {
      label: "Description",
      value: description,
      valueSetter: setDescription,
      contentType: CmsContentType.DESCRIPTION,
      contentId: getContentIdForType(CmsContentType.DESCRIPTION, contents),
    },
    {
      label: "Button text",
      value: ctaText,
      valueSetter: setCtaText,
      contentType: CmsContentType.CALL_TO_ACTION_TEXT,
      contentId: getContentIdForType(
        CmsContentType.CALL_TO_ACTION_TEXT,
        contents
      ),
    },
    {
      label: "Button link",
      value: ctaUrl,
      valueSetter: setCtaUrl,
      contentType: CmsContentType.CALL_TO_ACTION_URL,
      contentId: getContentIdForType(
        CmsContentType.CALL_TO_ACTION_URL,
        contents
      ),
    },
    {
      label: "Image",
      contentType: CmsContentType.ASSET_URL,
      contentId: getContentIdForType(CmsContentType.ASSET_URL, contents),
      file: assetFile,
      setFile: setAssetFile,
      value: assetUrl,
      valueSetter: setAssetUrl,
    },
    {
      label: "Content",
      contentType: CmsContentType.CONTENT_OBJECT,
      contentId: getContentIdForType(CmsContentType.CONTENT_OBJECT, contents),
      value: contentObject,
      valueSetter: setContent,
      designSetter: setContentObject,
      edit: contents !== undefined,
    },
    {
      label: "",
      contentType: CmsContentType.CONTENT,
      contentId: getContentIdForType(CmsContentType.CONTENT, contents),
      value: content,
      valueSetter: setContent,
    },
  ];

  return (
    <CmsForm
      inputData={inputData}
      onSave={onSave}
      showItemTypeSelect={showItemTypeSelect}
      itemType={itemType}
      availableItemTypes={availableItemTypes}
      loading={loading}
      setLoading={setLoading}
      cmsSection={cmsSection}
    />
  );
}
