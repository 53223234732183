import { CmsItem } from "../api/CmsApi";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";

export const jsonLdObjects = {
  organization: {
    "@context": "https://schema.org",
    "@type": "Organization",
    name: "Student Startups Europe",
    url: "https://studentstartups.eu",
    email: "info@studentstartups.eu",
    address: {
      "@type": "PostalAddress",
      addressLocality: "Roeselare, Belgium",
      postcalCode: "8800",
      streetAddress: "Bruanestraat 8",
    },
  },
  event: (id: string | undefined, eventCmsItem: CmsItem | undefined) => ({
    "@context": "https://schema.org",
    "@type": "Event",
    name: getContentValueForType(
      CmsContentType.TITLE,
      eventCmsItem?.cmsContents
    ),
    identifier: id,
    description: getContentValueForType(
      CmsContentType.DESCRIPTION,
      eventCmsItem?.cmsContents
    ),
    image: getContentValueForType(
      CmsContentType.ASSET_URL,
      eventCmsItem?.cmsContents
    ),
    startDate: getContentValueForType(
      CmsContentType.DATE,
      eventCmsItem?.cmsContents
    ),
    location: getContentValueForType(
      CmsContentType.ADDRESS,
      eventCmsItem?.cmsContents
    ),
    keywords: JSON.parse(
      getContentValueForType(CmsContentType.TAGS, eventCmsItem?.cmsContents) ||
        "[]"
    ).join(","),
    status: "https://schema.org/EventScheduled",
    eventAttendanceMode: "https://schema.org/MixedEventAttendanceMode",
  }),
  podcastSeries: {
    "@context": "https://schema.org",
    "@type": "PodcastSeries",
    url: "https://open.spotify.com/show/04RsWBTmVuLOmx0u2s5aNV?si=1af4dc10fa3a456a",
    name: "Startup Heroes",
    description: `Welcome to STARTUP HEROES
A podcast with guests that have the guts & mindset to jump at a young age.
With this podcast we specifically focus on student- and phd startups
We consider all of them Startup heroes.`,
  },
  podcastEpisode: (podcastCmsItem: CmsItem | undefined) => ({
    "@context": "https://schema.org",
    "@type": "PodcastEpisode",
    url:
      getContentValueForType(
        CmsContentType.IFRAME_URL2,
        podcastCmsItem?.cmsContents
      )?.replace("/embed", "") || "",
    name: getContentValueForType(
      CmsContentType.TITLE,
      podcastCmsItem?.cmsContents
    ),
    partOfSeries: jsonLdObjects.podcastSeries,
    image: getContentValueForType(
      CmsContentType.ASSET_URL,
      podcastCmsItem?.cmsContents
    ),
    keywords: getContentValueForType(
      CmsContentType.TOPIC,
      podcastCmsItem?.cmsContents
    ),
  }),
};
