import React, { useContext, useEffect, useRef, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { ActionsRenderer } from "../renderers/ActionsRenderer";
import Dialog from "../Dialog";
import CmsApi, { CmsContent, CmsItem } from "../../api/CmsApi";
import { CmsCountryLanguageContext } from "../../context/CmsCountryLanguageContext";
import { CmsContentType } from "../../enums/CmsContentType";
import { CmsItemType, getLabelForItemType } from "../../enums/CmsItemType";
import { CmsSection } from "../../enums/CmsSection";
import { getContentForType } from "./getContentForType";
import { CmsFormForSection } from "./CmsFormForSection";
import { useTranslation } from "react-i18next";
import { AxiosError } from "axios";

function getContentCellRenderer(type: CmsContentType) {
  return ({ value }: { value: CmsContent[] }) => {
    const content = getContentForType(type, value);

    if (!content) {
      return null;
    }

    return content.value;
  };
}

export type OnSaveCmsItem = (
  contents: CmsContentData[],
  itemType?: CmsItemType
) => void;

export type CmsContentData = {
  value: string;
  contentType: CmsContentType;
  id: string | undefined;
};

export function CmsTable({
  section,
  sectionName,
  columnContentNames,
  columnContentTypes,
  singleItem,
  showItemTypeSelect = false,
  availableItemTypes,
  height,
}: {
  section: CmsSection;
  sectionName: string;
  columnContentNames: string[];
  columnContentTypes: CmsContentType[];
  singleItem: boolean;
  showItemTypeSelect?: boolean;
  availableItemTypes?: CmsItemType[];
  height?: string;
}) {
  const { selectedCountryAndLanguage } = useContext(CmsCountryLanguageContext);
  const [items, setItems] = useState<CmsItem[]>([]);
  const [create, setCreate] = useState(false);
  const [itemToEdit, setItemToEdit] = useState<null | CmsItem>(null);
  const [itemToDelete, setItemToDelete] = useState<null | CmsItem>(null);
  const [disableAdd, setDisableAdd] = useState(false);
  const [loading, setLoading] = useState(false);
  const gridRef = useRef<AgGridReact<CmsItem>>(null);
  const { t } = useTranslation("common");

  useEffect(() => {
    CmsApi.getCmsItems(
      section,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((result) => {
      setItems(result);
      setDisableAdd(singleItem && result.length >= 1);
    });
  }, [section, selectedCountryAndLanguage, singleItem]);

  const contentColumnDefs = columnContentNames.map((name, i) => ({
    field: "cmsContents",
    headerName: name,
    cellRenderer: getContentCellRenderer(columnContentTypes[i]),
    filter: true,
    resizable: true,
  }));

  const itemTypeColumnDef = showItemTypeSelect
    ? [
        {
          field: "type",
          headerName: "Item type",
          cellRenderer: ({ value }: { value: CmsItemType }) => {
            return getLabelForItemType(value);
          },
          filter: true,
          resizable: true,
        },
      ]
    : [];

  const columnDefs = [
    {
      field: "position",
      filter: true,
      resizable: true,
      suppressSizeToFit: true,
      rowDrag: true,
    },
    {
      field: "id",
      filter: true,
      resizable: true,
      suppressSizeToFit: true,
    },
    ...contentColumnDefs,
    ...itemTypeColumnDef,
    {
      field: "id",
      headerName: "Actions",
      width: 100,
      cellRenderer: ActionsRenderer,
      cellRendererParams: {
        edit: (field: string) => {
          setItemToEdit(items.find((s) => s.id === field) || null);
        },
        remove: (field: string) => {
          setItemToDelete(items.find((s) => s.id === field) || null);
        },
        includeRemove: true,
      },
      filter: false,
      sortable: false,
      suppressSizeToFit: true,
    },
  ];

  const defaultColDef = {
    sortable: true,
    autosize: true,
  };

  function showToastOnError(error: unknown) {
    if (error instanceof AxiosError) {
      toast.error(error.response?.data.message || t("error"), {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    } else {
      toast.error(t("error"), {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }
  }

  async function onSave(contents: CmsContentData[], itemType?: CmsItemType) {
    try {
      if (create) {
        const result = await CmsApi.addCmsItem(
          section,
          selectedCountryAndLanguage.country,
          selectedCountryAndLanguage.language,
          itemType || CmsItemType.DEFAULT,
          contents
        );

        setItems(items.concat(result));
        setDisableAdd(singleItem && items.length + 1 >= 1);
      }

      let error = false;
      if (itemToEdit !== null) {
        const newContents: CmsContent[] = [];
        let i = 0;
        while (i < contents.length && !error) {
          const content = contents[i];
          if (content.id === undefined) {
            newContents.push(
              await CmsApi.addCmsContent(
                itemToEdit.id,
                content.contentType,
                selectedCountryAndLanguage.language,
                content.value
              )
            );
          } else {
            try {
              await CmsApi.editCmsContent(content.id, content.value);
              const existingContent = itemToEdit.cmsContents.find(
                (c) => c.id === content.id
              );
              if (existingContent) {
                existingContent.value = content.value;
                newContents.push(existingContent);
              }
            } catch (e) {
              Sentry.captureException(e);
              showToastOnError(e);
              error = true;
            }
          }

          i++;
        }

        if (!error) {
          itemToEdit.cmsContents = newContents;
          if (showItemTypeSelect && itemType !== undefined) {
            itemToEdit.type = itemType;
            await CmsApi.editCmsItemType(itemToEdit.id, itemType);
          }
        }
      }

      if (!error) {
        setCreate(false);
        setItemToEdit(null);
      }
    } catch (e) {
      Sentry.captureException(e);
      showToastOnError(e);
    } finally {
      setLoading(false);
    }
  }

  return (
    <div>
      <div className="flex mb-4 items-center mt-4">
        <div className="title small">{sectionName}</div>
        <div
          className="button purple ml-auto"
          onClick={async () => {
            if (!gridRef.current) {
              return;
            }

            const { api } = gridRef.current;

            if (!api) {
              return;
            }

            try {
              const mappedRows: { position: number; id: string }[] = [];
              gridRef.current.api.getModel().forEachNode((item) => {
                if (item.rowIndex === null) {
                  return;
                }

                mappedRows.push({
                  position: item.rowIndex,
                  id: item.data.id,
                });
              });

              await CmsApi.setPositions(
                mappedRows,
                section,
                selectedCountryAndLanguage.country
              );
              const newItems = await CmsApi.getCmsItems(
                section,
                selectedCountryAndLanguage.country,
                selectedCountryAndLanguage.language
              );
              setItems(newItems);
            } catch (e) {
              Sentry.captureException(e);
              toast.error(t("error"), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
              });
            }
          }}
        >
          Save positions
        </div>
        <div
          className={`button ml-4 ${disableAdd ? "disabled" : ""}`}
          onClick={() => {
            setCreate(!disableAdd);
          }}
        >
          Add {sectionName} item
        </div>
      </div>
      <div
        className="ag-theme-alpine"
        style={{ height: height ? height : singleItem ? "120px" : "400px" }}
      >
        <AgGridReact
          ref={gridRef}
          rowData={items}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="single"
          onGridReady={(grid) => {
            grid.api.sizeColumnsToFit();
          }}
          rowDragManaged={true}
        />
      </div>
      <Dialog
        isOpen={create || itemToEdit !== null}
        onRequestClose={() => {
          setCreate(false);
          setItemToEdit(null);
        }}
        title={`${
          itemToEdit !== null ? "Edit" : "Add new"
        } ${sectionName} item`}
        size={"large"}
        padding={true}
      >
        <CmsFormForSection
          section={section}
          contents={itemToEdit?.cmsContents}
          itemType={itemToEdit?.type}
          availableItemTypes={availableItemTypes}
          showItemTypeSelect={showItemTypeSelect}
          onSave={onSave}
          loading={loading}
          setLoading={setLoading}
        />
      </Dialog>
      <Dialog
        isOpen={itemToDelete !== null}
        onRequestClose={() => {
          setItemToDelete(null);
        }}
        title={"Are you sure you want to delete this?"}
        size={"small"}
        padding={true}
      >
        <div className="flex">
          <div
            className="button purple"
            onClick={async () => {
              if (!itemToDelete) {
                return;
              }

              try {
                const filteredItems = items.filter(
                  (s) => s.id !== itemToDelete.id
                );
                await CmsApi.deleteCmsItem(itemToDelete.id);
                setItems(filteredItems);
                setItemToDelete(null);
                setDisableAdd(singleItem && filteredItems.length >= 1);
              } catch (e) {
                Sentry.captureException(e);
                toast.error(t("error"), {
                  position: toast.POSITION.TOP_CENTER,
                  hideProgressBar: true,
                });
              }
            }}
          >
            Delete
          </div>
          <div
            className="button ml-4"
            onClick={() => {
              setItemToDelete(null);
            }}
          >
            Cancel
          </div>
        </div>
      </Dialog>
    </div>
  );
}
