import React, { useContext, useEffect, useState } from "react";
import Bg from "../assets/contactbg.png";
import { Input } from "../components/Input";
import "./Contact.css";
import { useTranslation } from "react-i18next";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { CmsSection } from "../enums/CmsSection";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";
import { ImageOrVideo } from "../components/ImageOrVideo";
import { Checkbox } from "../components/Checkbox";
import { Link } from "react-router-dom";
import ContactApi from "../api/ContactApi";
import { Select } from "../components/Select";
import { Role } from "../enums/Role";
import AnimDiv from "../components/AnimDiv";
import { toast } from "react-toastify";

export function Contact() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [type, setType] = useState(Role.STUDENT);
  const [message, setMessage] = useState("");
  const [acceptPolicy, setAcceptPolicy] = useState(false);
  const { t } = useTranslation(["contact", "signup"]);
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItem, setCmsItem] = useState<CmsItem>();

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.CONTACT_SECTION,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItem(items[0]));
  }, []);

  return (
    <AnimDiv className="contact">
      <img className="bg-header" src={Bg} alt="bg" />
      <div className="content-wrapper">
        <h2>
          {getContentValueForType(CmsContentType.TITLE, cmsItem?.cmsContents)}
        </h2>
        <div className="content">
          <div className="text">
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItem?.cmsContents
            )}
          </div>
          <ImageOrVideo
            assetUrl={getContentValueForType(
              CmsContentType.ASSET_URL,
              cmsItem?.cmsContents
            )}
            className={"image"}
            alt={"bg"}
          />
        </div>
        <h2>{t("contact:contactUs")}</h2>
        <form
          className="contact-form"
          onSubmit={async (e) => {
            e.preventDefault();
            await ContactApi.sendMessage(
              `${firstName} ${lastName}`,
              type,
              message,
              email
            );
            setType(Role.STUDENT);
            setEmail("");
            setLastName("");
            setFirstName("");
            setMessage("");
            toast.info("Successfully submitted your request!", {
              position: toast.POSITION.BOTTOM_CENTER,
              hideProgressBar: true,
            });
          }}
        >
          <div className="input-wrapper">
            <Input
              label={t("signup:firstName")}
              value={firstName}
              onChange={setFirstName}
              type="text"
              required={true}
            />
            <Input
              label={t("signup:lastName")}
              value={lastName}
              onChange={setLastName}
              type="text"
              required={true}
            />
          </div>
          <div className="input-wrapper">
            <Input
              label={"Email"}
              value={email}
              onChange={setEmail}
              type="email"
              required={true}
            />
            <Select
              label={t("contact:who")}
              value={type}
              onChange={(v) => {
                setType(v as Role);
              }}
              options={[
                { label: t("signup:student"), value: Role.STUDENT },
                { label: t("signup:phdPostdoc"), value: Role.PHD },
                { label: t("signup:alumnus"), value: Role.ALUMNUS },
                { label: t("signup:corporate"), value: Role.COMPANY },
                { label: t("signup:higherEducation"), value: Role.HIGHER_EDU },
                { label: t("signup:investor"), value: Role.INVESTOR },
                { label: t("signup:mentor"), value: Role.MENTOR },
              ]}
            />
          </div>
          <Input
            label={t("contact:what")}
            value={message}
            onChange={setMessage}
            textArea={true}
          />
          <div className="flex mt-12 wrap">
            <Checkbox
              title={
                <div className={"color-black-80-opacity"}>
                  I accept the{" "}
                  <a
                    href="/privacy-policy"
                    target="_blank"
                    rel="noopener noreferrer"
                    onClick={(e) => e.stopPropagation()}
                  >
                    privacy policy
                  </a>
                </div>
              }
              value={acceptPolicy}
              onClick={setAcceptPolicy}
              required={true}
            />
            <button
              type="submit"
              className="button ml-auto uppercase purple cta"
            >
              {t("contact:submit")}
            </button>
          </div>
        </form>
        <div className="extra">
          {t("contact:mailUs")}{" "}
          <a href="mailto:info@studentstartups.eu">info@studentstartups.eu</a>
        </div>
      </div>
      <div className="bg" />
    </AnimDiv>
  );
}
