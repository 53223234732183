import React, { useContext, useEffect, useState } from "react";
import { Helmet } from "react-helmet";
import "./Podcasts.css";
import Video from "../assets/video.svg";
import Audio from "../assets/sound.svg";
import Original from "../assets/ssu-original.png";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";
import { Link } from "react-router-dom";
import { availableRoutes } from "../constants/AvailableRoutes";
import { CmsSection } from "../enums/CmsSection";
import { ImageOrVideo } from "../components/ImageOrVideo";
import AnimDiv from "../components/AnimDiv";
import { jsonLdObjects } from "../constants/JsonLdObjects";

export function Podcasts() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItems, setCmsItems] = useState<CmsItem[]>();

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.PODCAST,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItems(items));
  }, []);

  return (
    <div className="podcasts">
      <div>
        <AnimDiv className="title-wrapper">
          <h2>Podcasts</h2>
        </AnimDiv>
        <div className="bg-wrapper">
          <div className="wrapper">
            {cmsItems?.map((item) => {
              const iframeUrl = getContentValueForType(
                CmsContentType.IFRAME_URL,
                item.cmsContents
              );
              const isOriginal =
                getContentValueForType(
                  CmsContentType.CHECKBOX,
                  item.cmsContents
                ) === "true";

              return (
                <Link
                  to={`${availableRoutes.podcasts}/${item.id}`}
                  className="podcast"
                >
                  <AnimDiv className="content-wrapper">
                    <div className="figure" />
                    <div className="image-wrapper">
                      <ImageOrVideo
                        assetUrl={getContentValueForType(
                          CmsContentType.ASSET_URL,
                          item.cmsContents
                        )}
                        className={"image"}
                        alt={"bg"}
                      />
                      <img
                        className="type"
                        src={iframeUrl.includes("youtube") ? Video : Audio}
                      />
                      {isOriginal ? (
                        <img
                          className="original"
                          src={Original}
                          alt="original"
                        />
                      ) : null}
                    </div>
                    <div className="content">
                      <div className="topic">
                        {getContentValueForType(
                          CmsContentType.TOPIC,
                          item.cmsContents
                        )}
                      </div>
                      <div className="podcast-title">
                        {getContentValueForType(
                          CmsContentType.TITLE,
                          item.cmsContents
                        )}
                      </div>
                      <div className="description">
                        {getContentValueForType(
                          CmsContentType.SHORT_DESCRIPTION,
                          item.cmsContents
                        )}
                      </div>
                      <div className="button cta ml-auto uppercase color-white">
                        Listen now
                      </div>
                    </div>
                  </AnimDiv>
                </Link>
              );
            })}
          </div>
        </div>
      </div>
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(jsonLdObjects.podcastSeries)}
        </script>
      </Helmet>
    </div>
  );
}
