import React from "react";
import { CountryLanguageTable } from "../../components/cms/CountryLanguageTable";
import { HeaderLinkTable } from "../../components/cms/HeaderLinkTable";
import { CmsHeader } from "../../components/cms/CmsHeader";
import { CmsSidebar } from "../../components/cms/CmsSidebar";

export function CmsSettings() {
  return (
    <div>
      <CmsHeader />
      <div className="flex">
        <CmsSidebar />
        <div className="container">
          <div className="flex mb-4 items-center mt-4">
            <div className="title">Settings</div>
          </div>
          <CountryLanguageTable />
          <HeaderLinkTable />
        </div>
      </div>
    </div>
  );
}
