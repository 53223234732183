import React, { useContext, useEffect, useState } from "react";
import { IntroductionSection } from "../components/home/IntroductionSection";
import { HomeSection1 } from "../components/home/HomeSection1";
import { HomeSection2 } from "../components/home/HomeSection2";
import { CmsSection } from "../enums/CmsSection";
import { ImageOrVideo } from "../components/ImageOrVideo";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { getCorrectColorIfSet, getTextColor } from "../enums/Color";
import { Button } from "../components/Button";
import AnimDiv from "../components/AnimDiv";

export function StartupCafe() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItemHero, setCmsItemHero] = useState<CmsItem>();
  const [cmsItemSection4, setCmsItemSection4] = useState<CmsItem>();

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.STARTUP_CAFE_HERO,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemHero(items[0]));
    CmsApi.getCmsItems(
      CmsSection.STARTUP_CAFE_SECTION4,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection4(items[0]));
  }, [selectedCountryAndLanguage]);

  return (
    <div className="corporates about academy">
      <AnimDiv className="corporate-hero">
        <ImageOrVideo
          assetUrl={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItemHero?.cmsContents
          )}
          className={"image"}
          alt={"bg"}
        />
        <div className="content">
          <h2
            className={getTextColor(
              getCorrectColorIfSet(
                CmsSection.STARTUP_CAFE_HERO,
                CmsContentType.COLOR_TITLE,
                cmsItemHero?.cmsContents
              ),
              true
            )}
          >
            <div>
              {getContentValueForType(
                CmsContentType.TITLE,
                cmsItemHero?.cmsContents
              )}
            </div>
          </h2>
          <div
            className={`text ${getTextColor(
              getCorrectColorIfSet(
                CmsSection.STARTUP_CAFE_HERO,
                CmsContentType.COLOR_DESCRIPTION,
                cmsItemHero?.cmsContents
              ),
              false
            )}`}
          >
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemHero?.cmsContents
            )}
          </div>
          {cmsItemHero ? (
            <Button
              section={CmsSection.STARTUP_CAFE_HERO}
              item={cmsItemHero}
              className="ml-auto button-wrapper"
            />
          ) : null}
        </div>
      </AnimDiv>
      <IntroductionSection section={CmsSection.STARTUP_CAFE_SECTION1} />
      <HomeSection1 section={CmsSection.STARTUP_CAFE_SECTION2} />
      <HomeSection2
        section={CmsSection.STARTUP_CAFE_SECTION3}
        sectionHead={CmsSection.STARTUP_CAFE_SECTION3_HEAD}
      />
      <AnimDiv className="bottom gradient-overlay">
        <ImageOrVideo
          assetUrl={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItemSection4?.cmsContents
          )}
          className={"image z-1"}
          alt={"bg"}
        />
        <div className="content">
          <div
            className={`content-title ${getTextColor(
              getCorrectColorIfSet(
                CmsSection.STARTUP_CAFE_SECTION4,
                CmsContentType.COLOR_TITLE,
                cmsItemSection4?.cmsContents
              ),
              true
            )}`}
          >
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsItemSection4?.cmsContents
            )}
          </div>
          <div
            className={`${getTextColor(
              getCorrectColorIfSet(
                CmsSection.STARTUP_CAFE_SECTION4,
                CmsContentType.COLOR_DESCRIPTION,
                cmsItemSection4?.cmsContents
              ),
              false
            )}`}
          >
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemSection4?.cmsContents
            )}
          </div>
          <div className="flex buttons">
            {cmsItemSection4 ? (
              <Button
                section={CmsSection.STARTUP_CAFE_SECTION4}
                item={cmsItemSection4}
              />
            ) : null}
          </div>
        </div>
      </AnimDiv>
    </div>
  );
}
