import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { ActionsRenderer } from "../../components/renderers/ActionsRenderer";
import { useNavigate } from "react-router-dom";
import CoachApi, { CoachEntity } from "../../api/CoachApi";

export function CoachTable() {
  const [coaches, setCoaches] = useState<CoachEntity[]>([]);

  const navigate = useNavigate();

  const columnDefs = [
    {
      field: "id",
      headerName: "",
      width: 80,
      cellRenderer: ActionsRenderer,
      cellRendererParams: {
        edit: (id: string) => {
          navigate(`availableRoutes.adminCoachTable/${id}`);
        },
        includeRemove: false,
      },
      filter: false,
      sortable: false,
    },
    { field: "name", filter: true, resizable: true },
    { field: "email", filter: true, resizable: true },
    { field: "description", filter: true, resizable: true },
    { field: "schedulingUrl", filter: false, resizable: true },
    { field: "avatarUrl", filter: false, resizeble: true },
    { field: "country.code", filter: true, resizable: true },
    { field: "isPublic", filter: true, resizable: true },
    { field: "onHome", filter: true, resizable: true },
    { field: "position", filter: true, resizable: true },
    { field: "homeposition", filter: true, resizable: true },
  ];

  const defaultColDef = {
    sortable: true,
    autosize: true,
  };

  useEffect(() => {
    CoachApi.listCoaches().then((coaches) =>
      setCoaches(coaches.sort((a) => (a.isPublic ? -1 : 1)))
    );
  }, []);

  return (
    <div className="container">
      <div className="flex mb-4 items-center mt-4">
        <div className="title">Coaches</div>
      </div>
      <div className="ag-theme-alpine" style={{ height: "800px" }}>
        <AgGridReact
          rowData={coaches}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="multiple"
        />
      </div>
    </div>
  );
}
