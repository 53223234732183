import { Role } from "../enums/Role";
import { getParsedToken } from "../util";
import ApiInstance from "./ApiInstance";

export type Gender = "M" | "F" | "X";

export interface UserData {
  name: string;
  surname: string;
  email: string;
  sex: Gender;
  birthDate: string;
  nationality: string; // country code (e.g. "BE")
  language: string; // language code (e.g. "nl")
  password: string;
  phoneNumber: string;
  role: Role;
  companyInfo?: {
    name: string;
    vatNumber: string;
    legalForm: string;
    industry: string;
    street: string;
    streetNumber: string;
    city: string;
    zipCode: string;
    country: string;
  };
  educationInfo?: {
    institution: string;
    subject?: string;
    subjectArea?: string;
    function?: string;
  };
  investorInfo?: {
    investorType: string;
  };
  mentorInfo?: {
    expertise: string;
  };
}

export interface User {
  id: string;
  name: string;
  surname: string;
  email: string;
  sex: Gender;
  birthDate: string;
  role: Role;
  nationality?: {
    code: string;
    name: string;
  };
  language?: {
    code: string;
    name: string;
  };
  phoneNumber?: string;
  permittedForDeals: boolean;
  dealsPermissionRevoked: boolean;
  dealsRegistrationDate: string | null;
  dealsLastStatusUpdate: string | null;
  discountCode: string | null;
  companyInfo?: {
    name: string;
    vatNumber: string;
    legalForm: string;
    industry: string;
    street: string;
    streetNumber: string;
    city: string;
    zipCode: string;
  };
  educationInfo?: {
    institution: string;
    subject?: string;
    subjectArea?: string;
    function?: string;
  };
  investorInfo?: {
    investorType: string;
  };
  mentorInfo?: {
    expertise: string;
  };
}

class AuthApi {
  public async login(
    email: string,
    password: string
  ): Promise<{ token: string; refreshToken: string; type: string }> {
    const {
      data: { token, refreshToken, type },
    } = await ApiInstance.getApi().post("/auth/login", { email, password });
    return { token, refreshToken, type };
  }

  public async registerAccount(userData: UserData): Promise<User> {
    const response = await ApiInstance.getApi().post(
      "/auth/register-account",
      userData
    );
    return response.data;
  }

  public async confirmEmail(email: string, token: string): Promise<void> {
    await ApiInstance.getApi().post("/auth/confirm", { email, token });
  }

  public async requestPasswordReset(email: string): Promise<void> {
    await ApiInstance.getApi().post("/auth/request-password-reset", { email });
  }

  public async confirmPasswordReset(
    email: string,
    password: string,
    token: string
  ): Promise<void> {
    await ApiInstance.getApi().post("/auth/confirm-password-reset", {
      email,
      password,
      token,
    });
  }

  public async getCurrentUser(): Promise<User | null> {
    if (!getParsedToken()) {
      return null;
    }

    const { data } = await ApiInstance.getApi().get("/auth/users/me");
    return data;
  }

  public async getAllUsers(): Promise<User[]> {
    const { data } = await ApiInstance.getApi().get("/auth/users");
    return data;
  }
}

export default new AuthApi();
