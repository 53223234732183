import { useState } from "react";
import { CmsContentType } from "../../enums/CmsContentType";
import { CmsForm, CmsFormProps, CmsFormInputData } from "./CmsForm";
import {
  getContentIdForType,
  getContentValueForType,
} from "./getContentForType";
import { Color, getCorrectColorIfSet } from "../../enums/Color";

export function TitleAssetDescriptionWithColorsForm({
  onSave,
  contents,
  itemType,
  availableItemTypes,
  showItemTypeSelect,
  loading,
  setLoading,
  cmsSection,
}: CmsFormProps) {
  const [title, setTitle] = useState<string>(
    getContentValueForType(CmsContentType.TITLE, contents)
  );

  const [colorTitle, setColorTitle] = useState<string>(
    getCorrectColorIfSet(cmsSection, CmsContentType.COLOR_TITLE, contents)
  );

  const [description, setDescription] = useState<string>(
    getContentValueForType(CmsContentType.DESCRIPTION, contents)
  );

  const [colorDescription, setColorDescription] = useState<string>(
    getCorrectColorIfSet(cmsSection, CmsContentType.COLOR_DESCRIPTION, contents)
  );

  const [assetUrl, setAssetUrl] = useState<string>(
    getContentValueForType(CmsContentType.ASSET_URL, contents)
  );

  const [assetFile, setAssetFile] = useState<File>();

  const inputData: CmsFormInputData[] = [
    {
      label: "Title",
      value: title,
      valueSetter: setTitle,
      contentType: CmsContentType.TITLE,
      contentId: getContentIdForType(CmsContentType.TITLE, contents),
    },
    {
      label: "Description",
      value: description,
      valueSetter: setDescription,
      contentType: CmsContentType.DESCRIPTION,
      contentId: getContentIdForType(CmsContentType.DESCRIPTION, contents),
    },
    {
      label: "Image",
      contentType: CmsContentType.ASSET_URL,
      contentId: getContentIdForType(CmsContentType.ASSET_URL, contents),
      value: assetUrl,
      valueSetter: setAssetUrl,
      file: assetFile,
      setFile: setAssetFile,
    },
    {
      label: "Title color",
      value: colorTitle,
      valueSetter: setColorTitle,
      contentType: CmsContentType.COLOR_TITLE,
      contentId: getContentIdForType(CmsContentType.COLOR_TITLE, contents),
      right: true,
      options: Object.values(Color).map((item) => {
        return { label: item, value: item };
      }),
    },
    {
      label: "Description color",
      value: colorDescription,
      valueSetter: setColorDescription,
      contentType: CmsContentType.COLOR_DESCRIPTION,
      contentId: getContentIdForType(
        CmsContentType.COLOR_DESCRIPTION,
        contents
      ),
      right: true,
      options: Object.values(Color).map((item) => {
        return { label: item, value: item };
      }),
    },
  ];

  return (
    <CmsForm
      inputData={inputData}
      onSave={onSave}
      showItemTypeSelect={showItemTypeSelect}
      itemType={itemType}
      availableItemTypes={availableItemTypes}
      loading={loading}
      setLoading={setLoading}
      cmsSection={cmsSection}
    />
  );
}
