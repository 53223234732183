import axios from "axios";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import * as Sentry from "@sentry/react";
import qs from "qs";
import "react-toastify/dist/ReactToastify.css";
import SettingsApi from "../../api/SettingsApi";
import {
  SETTING_CALENDLY_ACCESS_TOKEN,
  SETTING_CALENDLY_REFRESH_TOKEN,
} from "../../constants/SettingsKeys";
import { Config } from "../../Config";
import "./CalendlyAuth.css";

const CALENDLY_AUTH_CODE_REQUEST_URL = "https://auth.calendly.com/oauth/token";

/**
 * Request access + refresh token from calendly and update corresponding
 * settings in database.
 */
async function updateAuthTokenSettings() {
  const urlParams = new URLSearchParams(window.location.search);
  const calendlyAuthCode = urlParams.get("code");

  if (calendlyAuthCode) {
    try {
      const headers = { "Content-Type": "application/x-www-form-urlencoded" };
      const body = qs.stringify({
        code: calendlyAuthCode,
        grant_type: "authorization_code",
        redirect_uri: Config.getCalendlyRedirectUri(),
        client_id: Config.getCalendlyClientId(),
      });

      const {
        data: { access_token: accessToken, refresh_token: refreshToken },
      } = await axios.post(CALENDLY_AUTH_CODE_REQUEST_URL, body, {
        headers,
      });

      await SettingsApi.updateSettings(
        SETTING_CALENDLY_ACCESS_TOKEN,
        accessToken
      );
      await SettingsApi.updateSettings(
        SETTING_CALENDLY_REFRESH_TOKEN,
        refreshToken
      );
    } catch (e) {
      Sentry.captureException(e);
    }
  } else {
    throw new Error(
      "/oauth/calendly visited without 'code' in URL query parameters"
    );
  }
}

export function CalendlyAuth() {
  const navigate = useNavigate();
  const [error, setError] = useState(false);

  const { t } = useTranslation("calendly-oauth");

  useEffect(() => {
    updateAuthTokenSettings()
      .then(() => {
        navigate("/");
      })
      .catch((e) => {
        setError(true);
        Sentry.captureException(e);
      });
  }, []);

  return <div className="calendly-auth flex">{error && t("invalidUrl")}</div>;
}
