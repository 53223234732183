import { CmsSection } from "./CmsSection";
import { CmsContent } from "../api/CmsApi";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "./CmsContentType";

export enum Color {
  PRIMARY = "primary",
  PURPLE = "purple",
  BLACK = "black",
  WHITE = "white",
  RED = "red",
}

export function getTextColor(color: Color, title: boolean) {
  switch (color) {
    case Color.BLACK:
      return "color-black";
    case Color.PRIMARY:
      return "color-primary";
    case Color.PURPLE:
      return "color-purple";
    case Color.WHITE:
      return "color-white";
    case Color.RED:
      return "color-red";
    default:
      return title ? "color-purple" : "color-white";
  }
}

export function getButtonColor(color: Color) {
  switch (color) {
    case Color.BLACK:
      return "black";
    case Color.PRIMARY:
      return "blue";
    case Color.PURPLE:
      return "purple";
    case Color.WHITE:
      return "white";
    case Color.RED:
      return "red";
    default:
      return "purple";
  }
}

const defaultColors: Record<string, Record<string, Color>> = {
  [CmsSection.HOMEPAGE_HERO_SECTION]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.HOMEPAGE_SECTION1]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.BLACK,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.BLACK,
  },
  [CmsSection.HOMEPAGE_SECTION2]: {
    [CmsContentType.COLOR_TITLE]: Color.WHITE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.HOMEPAGE_SECTION3]: {
    [CmsContentType.COLOR_TITLE]: Color.WHITE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.HOMEPAGE_SECTION5]: {
    [CmsContentType.COLOR_TITLE]: Color.PRIMARY,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.HOMEPAGE_SECTION6]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.HOMEPAGE_SECTION7]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.BLACK,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.BLACK,
  },
  [CmsSection.CORPORATES_HERO]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.HIGHER_EDU_HERO]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.INVESTORS_HERO]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.MENTORS_HERO]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.ABOUT_HERO]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.ACADEMY_SECTION1]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.ACADEMY_SECTION2]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.BLACK,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.BLACK,
  },
  [CmsSection.ACADEMY_SECTION3]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.ACADEMY_SECTION4]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.BLACK,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.BLACK,
  },
  [CmsSection.ACADEMY_SECTION5]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.CORPORATES_SECTION3]: {
    [CmsContentType.COLOR_TITLE]: Color.PRIMARY,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.HIGHER_EDU_SECTION3]: {
    [CmsContentType.COLOR_TITLE]: Color.PRIMARY,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.INVESTORS_SECTION3]: {
    [CmsContentType.COLOR_TITLE]: Color.PRIMARY,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.MENTORS_SECTION3]: {
    [CmsContentType.COLOR_TITLE]: Color.PRIMARY,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.FUNDING_HERO]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.FUNDING_SECTION1]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.BLACK,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.BLACK,
  },
  [CmsSection.FUNDING_SECTION2]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.BLACK,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.BLACK,
  },
  [CmsSection.FUNDING_SECTION3]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.PODCAST_RECOMMENDATION]: {
    [CmsContentType.COLOR_TITLE]: Color.WHITE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.PODCAST_CTA]: {
    [CmsContentType.COLOR_TITLE]: Color.PRIMARY,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.COMMUNITY_SECTION1]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.COMMUNITY_SECTION2]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.BLACK,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.BLACK,
  },
  [CmsSection.COMMUNITY_SECTION3]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.COMMUNITY_SECTION4]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.BLACK,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.BLACK,
  },
  [CmsSection.STARTUP_CAFE_HERO]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.STARTUP_CAFE_SECTION1]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.STARTUP_CAFE_SECTION2]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.BLACK,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.BLACK,
  },
  [CmsSection.STARTUP_CAFE_SECTION3]: {
    [CmsContentType.COLOR_TITLE]: Color.WHITE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.STARTUP_CAFE_SECTION4]: {
    [CmsContentType.COLOR_TITLE]: Color.PRIMARY,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.UNBOUNDED_HERO]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.UNBOUNDED_SECTION2]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.BLACK,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.BLACK,
  },
  [CmsSection.UNBOUNDED_SECTION3]: {
    [CmsContentType.COLOR_TITLE]: Color.WHITE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.UNBOUNDED_SECTION4]: {
    [CmsContentType.COLOR_TITLE]: Color.PRIMARY,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.START_SMART_BOTTOM]: {
    [CmsContentType.COLOR_TITLE]: Color.PRIMARY,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.FORCES_HERO]: {
    [CmsContentType.COLOR_TITLE]: Color.WHITE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.LOGIN_SECTION]: {
    [CmsContentType.COLOR_TITLE]: Color.WHITE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.HOMEPAGE_SECTION4]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.HOMEPAGE_INTRODUCTION]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.FORCES_SECTION1]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.STARTUP_CAFE_SECTION1]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.UNBOUNDED_SECTION1]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.HOMEPAGE_SECTION2_HEAD]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.HOMEPAGE_SECTION3_HEAD]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.HOMEPAGE_SECTION8_HEAD]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.BLACK,
  },
  [CmsSection.CORPORATES_SECTION2_HEAD]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.HIGHER_EDU_SECTION2_HEAD]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.INVESTORS_SECTION2_HEAD]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.MENTORS_SECTION2_HEAD]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PURPLE,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.PODCAST_RECOMMENDATION_HEAD]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.STARTUP_CAFE_SECTION3_HEAD]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.UNBOUNDED_SECTION3_HEAD]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.CORPORATES_SECTION1]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.HIGHER_EDU_SECTION1]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.MENTORS_SECTION1]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.INVESTORS_SECTION1]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.COACHING_BOTTOM]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.ADVICE_BOTTOM]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.MENTORING_BOTTOM]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.FORCES_SECTION6]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.NATHALIE_BOOKING_BOTTOM]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
  [CmsSection.STEVE_BOOKING_BOTTOM]: {
    [CmsContentType.COLOR_TITLE]: Color.PURPLE,
    [CmsContentType.COLOR_DESCRIPTION]: Color.WHITE,
    [CmsContentType.COLOR_CALL_TO_ACTION]: Color.PRIMARY,
    [CmsContentType.COLOR_CALL_TO_ACTION_TEXT]: Color.WHITE,
  },
};

export function getCorrectColorIfSet(
  cmsSection: CmsSection,
  cmsContentType: CmsContentType,
  cmsContents: CmsContent[] | undefined
) {
  return (
    (getContentValueForType(cmsContentType, cmsContents) as Color) ||
    defaultColors[cmsSection][cmsContentType]
  );
}
