import { AgGridReact } from "ag-grid-react";
import React, { useState } from "react";
import { User } from "../../api/AuthApi";
import { ValueFormatterParams } from "ag-grid-community";
import { DateTime } from "luxon";
import DealsApi from "../../api/DealsApi";

function GrantRevokeRenderer({ data }: { data: User }) {
  const [permittedForDeals, setPermittedForDeals] = useState(
    data.permittedForDeals
  );

  return (
    <button
      className={`button ${
        permittedForDeals ? "red color-red" : "blue color-primary"
      }`}
      onClick={async () => {
        await DealsApi.updateDealsPermission(!permittedForDeals, data.id);
        setPermittedForDeals(!permittedForDeals);
      }}
    >
      {permittedForDeals ? "Revoke" : "Grant"}
    </button>
  );
}

export function DealsModerationTable({ users }: { users: User[] }) {
  const columnDefs = [
    { field: "name", filter: true, resizable: true },
    { field: "email", filter: true, resizable: true },
    {
      field: "dealsRegistrationDate",
      headerName: "Join date",
      filter: true,
      resizable: true,
      valueFormatter: (params: ValueFormatterParams<User>) => {
        return DateTime.fromISO(params.value).toFormat("dd/LL/y");
      },
    },
    { field: "phoneNumber", filter: true, resizable: true },
    {
      field: "companyInfo.name",
      headerName: "Company name",
      filter: true,
      resizable: true,
    },
    {
      field: "companyInfo.vatNumber",
      headerName: "VAT",
      filter: true,
      resizable: true,
    },
    {
      field: "nationality.name",
      headerName: "Country",
      filter: true,
      resizable: true,
    },
    {
      field: "discountCode",
      headerName: "Discount code",
      width: 145,
      filter: false,
      sortable: false,
    },
    {
      field: "data",
      headerName: "Permission",
      width: 145,
      cellRenderer: GrantRevokeRenderer,
      filter: false,
      sortable: false,
    },
  ];

  const defaultColDef = {
    sortable: true,
    autosize: true,
  };

  return (
    <div className="ag-theme-alpine" style={{ height: "800px" }}>
      <AgGridReact
        rowData={users}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        animateRows={true}
        rowSelection="multiple"
      />
    </div>
  );
}
