import { createContext } from "react";

export const CmsCountryLanguageContext = createContext<{
  setCountryAndLanguage: (data: { country: string; language: string }) => void;
  selectedCountryAndLanguage: { country: string; language: string };
}>({
  // eslint-disable-next-line @typescript-eslint/no-empty-function
  setCountryAndLanguage: () => {},
  selectedCountryAndLanguage: { country: "BE", language: "en" },
});
