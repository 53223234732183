export const defaultContent = {
  counters: {
    u_column: 1,
    u_row: 1,
    u_content_heading: 1,
  },
  body: {
    id: "DPZxwn-1jZ",
    rows: [
      {
        id: "08eO-aX3oh",
        cells: [1],
        columns: [
          {
            id: "wp6UOgni_d",
            contents: [],
            values: {
              backgroundColor: "",
              padding: "0px",
              border: {},
              _meta: {
                htmlID: "u_column_1",
                htmlClassNames: "u_column",
              },
            },
          },
        ],
        values: {
          displayCondition: null,
          columns: false,
          backgroundColor: "",
          columnsBackgroundColor: "",
          backgroundImage: {
            url: "",
            fullWidth: true,
            repeat: "no-repeat",
            size: "custom",
            position: "center",
          },
          padding: "0px",
          anchor: "",
          hideDesktop: false,
          _meta: {
            htmlID: "u_row_1",
            htmlClassNames: "u_row",
          },
          selectable: true,
          draggable: true,
          duplicatable: true,
          deletable: true,
          hideable: true,
        },
      },
    ],
    values: {
      popupPosition: "center",
      popupWidth: "600px",
      popupHeight: "auto",
      borderRadius: "10px",
      contentAlign: "center",
      contentVerticalAlign: "center",
      contentWidth: 1200,
      fontFamily: {
        label: "Default",
        value: "RedHatText, sans-serif",
      },
      textColor: "#1b1b1b",
      popupBackgroundColor: "#FFFFFF",
      popupBackgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "cover",
        position: "center",
      },
      popupOverlay_backgroundColor: "rgba(0, 0, 0, 0.1)",
      popupCloseButton_position: "top-right",
      popupCloseButton_backgroundColor: "#DDDDDD",
      popupCloseButton_iconColor: "#000000",
      popupCloseButton_borderRadius: "0px",
      popupCloseButton_margin: "0px",
      popupCloseButton_action: {
        name: "close_popup",
        attrs: {
          onClick:
            "document.querySelector('.u-popup-container').style.display = 'none';",
        },
      },
      backgroundColor: "transparent",
      backgroundImage: {
        url: "",
        fullWidth: true,
        repeat: "no-repeat",
        size: "custom",
        position: "center",
      },
      preheaderText: "",
      linkStyle: {
        body: true,
        linkColor: "#0000ee",
        linkHoverColor: "#0000ee",
        linkUnderline: true,
        linkHoverUnderline: true,
      },
      _meta: {
        htmlID: "u_body",
        htmlClassNames: "u_body",
      },
    },
  },
  schemaVersion: 12,
};
