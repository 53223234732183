import React, { useContext, useEffect, useState } from "react";
import "./HomeSection1.css";
import Placeholder from "../../assets/placeholder.png";
import CmsApi, { CmsItem } from "../../api/CmsApi";
import { CmsSection } from "../../enums/CmsSection";
import { getContentValueForType } from "../cms/getContentForType";
import { CmsContentType } from "../../enums/CmsContentType";
import { UserCountryLanguageContext } from "../../context/UserCountryLanguageContext";
import { ImageOrVideo } from "../ImageOrVideo";
import { getCorrectColorIfSet, getTextColor } from "../../enums/Color";
import { Button } from "../Button";
import AnimDiv from "../AnimDiv";

export function HomeSection1({ section }: { section: CmsSection }) {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItems, setCmsItems] = useState<CmsItem[]>();

  useEffect(() => {
    CmsApi.getCmsItems(
      section,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItems(items));
  }, [selectedCountryAndLanguage]);

  return (
    <AnimDiv className="section1-wrapper">
      <div className="section1">
        <div className="left-content">
          {cmsItems && cmsItems.length > 0 ? (
            <ImageOrVideo
              assetUrl={
                cmsItems
                  ? getContentValueForType(
                      CmsContentType.ASSET_URL,
                      cmsItems[0]?.cmsContents
                    )
                  : Placeholder
              }
              className={"image"}
              alt={"placeholder"}
            />
          ) : null}
        </div>
        <div className="right-content flex flex-column">
          <h2
            className={
              cmsItems && cmsItems.length > 0
                ? getTextColor(
                    getCorrectColorIfSet(
                      section,
                      CmsContentType.COLOR_TITLE,
                      cmsItems[0].cmsContents
                    ),
                    true
                  )
                : ""
            }
          >
            {cmsItems && cmsItems.length > 0
              ? getContentValueForType(
                  CmsContentType.TITLE,
                  cmsItems[0]?.cmsContents
                )
              : null}
          </h2>
          <div
            className={`text ${
              cmsItems && cmsItems.length > 0
                ? getTextColor(
                    getCorrectColorIfSet(
                      section,
                      CmsContentType.COLOR_DESCRIPTION,
                      cmsItems[0].cmsContents
                    ),
                    false
                  )
                : ""
            }`}
          >
            {cmsItems && cmsItems.length > 0
              ? getContentValueForType(
                  CmsContentType.DESCRIPTION,
                  cmsItems[0]?.cmsContents
                )
              : null}
          </div>
          {cmsItems && cmsItems.length > 0 ? (
            <Button section={CmsSection.HOMEPAGE_SECTION1} item={cmsItems[0]} />
          ) : null}
        </div>
      </div>
    </AnimDiv>
  );
}
