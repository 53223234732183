import React, { useContext, useEffect, useState } from "react";
import { ImageOrVideo } from "../components/ImageOrVideo";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { CmsSection } from "../enums/CmsSection";
import "./Funding.css";
import { Input } from "../components/Input";
import ContactApi from "../api/ContactApi";
import { getCorrectColorIfSet, getTextColor } from "../enums/Color";
import { Button } from "../components/Button";
import AnimDiv from "../components/AnimDiv";
import { getTarget } from "../util";
import { ImageRhombus } from "../components/ImageRhombus";
import { toast } from "react-toastify";

export function Funding() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItemHero, setCmsItemHero] = useState<CmsItem>();
  const [cmsItemSection1, setCmsItemSection1] = useState<CmsItem>();
  const [cmsItemSection2, setCmsItemSection2] = useState<CmsItem>();
  const [cmsItemSection3, setCmsItemSection3] = useState<CmsItem>();
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [email, setEmail] = useState("");
  const [phone, setPhone] = useState("");
  const [message, setMessage] = useState("");

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.FUNDING_HERO,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemHero(items[0]));

    CmsApi.getCmsItems(
      CmsSection.FUNDING_SECTION1,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection1(items[0]));

    CmsApi.getCmsItems(
      CmsSection.FUNDING_SECTION2,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection2(items[0]));

    CmsApi.getCmsItems(
      CmsSection.FUNDING_SECTION3,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection3(items[0]));
  }, []);

  const heroButtonText = getContentValueForType(
    CmsContentType.CALL_TO_ACTION_TEXT,
    cmsItemHero?.cmsContents
  );
  const heroButtonUrl = getContentValueForType(
    CmsContentType.CALL_TO_ACTION_URL,
    cmsItemHero?.cmsContents
  );

  return (
    <div className="corporates about academy forces funding">
      <AnimDiv className="funding-hero">
        <ImageOrVideo
          assetUrl={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItemHero?.cmsContents
          )}
          className={"image"}
          alt={"bg"}
        />
        <div className="content">
          <h2
            className={getTextColor(
              getCorrectColorIfSet(
                CmsSection.FUNDING_HERO,
                CmsContentType.COLOR_TITLE,
                cmsItemHero?.cmsContents
              ),
              true
            )}
          >
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsItemHero?.cmsContents
            )}
          </h2>
          <div
            className={`description ${getTextColor(
              getCorrectColorIfSet(
                CmsSection.FUNDING_HERO,
                CmsContentType.COLOR_DESCRIPTION,
                cmsItemHero?.cmsContents
              ),
              false
            )}`}
          >
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemHero?.cmsContents
            )}
          </div>
          {!heroButtonUrl || !heroButtonText ? null : (
            <div className="ml-auto button-wrapper">
              <a
                href={heroButtonUrl}
                target={getTarget(heroButtonUrl)}
                className="button cta uppercase purple"
              >
                {heroButtonText}
              </a>
            </div>
          )}
        </div>
      </AnimDiv>
      <AnimDiv className="section funding">
        <ImageRhombus
          imgSrc={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItemSection1?.cmsContents
          )}
        />
        <div className="content">
          <div
            className={`forces-title ${getTextColor(
              getCorrectColorIfSet(
                CmsSection.FUNDING_SECTION1,
                CmsContentType.COLOR_TITLE,
                cmsItemSection1?.cmsContents
              ),
              true
            )}`}
          >
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsItemSection1?.cmsContents
            )}
          </div>
          <div
            className={`text ${getTextColor(
              getCorrectColorIfSet(
                CmsSection.FUNDING_SECTION1,
                CmsContentType.COLOR_DESCRIPTION,
                cmsItemSection1?.cmsContents
              ),
              false
            )}`}
          >
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemSection1?.cmsContents
            )}
          </div>
          {cmsItemSection1 ? (
            <Button
              section={CmsSection.FUNDING_SECTION1}
              item={cmsItemSection1}
              className="ml-auto button-wrapper mt-4"
            />
          ) : null}
        </div>
      </AnimDiv>
      <div className="line" />
      <div className="section gray">
        <div className="content">
          <div
            className={`forces-title ${getTextColor(
              getCorrectColorIfSet(
                CmsSection.FUNDING_SECTION2,
                CmsContentType.COLOR_TITLE,
                cmsItemSection2?.cmsContents
              ),
              true
            )}`}
          >
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsItemSection2?.cmsContents
            )}
          </div>
          <div
            className={`text ${getTextColor(
              getCorrectColorIfSet(
                CmsSection.FUNDING_SECTION2,
                CmsContentType.COLOR_DESCRIPTION,
                cmsItemSection2?.cmsContents
              ),
              false
            )}`}
          >
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemSection2?.cmsContents
            )}
          </div>
          {cmsItemSection2 ? (
            <Button
              section={CmsSection.FUNDING_SECTION2}
              item={cmsItemSection2}
              className="ml-auto button-wrapper mt-4"
            />
          ) : null}
        </div>
        <div className="circle">
          <img
            className="image"
            src={getContentValueForType(
              CmsContentType.ASSET_URL,
              cmsItemSection2?.cmsContents
            )}
          />
        </div>
      </div>
      <AnimDiv className="bottom">
        <ImageOrVideo
          assetUrl={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItemSection3?.cmsContents
          )}
          className={"image"}
          alt={"bg"}
        />
        <div className="content form-type">
          <div className="flex content-container">
            <form
              className="flex flex-column left"
              onSubmit={async (e) => {
                e.preventDefault();

                await ContactApi.sendFundingMessage(
                  `${firstName} ${lastName}`,
                  email,
                  phone,
                  message
                );
                setFirstName("");
                setLastName("");
                setEmail("");
                setPhone("");
                setMessage("");
                toast.info("Successfully submitted your request!", {
                  position: toast.POSITION.BOTTOM_CENTER,
                  hideProgressBar: true,
                });
              }}
            >
              <div className="form-title">Apply now</div>
              <div className="input-wrapper">
                <Input
                  color={"color-white opacity-80"}
                  transparent={true}
                  label={"Firstname"}
                  value={firstName}
                  onChange={setFirstName}
                  required={true}
                />
                <Input
                  color={"color-white opacity-80"}
                  transparent={true}
                  label={"Lastname"}
                  value={lastName}
                  onChange={setLastName}
                  required={true}
                />
              </div>
              <div className="input-wrapper">
                <Input
                  color={"color-white opacity-80"}
                  transparent={true}
                  label={"Email"}
                  value={email}
                  onChange={setEmail}
                  required={true}
                />
                <Input
                  color={"color-white opacity-80"}
                  transparent={true}
                  label={"Phone"}
                  value={phone}
                  onChange={setPhone}
                  pattern={
                    "^[\\+]?[(]?[0-9]{3}[)]?[-\\s\\.]?[0-9]{3}[-\\s\\.]?[0-9]{4,6}$"
                  }
                  title={"Please check your phone number."}
                  required={true}
                />
              </div>
              <Input
                color={"color-white opacity-80"}
                transparent={true}
                label={"What's your idea?"}
                value={message}
                onChange={setMessage}
                textArea={true}
                required={true}
                rows={5}
              />
              <button
                type="submit"
                className="button ml-auto purple color-white uppercase cta mt-4"
              >
                Send application
              </button>
            </form>
            <div className="flex flex-column right">
              <div
                className={`content-title opacity-80 ${getTextColor(
                  getCorrectColorIfSet(
                    CmsSection.FUNDING_SECTION3,
                    CmsContentType.COLOR_TITLE,
                    cmsItemSection3?.cmsContents
                  ),
                  true
                )}`}
              >
                {getContentValueForType(
                  CmsContentType.TITLE,
                  cmsItemSection3?.cmsContents
                )}
              </div>
              <div
                className={`text ${getTextColor(
                  getCorrectColorIfSet(
                    CmsSection.FUNDING_SECTION3,
                    CmsContentType.COLOR_DESCRIPTION,
                    cmsItemSection3?.cmsContents
                  ),
                  false
                )}`}
              >
                {getContentValueForType(
                  CmsContentType.DESCRIPTION,
                  cmsItemSection3?.cmsContents
                )}
              </div>
              <div className="flex buttons">
                {cmsItemSection3 ? (
                  <Button
                    section={CmsSection.FUNDING_SECTION3}
                    item={cmsItemSection3}
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </AnimDiv>
    </div>
  );
}
