export const HigherEductionSubjects = [
  "Archaeology and Art Sciences",
  "Architecture",
  "Audiovisual and Visual Arts",
  "Movement and Rehabilitation Sciences",
  "Biomedical Sciences",
  "Biotechnology",
  "Conservation and Restoration",
  "Veterinary Medicine",
  "Economic and Applied Economic Sciences",
  "Pharmaceutical Sciences",
  "Medicine",
  "History",
  "Healthcare",
  "Theology, Religious Studies, and Canon Law",
  "Commercial Sciences and Business Administration",
  "Industrial Sciences and Technology",
  "Music and Performing Arts",
  "Nautical Sciences",
  "Education",
  "Political and Social Sciences",
  "Product Development",
  "Psychology and Pedagogical Sciences",
  "Law, Notary, and Criminological Sciences",
  "Social Work",
  "Social Health Sciences",
  "Language and Literature",
  "Dentistry",
  "Applied Biological Sciences",
  "Applied Linguistics",
  "Applied Sciences",
  "Traffic Science",
  "Sciences",
  "Philosophy and Moral Sciences",
];
