import React, { Fragment, useContext, useEffect, useState } from "react";
import "./HeroSection.css";
import CmsApi, { CmsItem } from "../../api/CmsApi";
import { CmsSection } from "../../enums/CmsSection";
import { getContentValueForType } from "../cms/getContentForType";
import { CmsContentType } from "../../enums/CmsContentType";
import { UserCountryLanguageContext } from "../../context/UserCountryLanguageContext";
import { isImage } from "../../util";
import { getCorrectColorIfSet, getTextColor } from "../../enums/Color";
import { motion } from "framer-motion";
import { Button } from "../Button";
import AnimDiv from "../AnimDiv";

export function HeroSection() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [currentItemIndex, setCurrentItemIndex] = useState(0);
  const [cmsItems, setCmsItems] = useState<CmsItem[]>();
  const [preloadedImages, setPreloadedImages] = useState([]);

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.HOMEPAGE_HERO_SECTION,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => {
      setCmsItems(items);

      const list = [];

      for (const cmsItem of items) {
        const newImage = new Image();
        newImage.src = getContentValueForType(
          CmsContentType.ASSET_URL,
          cmsItem.cmsContents
        );

        list.push(newImage);
      }
    });
  }, [selectedCountryAndLanguage]);

  const assetUrl =
    cmsItems && cmsItems.length > 0
      ? getContentValueForType(
          CmsContentType.ASSET_URL,
          cmsItems[currentItemIndex].cmsContents
        )
      : ".png";

  return (
    <Fragment>
      <div style={{ display: "none" }}>
        {cmsItems &&
          cmsItems.map((item) => {
            return (
              <img
                src={getContentValueForType(
                  CmsContentType.ASSET_URL,
                  item.cmsContents
                )}
                alt={"preload"}
              />
            );
          })}
      </div>
      <AnimDiv
        className="hero slide-in"
        style={{
          background: `linear-gradient(0deg, #1b1b1b 0%, 
        rgba(27, 27, 27, 0.4) 24.48%), 
        url(${assetUrl}) center center / cover no-repeat , #1B1B1B`,
        }}
      >
        {assetUrl && cmsItems && cmsItems.length > 0 ? (
          isImage(assetUrl) ? null : (
            <video
              key={assetUrl}
              controls={false}
              autoPlay={true}
              muted={true}
              loop={true}
              playsInline={true}
              webkit-playsinline={true}
            >
              <source src={assetUrl} type="video/mp4" />
            </video>
          )
        ) : null}
        <motion.div
          key={currentItemIndex}
          initial={{ translateX: "100%" }}
          animate={{ translateX: "0%" }}
          transition={{
            type: "keyframes",
          }}
          className={`content-wrapper ${
            currentItemIndex % 2 ? "animate" : "animate2"
          }`}
        >
          <div className="content">
            <h2
              className={
                cmsItems && cmsItems.length > 0
                  ? getTextColor(
                      getCorrectColorIfSet(
                        CmsSection.HOMEPAGE_HERO_SECTION,
                        CmsContentType.COLOR_TITLE,
                        cmsItems[currentItemIndex].cmsContents
                      ),
                      true
                    )
                  : ""
              }
            >
              {cmsItems && cmsItems.length > 0
                ? getContentValueForType(
                    CmsContentType.TITLE,
                    cmsItems[currentItemIndex].cmsContents
                  )
                : null}
            </h2>
            <div
              className={`content-text ${
                cmsItems && cmsItems.length > 0
                  ? getTextColor(
                      getCorrectColorIfSet(
                        CmsSection.HOMEPAGE_HERO_SECTION,
                        CmsContentType.COLOR_DESCRIPTION,
                        cmsItems[currentItemIndex].cmsContents
                      ),
                      false
                    )
                  : ""
              }`}
            >
              {cmsItems && cmsItems.length > 0
                ? getContentValueForType(
                    CmsContentType.DESCRIPTION,
                    cmsItems[currentItemIndex].cmsContents
                  )
                : null}
            </div>
            {cmsItems && cmsItems.length > 0 ? (
              <Button
                section={CmsSection.HOMEPAGE_HERO_SECTION}
                item={cmsItems[currentItemIndex]}
              />
            ) : null}
          </div>
        </motion.div>
        <div className={`bullets ${isImage(assetUrl) ? "" : ""}`}>
          {cmsItems && cmsItems?.length === 1
            ? null
            : cmsItems?.map((item, index) => (
                <div
                  className={`bullet ${
                    index === currentItemIndex ? "" : "hidden"
                  }`}
                  onClick={() => {
                    setCurrentItemIndex(index);
                  }}
                >
                  <div
                    className={`line ${
                      index == currentItemIndex
                        ? "active"
                        : index < currentItemIndex
                        ? "filled"
                        : ""
                    }`}
                  />
                  <div className="text">
                    {getContentValueForType(
                      CmsContentType.TITLE,
                      item.cmsContents
                    )}
                  </div>
                </div>
              ))}
        </div>
      </AnimDiv>
    </Fragment>
  );
}
