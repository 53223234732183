import React, { useEffect } from "react";
import "./ContentEditor.css";

export function ContentEditor({
  design,
  save,
  cancel,
}: {
  design: any;
  save: (data: { html: string; design: string }) => void;
  cancel: () => void;
}) {
  useEffect(() => {
    // eslint-disable-next-line
    // @ts-ignore
    unlayer.init({
      id: "editor-container",
      projectId: 143744,
      displayMode: "web",
    });

    // eslint-disable-next-line
    // @ts-ignore
    unlayer.addEventListener("editor:ready", () => {
      // eslint-disable-next-line
      // @ts-ignore
      unlayer.loadDesign(design);
    });
  }, []);

  return (
    <div className="editor-wrapper">
      <div id="editor-container" />
      <div className="container mt-4 flex">
        <div
          className="button"
          onClick={() => {
            // eslint-disable-next-line
            // @ts-ignore
            unlayer.exportHtml((data) => {
              const { design, html } = data;

              save({
                design: JSON.stringify(design),
                html: html,
              });
            });
          }}
        >
          Save
        </div>
        <div className="button ml-4 purple" onClick={cancel}>
          Cancel
        </div>
      </div>
    </div>
  );
}
