import React, { useContext, useEffect, useState } from "react";
import CrossIcon from "../../assets/cross.svg";
import Dialog from "../Dialog";
import { CmsSection } from "../../enums/CmsSection";
import SettingsApi, { CountryLanguages } from "../../api/SettingsApi";
import { Select } from "../Select";
import CmsApi from "../../api/CmsApi";
import { CmsCountryLanguageContext } from "../../context/CmsCountryLanguageContext";
import { GeneralPopup } from "./GeneralPopup";

export function CopyPopup({
  open,
  setOpen,
  cmsSections,
  page,
}: {
  open: boolean;
  setOpen: (v: boolean) => void;
  cmsSections: CmsSection[];
  page: string;
}) {
  const [warning, setWarning] = useState(false);
  const { selectedCountryAndLanguage } = useContext(CmsCountryLanguageContext);

  const [countryLanguages, setCountryLanguages] = useState<CountryLanguages[]>(
    []
  );
  const [selectedCountry, setSelectedCountry] = useState<string>("");
  const [selectedLanguage, setSelectedLanguage] = useState<string>("");

  useEffect(() => {
    SettingsApi.getCountryLanguages().then((result) =>
      setCountryLanguages(result)
    );
  }, []);

  return (
    <Dialog
      isOpen={open}
      onRequestClose={() => {
        setOpen(false);
      }}
      title={""}
      size={"small"}
      padding={false}
      overflow={false}
    >
      <div className="country-popup">
        <h2>Copy content</h2>
        <img
          className="close pointer"
          src={CrossIcon}
          alt="close"
          onClick={() => {
            setOpen(false);
          }}
        />
        <div className="content program">
          <div className="mb-4">
            You will copy content from {selectedCountryAndLanguage.country} -{" "}
            {selectedCountryAndLanguage.language} - {page}. Please select the
            country and language you want to copy the content to.
          </div>
          <Select
            label={"Country"}
            value={selectedCountry}
            onChange={setSelectedCountry}
            options={[{ label: "", value: "" }].concat(
              countryLanguages.map((item) => {
                return {
                  label: item.country,
                  value: item.country,
                };
              })
            )}
          />
          <Select
            label={"Language"}
            value={selectedLanguage}
            onChange={setSelectedLanguage}
            options={[{ label: "", value: "" }].concat(
              countryLanguages
                .find((c) => c.country === selectedCountry)
                ?.languages.map((item) => {
                  return {
                    label: item.code,
                    value: item.code,
                  };
                }) || []
            )}
          />
          <div
            className="button cta red mt-4"
            onClick={() => {
              if (!selectedLanguage || !selectedCountry) {
                return;
              }

              setWarning(true);
            }}
          >
            Copy content to
          </div>
        </div>
      </div>
      <GeneralPopup open={warning} title={"Warning"} setOpen={setWarning}>
        <div>
          You are about to <b>override</b> the content for {selectedCountry} -{" "}
          {selectedLanguage} - {page} with the current content displayed here.
          Do you want to proceed?
        </div>
        <div className="flex mt-4">
          <div
            className="button cta red mr-4"
            onClick={async () => {
              for (const section of cmsSections) {
                const existingItems = await CmsApi.getCmsItems(
                  section,
                  selectedCountryAndLanguage.country,
                  selectedCountryAndLanguage.language
                );

                const existingItemsNewLanguageAndCountry =
                  await CmsApi.getCmsItems(
                    section,
                    selectedCountry,
                    selectedLanguage
                  );

                if (selectedCountry !== selectedCountryAndLanguage.country) {
                  for (const item of existingItemsNewLanguageAndCountry) {
                    await CmsApi.deleteCmsItem(item.id);
                  }

                  for (const item of existingItems) {
                    await CmsApi.addCmsItem(
                      item.section,
                      selectedCountry,
                      selectedLanguage,
                      item.type,
                      item.cmsContents.map((subItem) => {
                        return {
                          contentType: subItem.type,
                          value: subItem.value,
                        };
                      })
                    );
                  }
                } else {
                  for (const item of existingItems) {
                    for (const subItem of item.cmsContents) {
                      await CmsApi.addCmsContent(
                        item.id,
                        subItem.type,
                        selectedLanguage,
                        subItem.value
                      );
                    }
                  }
                }
              }

              setWarning(false);
              setOpen(false);
            }}
          >
            Proceed
          </div>
          <div
            className="button purple cta"
            onClick={() => {
              setWarning(false);
              setOpen(false);
            }}
          >
            Cancel
          </div>
        </div>
      </GeneralPopup>
    </Dialog>
  );
}
