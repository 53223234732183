import React, { useContext, useEffect, useState } from "react";
import "./HomeSection6.css";
import Placeholder from "../../assets/placeholder.png";
import CmsApi, { CmsItem } from "../../api/CmsApi";
import { CmsSection } from "../../enums/CmsSection";
import { getContentValueForType } from "../cms/getContentForType";
import { CmsContentType } from "../../enums/CmsContentType";
import { UserCountryLanguageContext } from "../../context/UserCountryLanguageContext";
import { ImageOrVideo } from "../ImageOrVideo";
import { getCorrectColorIfSet, getTextColor } from "../../enums/Color";
import { Button } from "../Button";
import AnimDiv from "../AnimDiv";

export function HomeSection6() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItems, setCmsItems] = useState<CmsItem[]>();

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.HOMEPAGE_SECTION6,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItems(items));
  }, [selectedCountryAndLanguage]);

  return (
    <AnimDiv className="section6-wrapper">
      <div className="section6">
        <div className="left-content flex flex-column">
          <h2
            className={
              cmsItems && cmsItems.length > 0
                ? getTextColor(
                    getCorrectColorIfSet(
                      CmsSection.HOMEPAGE_SECTION6,
                      CmsContentType.COLOR_TITLE,
                      cmsItems[0].cmsContents
                    ),
                    true
                  )
                : ""
            }
          >
            {cmsItems && cmsItems.length > 0
              ? getContentValueForType(
                  CmsContentType.TITLE,
                  cmsItems[0]?.cmsContents
                )
              : null}
          </h2>
          <div
            className={`text ${
              cmsItems && cmsItems.length > 0
                ? getTextColor(
                    getCorrectColorIfSet(
                      CmsSection.HOMEPAGE_SECTION6,
                      CmsContentType.COLOR_DESCRIPTION,
                      cmsItems[0].cmsContents
                    ),
                    false
                  )
                : ""
            }`}
          >
            {cmsItems && cmsItems.length > 0
              ? getContentValueForType(
                  CmsContentType.DESCRIPTION,
                  cmsItems[0]?.cmsContents
                )
              : ""}
          </div>
          {cmsItems && cmsItems.length > 0 ? (
            <Button
              section={CmsSection.HOMEPAGE_SECTION6}
              item={cmsItems[0]}
              className="cta-button"
            />
          ) : null}
        </div>
        <div className="right-content">
          <div className="image-wrapper">
            {cmsItems && cmsItems.length > 0 ? (
              <ImageOrVideo
                assetUrl={
                  cmsItems
                    ? getContentValueForType(
                        CmsContentType.ASSET_URL,
                        cmsItems[0]?.cmsContents
                      )
                    : Placeholder
                }
                className={"image"}
                alt={"placeholder"}
              />
            ) : null}
          </div>
        </div>
      </div>
    </AnimDiv>
  );
}
