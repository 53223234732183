import React, { useState } from "react";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { Input } from "../components/Input";
import AuthApi from "../api/AuthApi";
import Bg from "../assets/bg.png";

export function ResetPassword() {
  const [password, setPassword] = useState("");
  const [passwordConfirm, setPasswordConfirm] = useState("");
  const [submitted, setSubmitted] = useState(false);
  const { t } = useTranslation(["reset-pw", "common"]);

  const urlSearchParams = new URLSearchParams(window.location.search);
  const { token, email } = Object.fromEntries(urlSearchParams.entries());
  if (!token || !email) {
    // TODO create more generic error page
    return (
      <div>
        <center>{t("error")}</center>
      </div>
    );
  }

  async function onResetPasswordSubmit(
    email: string,
    token: string,
    password: string,
    confirmPassword: string,
    setSubmitted: (submitted: boolean) => void
  ) {
    if (password === confirmPassword) {
      try {
        await AuthApi.confirmPasswordReset(email, password, token);
        setSubmitted(true);
      } catch (e) {
        Sentry.captureException(e);
        toast.error(t("common:error"), {
          position: toast.POSITION.BOTTOM_CENTER,
          hideProgressBar: true,
        });
      }
    } else {
      toast.error(t("common:passwordsMustMatch"), {
        position: toast.POSITION.TOP_CENTER,
        hideProgressBar: true,
      });
    }
  }

  return (
    <div className="login-container-wrapper flex">
      <div className="left-content">
        <img className="image" src={Bg} />
      </div>
      <div className="right-content">
        <div className="login-container">
          <h2>{t("title")}</h2>
          <div className="background-shadow flex forgot">
            {!submitted && (
              <form
                className="content forgot"
                onSubmit={async (e) => {
                  e.preventDefault();
                  await onResetPasswordSubmit(
                    email,
                    token,
                    password,
                    passwordConfirm,
                    setSubmitted
                  );
                }}
              >
                <Input
                  type={"password"}
                  label={t("password")}
                  value={password}
                  onChange={(e) => setPassword(e)}
                  required
                />
                <Input
                  type={"password"}
                  label={t("confirmPassword")}
                  value={passwordConfirm}
                  onChange={(e) => setPasswordConfirm(e)}
                  required
                />
                <button type="submit" className="button mt-4">
                  {t("resetPassword")}
                </button>
                {submitted && <span>{t("confirmSubmit")}</span>}
              </form>
            )}
            {submitted && (
              <div className="content forgot reset-message">
                Password reset successful!
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
}
