import ApiInstance from "./ApiInstance";
import { Topic } from "./TopicApi";

export type Country = {
  name: string;
  code: string;
};

export type InformationEntity = {
  slug: string;
  title: string;
  content: string;
  excerpt: string;
  isPublic: boolean;
  onHome: boolean;
  countries: Country[];
  topics: Topic[];
};

class InformationApi {
  public async listInformation(): Promise<InformationEntity[]> {
    const results = await ApiInstance.getApi().get<{
      data: InformationEntity[];
    }>("/information");

    if (!results.data) {
      return [];
    }

    if (!results.data.data) {
      return [];
    }

    return results.data.data;
  }
}

export default new InformationApi();
