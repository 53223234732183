import React, { useContext, useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import "./InformationDetail.css";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";
import { jsonLdObjects } from "../constants/JsonLdObjects";

export function EventDetail() {
  const { idOrSlug } = useParams();
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItem, setCmsItem] = useState<CmsItem>();

  useEffect(() => {
    if (!idOrSlug) {
      return;
    }

    CmsApi.getCmsItem(idOrSlug, selectedCountryAndLanguage.language).then(
      (item) => setCmsItem(item)
    );
  }, [idOrSlug]);

  if (!cmsItem) {
    return null;
  }

  const eventJsonLdData = jsonLdObjects.event(cmsItem.id, cmsItem);

  const slug = getContentValueForType(CmsContentType.SLUG, cmsItem.cmsContents);
  const canonicalUrl = `${window.location.origin}/events/${slug || idOrSlug}`;

  return (
    <>
      <div
        className="mt-4 mb-4 no-break"
        dangerouslySetInnerHTML={{
          __html: getContentValueForType(
            CmsContentType.CONTENT,
            cmsItem.cmsContents
          ),
        }}
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(eventJsonLdData)}
        </script>
        <link rel="canonical" href={canonicalUrl} />
      </Helmet>
      );
    </>
  );
}
