import React, { useState } from "react";
import { CmsHeader } from "../../components/cms/CmsHeader";
import { CmsSidebar } from "../../components/cms/CmsSidebar";
import { CmsTable } from "../../components/cms/CmsTable";
import { CmsContentType } from "../../enums/CmsContentType";
import { CmsItemType } from "../../enums/CmsItemType";
import { CmsSection } from "../../enums/CmsSection";
import { CopyPopup } from "../../components/cms/CopyPopup";

export function CmsHome() {
  const [openCopy, setOpenCopy] = useState(false);

  return (
    <div>
      <CmsHeader />
      <div className="flex">
        <CmsSidebar />
        <div className="container">
          <div className="flex mb-4 items-center mt-4">
            <div className="title">Home</div>
            <div
              className="button small cta purple ml-4"
              onClick={() => {
                setOpenCopy(true);
              }}
            >
              Copy content
            </div>
          </div>
          <CmsTable
            sectionName="Hero Section"
            section={CmsSection.HOMEPAGE_HERO_SECTION}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={false}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Introduction"
            section={CmsSection.HOMEPAGE_INTRODUCTION}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 1"
            section={CmsSection.HOMEPAGE_SECTION1}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 2 Head"
            section={CmsSection.HOMEPAGE_SECTION2_HEAD}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 2"
            section={CmsSection.HOMEPAGE_SECTION2}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={false}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 3 Head"
            section={CmsSection.HOMEPAGE_SECTION3_HEAD}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 3"
            section={CmsSection.HOMEPAGE_SECTION3}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={false}
            showItemTypeSelect={true}
            availableItemTypes={[
              CmsItemType.TEXT_SQUARE,
              CmsItemType.TEXT_RECT,
              CmsItemType.IMG_SQUARE,
              CmsItemType.IMG_RECT,
            ]}
          />
          <CmsTable
            sectionName="Section 4"
            section={CmsSection.HOMEPAGE_SECTION4}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 5 Head"
            section={CmsSection.HOMEPAGE_SECTION5_HEAD}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 5"
            section={CmsSection.HOMEPAGE_SECTION5}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={false}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 6"
            section={CmsSection.HOMEPAGE_SECTION6}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 7 Head"
            section={CmsSection.HOMEPAGE_SECTION7_HEAD}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 7"
            section={CmsSection.HOMEPAGE_SECTION7}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={false}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 8 Head"
            section={CmsSection.HOMEPAGE_SECTION8_HEAD}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 8"
            section={CmsSection.HOMEPAGE_SECTION8}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={false}
            showItemTypeSelect={false}
          />
        </div>
      </div>
      <CopyPopup
        page={"Home"}
        open={openCopy}
        setOpen={setOpenCopy}
        cmsSections={[
          CmsSection.HOMEPAGE_HERO_SECTION,
          CmsSection.HOMEPAGE_INTRODUCTION,
          CmsSection.HOMEPAGE_SECTION1,
          CmsSection.HOMEPAGE_SECTION2_HEAD,
          CmsSection.HOMEPAGE_SECTION2,
          CmsSection.HOMEPAGE_SECTION3_HEAD,
          CmsSection.HOMEPAGE_SECTION3,
          CmsSection.HOMEPAGE_SECTION4,
          CmsSection.HOMEPAGE_SECTION5_HEAD,
          CmsSection.HOMEPAGE_SECTION5,
          CmsSection.HOMEPAGE_SECTION6,
          CmsSection.HOMEPAGE_SECTION7_HEAD,
          CmsSection.HOMEPAGE_SECTION7,
          CmsSection.HOMEPAGE_SECTION8_HEAD,
          CmsSection.HOMEPAGE_SECTION8,
        ]}
      />
    </div>
  );
}
