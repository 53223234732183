import React, { useState, useEffect, ReactElement } from "react";
import "./Checkbox.css";

export function Checkbox({
  title,
  value,
  onClick,
  disabled = false,
  className,
  required,
}: {
  title: string | ReactElement;
  value: boolean;
  onClick: (clicked: boolean) => void;
  disabled?: boolean;
  className?: string;
  required?: boolean;
}) {
  const [checked, setChecked] = useState(value);

  useEffect(() => {
    if (disabled) {
      return;
    }

    onClick(checked);
  }, [checked]);

  useEffect(() => {
    if (disabled) {
      return;
    }

    setChecked(value);
  }, [value]);

  return (
    <label
      className={`checkbox-container ${className || ""}`}
      onClick={(e) => {
        e.preventDefault();
        if (disabled) {
          return;
        }

        setChecked(!checked);
      }}
    >
      {title}
      <input
        type="checkbox"
        checked={checked}
        onChange={() => setChecked(!checked)}
        disabled={disabled}
        required={required}
      />
      <span className={`checkmark ${disabled ? "disabled" : ""}`} />
    </label>
  );
}
