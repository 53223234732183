export enum CmsContentType {
  TITLE = "TITLE",
  SUBTITLE = "SUBTITLE",
  DESCRIPTION = "DESCRIPTION",
  CALL_TO_ACTION_TEXT = "CALL_TO_ACTION_TEXT",
  CALL_TO_ACTION_URL = "CALL_TO_ACTION_URL",
  MORE_TEXT = "MORE_TEXT",
  MORE_URL = "MORE_URL",
  ASSET_URL = "ASSET_URL",
  TOPIC = "TOPIC",
  TAGS = "TAGS",
  CONTENT = "CONTENT",
  CONTENT_OBJECT = "CONTENT_OBJECT",
  CHECKBOX = "CHECKBOX",
  IFRAME_URL = "IFRAME_URL",
  IFRAME_URL2 = "IFRAME_URL2",
  SHORT_DESCRIPTION = "SHORT_DESCRIPTION",
  DESCRIPTION2 = "DESCRIPTION2",
  SUBTITLE2 = "SUBTITLE2",
  LOGO_URL = "LOGO_URL",
  DEAL = "DEAL",
  CLAIM_BUTTON_TEXT = "CLAIM_BUTTON_TEXT",
  CLAIM_BUTTON_URL = "CLAIM_BUTTON_URL",
  COLOR_TITLE = "COLOR_TITLE",
  COLOR_SUBTITLE = "COLOR_SUBTITLE",
  COLOR_DESCRIPTION = "COLOR_DESCRIPTION",
  COLOR_SHORT_DESCRIPTION = "COLOR_SHORT_DESCRIPTION",
  COLOR_DESCRIPTION2 = "COLOR_DESCRIPTION2",
  COLOR_SUBTITLE2 = "COLOR_SUBTITLE2",
  COLOR_CALL_TO_ACTION = "COLOR_CALL_TO_ACTION",
  COLOR_CALL_TO_ACTION_TEXT = "COLOR_CALL_TO_ACTION_TEXT",
  VIDEO_MUTED = "VIDEO_MUTED",
  VIDEO_AUTOPLAY = "VIDEO_AUTOPLAY",
  VIDEO_CONTROLS = "VIDEO_CONTROLS",
  SLUG = "SLUG",
  DATE = "DATE",
  ADDRESS = "ADDRESS",
  EMAIL = "EMAIL",
}
