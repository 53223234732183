import React, { useState } from "react";
import { CmsHeader } from "../../components/cms/CmsHeader";
import { CmsSidebar } from "../../components/cms/CmsSidebar";
import { CmsTable } from "../../components/cms/CmsTable";
import { CmsContentType } from "../../enums/CmsContentType";
import { CmsSection } from "../../enums/CmsSection";
import { CopyPopup } from "../../components/cms/CopyPopup";

export function CmsJoinForces() {
  const [openCopy, setOpenCopy] = useState(false);

  return (
    <div>
      <CmsHeader />
      <div className="flex">
        <CmsSidebar />
        <div className="container">
          <div className="flex mb-4 items-center mt-4">
            <div className="title">Join forces</div>
            <div
              className="button small cta purple ml-4"
              onClick={() => {
                setOpenCopy(true);
              }}
            >
              Copy content
            </div>
          </div>
          <CmsTable
            sectionName="Hero"
            section={CmsSection.FORCES_HERO}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 1"
            section={CmsSection.FORCES_SECTION1}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 2"
            section={CmsSection.FORCES_SECTION2}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 3"
            section={CmsSection.FORCES_SECTION3}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 4"
            section={CmsSection.FORCES_SECTION4}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 5"
            section={CmsSection.FORCES_SECTION5}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 6"
            section={CmsSection.FORCES_SECTION6}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
        </div>
      </div>
      <CopyPopup
        page={"Join forces"}
        open={openCopy}
        setOpen={setOpenCopy}
        cmsSections={[
          CmsSection.FORCES_HERO,
          CmsSection.FORCES_SECTION1,
          CmsSection.FORCES_SECTION2,
          CmsSection.FORCES_SECTION3,
          CmsSection.FORCES_SECTION4,
          CmsSection.FORCES_SECTION5,
          CmsSection.FORCES_SECTION6,
        ]}
      />
    </div>
  );
}
