export const industries = [
  { value: "FinTech", label: "FinTech" },
  { value: "Consultancy", label: "Consultancy" },
  { value: "Education", label: "Education" },
  { value: "EdTech", label: "EdTech" },
  { value: "Construction", label: "Construction" },
  { value: "Circular economy", label: "Circular economy" },
  { value: "Fashion", label: "Fashion" },
  { value: "Financial", label: "Financial" },
  { value: "Inclusion", label: "Inclusion" },
  { value: "HRM", label: "HRM" },
  { value: "Sports", label: "Sports" },
  { value: "Raw Materials", label: "Raw Materials" },
  { value: "Industry 4.0", label: "Industry 4.0" },
  { value: "Web 3", label: "Web 3" },
  { value: "Hardware", label: "Hardware" },
  { value: "MedTech", label: "MedTech" },
  { value: "SmartCities", label: "SmartCities" },
  { value: "CleanTech", label: "CleanTech" },
  { value: "BioTech", label: "BioTech" },
  { value: "Biochemistry", label: "Biochemistry" },
  { value: "Blue economy", label: "Blue economy" },
  { value: "Space", label: "Space" },
  { value: "BtoB software", label: "BtoB software" },
  { value: "BtoC software", label: "BtoC software" },
  { value: "Deep tech", label: "Deep tech" },
  { value: "ICT", label: "ICT" },
  { value: "Wholesale", label: "Wholesale" },
  { value: "Mobility", label: "Mobility" },
  { value: "Energy", label: "Energy" },
  { value: "E commerce", label: "E commerce" },
  { value: "Food", label: "Food" },
  { value: "Logistics", label: "Logistics" },
  { value: "Travel", label: "Travel" },
  {
    value: "Media,advertising and communication",
    label: "Media,advertising and communication",
  },
  { value: "Leisure", label: "Leisure" },
  { value: "Horeca", label: "Horeca" },
  { value: "Agri", label: "Agri" },
  { value: "Retail", label: "Retail" },
  { value: "Health", label: "Health" },
  { value: "Production", label: "Production" },
  { value: "Gaming", label: "Gaming" },
  { value: "Impact", label: "Impact" },
  { value: "Others", label: "Others" },
];
