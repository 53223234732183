import React, { Fragment, useContext, useEffect, useState } from "react";
import { Link } from "react-router-dom";
import "./Scheduling.css";
import Placeholder from "../assets/placeholder.png";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { CmsSection } from "../enums/CmsSection";
import { CmsContentType } from "../enums/CmsContentType";
import { getContentValueForType } from "../components/cms/getContentForType";
import { ImageOrVideo } from "../components/ImageOrVideo";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import AuthApi, { User } from "../api/AuthApi";
import { availableRoutes } from "../constants/AvailableRoutes";
import { Trans, useTranslation } from "react-i18next";
import { InlineWidget } from "react-calendly";
import { Button } from "../components/Button";
import AnimDiv from "../components/AnimDiv";

export function Scheduling({
  topSection,
  bottomSection,
  url,
}: {
  topSection: CmsSection;
  bottomSection: CmsSection;
  url: string;
}) {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItemTop, setCmsItemTop] = useState<CmsItem>();
  const [cmsItemBottom, setCmsItemBottom] = useState<CmsItem>();
  const [user, setUser] = useState<User | null>(null);

  const { t } = useTranslation("scheduling");

  useEffect(() => {
    CmsApi.getCmsItems(
      topSection,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemTop(items[0]));

    CmsApi.getCmsItems(
      bottomSection,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemBottom(items[0]));
  }, [selectedCountryAndLanguage, topSection, bottomSection]);

  useEffect(() => {
    AuthApi.getCurrentUser().then((u) => setUser(u));
  }, []);

  return (
    <div className="scheduling">
      <div className="bg top" />
      <AnimDiv className="scheduling-top-wrapper">
        <div className="scheduling-top">
          <div className="scheduling-left-content flex flex-column">
            <h2>
              {getContentValueForType(
                CmsContentType.TITLE,
                cmsItemTop?.cmsContents
              ) || "Title"}
            </h2>
            <div className="text">
              {getContentValueForType(
                CmsContentType.DESCRIPTION,
                cmsItemTop?.cmsContents
              ) || ""}
            </div>
          </div>
          <div className="scheduling-right-content">
            <div className="image-wrapper">
              {cmsItemTop && (
                <ImageOrVideo
                  assetUrl={
                    cmsItemTop
                      ? getContentValueForType(
                          CmsContentType.ASSET_URL,
                          cmsItemTop?.cmsContents
                        )
                      : Placeholder
                  }
                  className={"image"}
                  alt={"placeholder"}
                />
              )}
            </div>
          </div>
        </div>
      </AnimDiv>
      {user !== null ? (
        <Fragment>
          <InlineWidget
            styles={{
              height: "778px",
            }}
            url={`${url}?hide_gdpr_banner=1&embed_domain=studentstartups.eu`}
            prefill={{
              email: user.email,
              firstName: user.name,
              lastName: user.surname,
              name: `${user.name} ${user.surname}`,
            }}
          />
          <div className="bg bottom flex">
            <div className="book-an-appointment">BOOK AN APPOINTMENT</div>
          </div>
        </Fragment>
      ) : (
        <div className="calendly-widget-placeholder">
          <Trans t={t} i18nKey="pleaseLogIn">
            <Link to={availableRoutes.login} />
          </Trans>
        </div>
      )}
      <div className="scheduling-bottom-wrapper">
        <div className="scheduling-bottom">
          <h2>
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsItemBottom?.cmsContents
            )}
          </h2>
          <div className="flex items-center">
            <div className="flex scheduling-bottom-frame">
              <div className="image-wrapper">
                {
                  <ImageOrVideo
                    assetUrl={
                      cmsItemBottom
                        ? getContentValueForType(
                            CmsContentType.ASSET_URL,
                            cmsItemBottom.cmsContents
                          )
                        : Placeholder
                    }
                    className="image"
                    alt="placeholder"
                  />
                }
              </div>
              <div className="w-100 flex flex-column">
                <div className="scheduling-bottom-title">
                  {cmsItemBottom &&
                    getContentValueForType(
                      CmsContentType.SUBTITLE,
                      cmsItemBottom.cmsContents
                    )}
                </div>
                <div className="scheduling-bottom-content">
                  {cmsItemBottom &&
                    getContentValueForType(
                      CmsContentType.DESCRIPTION,
                      cmsItemBottom.cmsContents
                    )}
                </div>
                {cmsItemBottom ? (
                  <Button
                    section={bottomSection}
                    item={cmsItemBottom}
                    className="ml-auto"
                    textClassName="color-white"
                  />
                ) : null}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
