import React, { Fragment, PropsWithChildren } from "react";
import { Navigate } from "react-router-dom";

export function RedirectToPageIfNoPermission({
  link,
  permission,
  children,
}: PropsWithChildren<{ link: string; permission: boolean | undefined }>) {
  if (permission === false) {
    return <Navigate to={link} />;
  }

  return <Fragment>{children}</Fragment>;
}
