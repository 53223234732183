import React, { useContext, useEffect, useState } from "react";
import "./ForCorporates.css";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";
import { Grid } from "../components/Grid";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { CmsSection } from "../enums/CmsSection";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import { ImageOrVideo } from "../components/ImageOrVideo";
import { Link } from "react-router-dom";
import { availableRoutes } from "../constants/AvailableRoutes";
import { getCorrectColorIfSet, getTextColor } from "../enums/Color";
import { Button } from "../components/Button";
import AnimDiv from "../components/AnimDiv";

export function ForCorporates() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItemHero, setCmsItemHero] = useState<CmsItem>();
  const [cmsItemSection1, setCmsItemSection1] = useState<CmsItem>();
  const [cmsItemsSection2, setCmsItemsSection2] = useState<CmsItem[]>();
  const [cmsItemSection2Head, setCmsItemSection2Head] = useState<CmsItem>();
  const [cmsItemSection3, setCmsItemSection3] = useState<CmsItem>();

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.CORPORATES_HERO,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemHero(items[0]));

    CmsApi.getCmsItems(
      CmsSection.CORPORATES_SECTION1,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection1(items[0]));

    CmsApi.getCmsItems(
      CmsSection.CORPORATES_SECTION2,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemsSection2(items));

    CmsApi.getCmsItems(
      CmsSection.CORPORATES_SECTION2_HEAD,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection2Head(items[0]));

    CmsApi.getCmsItems(
      CmsSection.CORPORATES_SECTION3,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection3(items[0]));
  }, []);

  const ctaButtonText =
    getContentValueForType(
      CmsContentType.CALL_TO_ACTION_TEXT,
      cmsItemSection3?.cmsContents
    ) || "";

  const ctaButtonUrl =
    getContentValueForType(
      CmsContentType.CALL_TO_ACTION_URL,
      cmsItemSection3?.cmsContents
    ) || "";

  return (
    <div className="corporates">
      <AnimDiv className="corporate-hero">
        <ImageOrVideo
          assetUrl={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItemHero?.cmsContents
          )}
          className={"image"}
          alt={"bg"}
        />
        <div className="content">
          <h2
            className={getTextColor(
              getCorrectColorIfSet(
                CmsSection.CORPORATES_HERO,
                CmsContentType.COLOR_TITLE,
                cmsItemHero?.cmsContents
              ),
              true
            )}
          >
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsItemHero?.cmsContents
            )}
          </h2>
          <div
            className={`text  ${getTextColor(
              getCorrectColorIfSet(
                CmsSection.CORPORATES_HERO,
                CmsContentType.COLOR_DESCRIPTION,
                cmsItemHero?.cmsContents
              ),
              false
            )}`}
          >
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemHero?.cmsContents
            )}
          </div>
          {cmsItemHero ? (
            <Button
              section={CmsSection.CORPORATES_HERO}
              item={cmsItemHero}
              className={"ml-auto button-wrapper"}
            />
          ) : null}
        </div>
      </AnimDiv>
      <AnimDiv className="section-2-wrapper">
        <div className="section-2">
          <div className="flex relative">
            <div className="line" />
            <div className="circle" />
          </div>
          <div className="content flex flex-column">
            <h2>
              {getContentValueForType(
                CmsContentType.TITLE,
                cmsItemSection1?.cmsContents
              )}
            </h2>
            {getContentValueForType(
              CmsContentType.SUBTITLE,
              cmsItemSection1?.cmsContents
            ) ? (
              <div className="content-title">
                {getContentValueForType(
                  CmsContentType.SUBTITLE,
                  cmsItemSection1?.cmsContents
                )}
              </div>
            ) : null}
            <div>
              {getContentValueForType(
                CmsContentType.DESCRIPTION,
                cmsItemSection1?.cmsContents
              )}
            </div>
            {cmsItemSection1 ? (
              <Button
                section={CmsSection.CORPORATES_SECTION1}
                item={cmsItemSection1}
                className="ml-auto button-wrapper"
                textClassName="color-white"
              />
            ) : null}
          </div>
        </div>
      </AnimDiv>
      <AnimDiv className="section-3-wrapper">
        <div className="flex section-3">
          <div>
            <h2>
              {getContentValueForType(
                CmsContentType.TITLE,
                cmsItemSection2Head?.cmsContents
              )}
            </h2>
          </div>
          {cmsItemSection2Head ? (
            <Button
              section={CmsSection.CORPORATES_SECTION2_HEAD}
              item={cmsItemSection2Head}
              className="ml-auto purple"
              textClassName="color-white"
            />
          ) : null}
        </div>
        <Grid
          pattern={["w3-3", "w2-3", "w1-3", "w1-3", "w2-3"]}
          items={
            cmsItemsSection2?.map((item) => {
              const title = getContentValueForType(
                CmsContentType.TITLE,
                item.cmsContents
              );
              const assetUrl = getContentValueForType(
                CmsContentType.ASSET_URL,
                item.cmsContents
              );
              const buttonText = getContentValueForType(
                CmsContentType.CALL_TO_ACTION_TEXT,
                item.cmsContents
              );
              const buttonUrl = getContentValueForType(
                CmsContentType.CALL_TO_ACTION_URL,
                item.cmsContents
              );
              const description = getContentValueForType(
                CmsContentType.DESCRIPTION,
                item.cmsContents
              );
              const content = getContentValueForType(
                CmsContentType.CONTENT,
                item.cmsContents
              );

              return {
                id: item.id,
                title: title,
                assetUrl: assetUrl,
                buttonText: buttonText,
                buttonUrl: buttonUrl,
                topic: "",
                description: description,
                content: content,
              };
            }) || []
          }
          defaultRoute={availableRoutes.forCorporates}
          full={true}
        />
      </AnimDiv>
      <AnimDiv className="bottom">
        <ImageOrVideo
          assetUrl={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItemSection3?.cmsContents
          )}
          className={"image"}
          alt={"bg"}
        />
        <div className="content">
          <div
            className={`content-title ${getTextColor(
              getCorrectColorIfSet(
                CmsSection.CORPORATES_SECTION3,
                CmsContentType.COLOR_TITLE,
                cmsItemSection3?.cmsContents
              ),
              true
            )}`}
          >
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsItemSection3?.cmsContents
            )}
          </div>
          <div
            className={`${getTextColor(
              getCorrectColorIfSet(
                CmsSection.CORPORATES_SECTION3,
                CmsContentType.COLOR_DESCRIPTION,
                cmsItemSection3?.cmsContents
              ),
              false
            )}`}
          >
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemSection3?.cmsContents
            )}
          </div>
          <div className="flex buttons">
            {cmsItemSection3 ? (
              <Button
                section={CmsSection.CORPORATES_SECTION3}
                item={cmsItemSection3}
              />
            ) : null}

            <Link
              to={"/contact"}
              className={`button cta purple color-white uppercase ${
                ctaButtonUrl === "" || ctaButtonText === "" ? "fixed" : ""
              }`}
            >
              contact us
            </Link>
          </div>
        </div>
      </AnimDiv>
    </div>
  );
}
