import React, { useContext, useEffect, useState } from "react";
import "./HomeSection2.css";
import CmsApi, { CmsItem } from "../../api/CmsApi";
import { CmsSection } from "../../enums/CmsSection";
import { getContentValueForType } from "../cms/getContentForType";
import { CmsContentType } from "../../enums/CmsContentType";
import { UserCountryLanguageContext } from "../../context/UserCountryLanguageContext";
import { ImageOrVideo } from "../ImageOrVideo";
import Placeholder from "../../assets/placeholder2.png";
import { getCorrectColorIfSet, getTextColor } from "../../enums/Color";
import { isMobile } from "react-device-detect";
import { Button } from "../Button";
import AnimDiv from "../AnimDiv";
import { getTarget } from "../../util";

export function HomeSection2({
  section,
  sectionHead,
}: {
  section: CmsSection;
  sectionHead: CmsSection;
}) {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [headCmsItem, setHeadCmsItem] = useState<CmsItem>();
  const [cmsItems, setCmsItems] = useState<CmsItem[]>();

  useEffect(() => {
    CmsApi.getCmsItems(
      sectionHead,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setHeadCmsItem(items[0]));

    CmsApi.getCmsItems(
      section,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItems(items));
  }, [selectedCountryAndLanguage]);

  return !cmsItems || cmsItems.length !== 0 ? (
    <div className="section2-wrapper">
      <AnimDiv className="flex justify-center">
        <h2 className="section2-title">
          {getContentValueForType(
            CmsContentType.TITLE,
            headCmsItem?.cmsContents
          )}
        </h2>
      </AnimDiv>
      <AnimDiv className="frames">
        {cmsItems?.map((item) => {
          const ctaButtonUrl = cmsItems
            ? getContentValueForType(
                CmsContentType.CALL_TO_ACTION_URL,
                item.cmsContents
              )
            : "";

          if (isMobile) {
            return (
              <a
                href={ctaButtonUrl}
                target={getTarget(ctaButtonUrl)}
                key={item.id}
                className="frame"
              >
                <div className="overlay" />
                <div
                  className={`overlay-title ${getTextColor(
                    getCorrectColorIfSet(
                      section,
                      CmsContentType.COLOR_TITLE,
                      item.cmsContents
                    ),
                    true
                  )}`}
                >
                  {getContentValueForType(
                    CmsContentType.TITLE,
                    item.cmsContents
                  )}
                </div>
                <div className="image-wrapper">
                  {
                    <ImageOrVideo
                      assetUrl={
                        cmsItems
                          ? getContentValueForType(
                              CmsContentType.ASSET_URL,
                              item.cmsContents
                            )
                          : Placeholder
                      }
                      className={"image"}
                      alt={"placeholder"}
                    />
                  }
                </div>
              </a>
            );
          }

          return (
            <div key={item.id} className="frame">
              <div className="overlay" />
              <div className="image-wrapper">
                {
                  <ImageOrVideo
                    assetUrl={
                      cmsItems
                        ? getContentValueForType(
                            CmsContentType.ASSET_URL,
                            item.cmsContents
                          )
                        : Placeholder
                    }
                    className={"image"}
                    alt={"placeholder"}
                  />
                }
              </div>
              <div className="frame-content">
                <div className="frame-title">
                  {getContentValueForType(
                    CmsContentType.TITLE,
                    item.cmsContents
                  )}
                </div>
                <div className="frame-description">
                  {getContentValueForType(
                    CmsContentType.DESCRIPTION,
                    item.cmsContents
                  )}
                </div>
                <Button
                  section={section}
                  item={item}
                  className={"white small ml-auto mt-auto frame-button"}
                  textClassName={"color-white"}
                />
              </div>
            </div>
          );
        })}
      </AnimDiv>
      <div className="black-bg flex justify-center">
        {headCmsItem ? (
          <Button
            section={sectionHead}
            item={headCmsItem}
            textClassName="color-white"
          />
        ) : null}
      </div>
    </div>
  ) : null;
}
