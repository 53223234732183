import React, { useEffect, useState } from "react";
import { BrowserRouter, Routes, Route } from "react-router-dom";
import { Information } from "./pages/Information";
import { availableRoutes } from "./constants/AvailableRoutes";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { InformationTable } from "./pages/cms/InformationTable";
import { CoachTable } from "./pages/cms/CoachTable";
import { Footer } from "./components/Footer";
import { Header } from "./components/Header";
import { Login } from "./pages/Login";
import { CalendlyAuth } from "./pages/auth/CalendlyAuth";
import { ForgotPassword } from "./pages/ForgotPassword";
import { ResetPassword } from "./pages/ResetPassword";
import { ConfirmEmail } from "./pages/ConfirmEmail";
import { HeroSection } from "./components/home/HeroSection";
import { IntroductionSection } from "./components/home/IntroductionSection";
import { CmsHome } from "./pages/cms/CmsHome";
import { CmsCountryLanguageContext } from "./context/CmsCountryLanguageContext";
import { CmsSettings } from "./pages/cms/CmsSettings";
import { HomeSection1 } from "./components/home/HomeSection1";
import { HomeSection2 } from "./components/home/HomeSection2";
import { HomeSection3 } from "./components/home/HomeSection3";
import { HomeSection4 } from "./components/home/HomeSection4";
import { HomeSection6 } from "./components/home/HomeSection6";
import { HomeSection5 } from "./components/home/HomeSection5";
import { HomeSection7 } from "./components/home/HomeSection7";
import { UserCountryLanguageContext } from "./context/UserCountryLanguageContext";
import { useTranslation } from "react-i18next";
import { HomeSection8 } from "./components/home/HomeSection8";
import { Signup } from "./pages/Signup";
import { SignupOptions } from "./pages/SignupOptions";
import { CmsInformation } from "./pages/cms/CmsInformation";
import { CmsTags } from "./pages/cms/CmsTags";
import { InformationDetail } from "./pages/InformationDetail";
import { CmsInspiration } from "./pages/cms/CmsInspiration";
import { Inspiration } from "./pages/Inspiration";
import { Scheduling } from "./pages/Scheduling";
import { CmsSection } from "./enums/CmsSection";
import { CmsCoaching } from "./pages/cms/CmsCoaching";
import { CmsAdvice } from "./pages/cms/CmsAdvice";
import { CmsMentoring } from "./pages/cms/CmsMentoring";
import { Config } from "./Config";
import { CmsFaq } from "./pages/cms/CmsFaq";
import { Faq } from "./pages/Faq";
import { Training } from "./pages/Training";
import { CmsTraining } from "./pages/cms/CmsTraining";
import { ForCorporates } from "./pages/ForCorporates";
import { RedirectToHomeIfNotAdmin } from "./components/RedirectToHomeIfNotAdmin";
import { CmsForCorporates } from "./pages/cms/CmsForCorporates";
import { CmsForHigherEdu } from "./pages/cms/CmsForHigherEdu";
import { CmsForInvestors } from "./pages/cms/CmsForInvestors";
import { CmsForMentors } from "./pages/cms/CmsForMentors";
import { ForHigherEdu } from "./pages/ForHigherEdu";
import { ForInvestors } from "./pages/ForInvestors";
import { ForMentors } from "./pages/ForMentors";
import { AboutUs } from "./pages/AboutUs";
import { CmsAboutUs } from "./pages/cms/CmsAboutUs";
import { Contact } from "./pages/Contact";
import { CmsContact } from "./pages/cms/CmsContact";
import { CmsBooking } from "./pages/cms/CmsBooking";
import { Academy } from "./pages/Academy";
import { CmsAcademy } from "./pages/cms/CmsAcademy";
import { ScrollToTop } from "./components/ScrollToTop";
import { JoinForces } from "./pages/JoinForces";
import { CmsJoinForces } from "./pages/cms/CmsJoinForces";
import { CmsFunding } from "./pages/cms/CmsFunding";
import { Funding } from "./pages/Funding";
import { CmsStartSmart } from "./pages/cms/CmsStartSmart";
import { Podcasts } from "./pages/Podcasts";
import { PodcastsDetail } from "./pages/PodcastsDetail";
import { CmsPodcasts } from "./pages/cms/CmsPodcasts";
import { CmsCommunity } from "./pages/cms/CmsCommunity";
import { Community } from "./pages/Community";
import { Events } from "./pages/Events";
import { CmsEvents } from "./pages/cms/CmsEvents";
import { StartupCafe } from "./pages/StartupCafe";
import { CmsStartupCafe } from "./pages/cms/CmsStartupCafe";
import { Unbounded } from "./pages/Unbounded";
import { CmsUnbounded } from "./pages/cms/CmsUnbounded";
import { StartSmart } from "./pages/StartSmart";
import { StartSmartDetail } from "./pages/StartSmartDetail";
import { RedirectToPageIfNotLoggedIn } from "./components/RedirectToPageIfNotLoggedIn";
import { PrivacyPolicy } from "./pages/PrivacyPolicy";
import { CmsLogin } from "./pages/cms/CmsLogin";
import { CmsSignUp } from "./pages/cms/CmsSignUp";
import { CheckEmail } from "./pages/CheckEmail";
import { AnimatePresence, motion } from "framer-motion";
import { InitialLoading } from "./components/InitialLoading";
import { EventDetail } from "./pages/EventDetail";

export default function App() {
  const [countryAndLanguage, setCountryAndLanguage] = useState({
    country: "BE",
    language: "en",
  });
  const [userCountryAndLanguage, setUserCountryAndLanguage] = useState({
    country: "BE",
    language: "en",
  });
  const [showLoading, setShowLoading] = useState(true);

  const { i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(userCountryAndLanguage.language);
  }, [userCountryAndLanguage, i18n]);

  useEffect(() => {
    setTimeout(() => {
      setShowLoading(false);
    }, 2500);
  }, []);

  return (
    <UserCountryLanguageContext.Provider
      value={{
        setCountryAndLanguage: setUserCountryAndLanguage,
        selectedCountryAndLanguage: userCountryAndLanguage,
      }}
    >
      <CmsCountryLanguageContext.Provider
        value={{
          setCountryAndLanguage: setCountryAndLanguage,
          selectedCountryAndLanguage: countryAndLanguage,
        }}
      >
        <BrowserRouter>
          <AnimatePresence>
            <motion.div
              initial={{ opacity: 1, zIndex: 100, position: "absolute" }}
              animate={{
                opacity: 0,
                zIndex: 100,
                position: "absolute",
              }}
              transition={{
                ease: "linear",
                duration: 2,
                delay: 0.5,
              }}
            >
              {showLoading ? <InitialLoading /> : null}
            </motion.div>
          </AnimatePresence>
          <ScrollToTop />
          <Header />
          <Routes>
            <Route
              path={availableRoutes.home}
              element={
                <motion.div
                  initial="offscreen"
                  whileInView="onscreen"
                  viewport={{ once: true, amount: 0.8 }}
                >
                  <HeroSection />
                  <IntroductionSection
                    section={CmsSection.HOMEPAGE_INTRODUCTION}
                  />
                  <HomeSection1 section={CmsSection.HOMEPAGE_SECTION1} />
                  <HomeSection2
                    sectionHead={CmsSection.HOMEPAGE_SECTION2_HEAD}
                    section={CmsSection.HOMEPAGE_SECTION2}
                  />
                  <HomeSection3 />
                  <HomeSection4 />
                  <HomeSection5 />
                  <HomeSection6 />
                  <HomeSection7 />
                  <HomeSection8 />
                </motion.div>
              }
            />
            <Route path={availableRoutes.faq} element={<Faq />} />
            <Route
              path={availableRoutes.informationDetail}
              element={<InformationDetail />}
            />
            <Route
              path={availableRoutes.detail}
              element={<InformationDetail />}
            />
            <Route
              path={availableRoutes.information}
              element={<Information />}
            />
            <Route path={availableRoutes.signup} element={<SignupOptions />} />
            <Route path={availableRoutes.signupNext} element={<Signup />} />
            <Route
              path={availableRoutes.inspirationDetail}
              element={<InformationDetail />}
            />
            <Route
              path={availableRoutes.inspiration}
              element={<Inspiration />}
            />
            <Route
              path={availableRoutes.trainingDetail}
              element={<InformationDetail />}
            />
            <Route
              path={availableRoutes.eventsDetail}
              element={<EventDetail />}
            />
            <Route path={availableRoutes.events} element={<Events />} />
            <Route path={availableRoutes.training} element={<Training />} />
            <Route path={availableRoutes.signup} element={<Signup />} />
            <Route
              path={availableRoutes.adminInformationTable}
              element={<InformationTable />}
            />
            <Route
              path={availableRoutes.adminCoachTable}
              element={<CoachTable />}
            />
            <Route path={availableRoutes.footer} element={<Footer />} />
            <Route path={availableRoutes.header} element={<Header />} />
            <Route path={availableRoutes.login} element={<Login />} />
            <Route
              path={availableRoutes.auth.calendly}
              element={<CalendlyAuth />}
            />
            <Route
              path={availableRoutes.forgotPassword}
              element={<ForgotPassword />}
            />
            <Route
              path={availableRoutes.resetPassword}
              element={<ResetPassword />}
            />
            <Route
              path={availableRoutes.confirmEmail}
              element={<ConfirmEmail />}
            />
            <Route path={availableRoutes.checkEmail} element={<CheckEmail />} />
            <Route
              path={availableRoutes.forCorporates}
              element={<ForCorporates />}
            />
            <Route
              path={availableRoutes.forHigherEdu}
              element={<ForHigherEdu />}
            />
            <Route
              path={availableRoutes.forInvestors}
              element={<ForInvestors />}
            />
            <Route
              path={availableRoutes.forCorporatesDetail}
              element={<InformationDetail />}
            />
            <Route
              path={availableRoutes.forInvestorsDetail}
              element={<InformationDetail />}
            />
            <Route
              path={availableRoutes.forMentorsDetail}
              element={<InformationDetail />}
            />
            <Route
              path={availableRoutes.forHigherEduDetail}
              element={<InformationDetail />}
            />
            <Route path={availableRoutes.joinForces} element={<JoinForces />} />
            <Route path={availableRoutes.about} element={<AboutUs />} />
            <Route path={availableRoutes.forMentors} element={<ForMentors />} />
            <Route path={availableRoutes.funding} element={<Funding />} />
            <Route path={availableRoutes.podcasts} element={<Podcasts />} />
            <Route path={availableRoutes.community} element={<Community />} />
            <Route path={availableRoutes.startSmart} element={<StartSmart />} />
            <Route
              path={availableRoutes.startSmartDetail}
              element={
                <RedirectToPageIfNotLoggedIn link={availableRoutes.startSmart}>
                  <StartSmartDetail />
                </RedirectToPageIfNotLoggedIn>
              }
            />
            <Route
              path={availableRoutes.startupCafe}
              element={<StartupCafe />}
            />
            <Route path={availableRoutes.unbounded} element={<Unbounded />} />
            <Route
              path={availableRoutes.podcastsDetails}
              element={<PodcastsDetail />}
            />
            <Route
              path={availableRoutes.cms.root}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsHome />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.home}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsHome />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.settings}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsSettings />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.training}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsTraining />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.information}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsInformation />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.inspiration}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsInspiration />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.tags}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsTags />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.coaching}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsCoaching />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.advice}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsAdvice />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.mentoring}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsMentoring />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.faq}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsFaq />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.corporates}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsForCorporates />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.higherEdu}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsForHigherEdu />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.investors}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsForInvestors />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.mentors}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsForMentors />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.about}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsAboutUs />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.contact}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsContact />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.booking}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsBooking />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.academy}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsAcademy />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.joinForces}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsJoinForces />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.funding}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsFunding />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.podcasts}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsPodcasts />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.community}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsCommunity />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.events}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsEvents />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.startupCafe}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsStartupCafe />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.unbounded}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsUnbounded />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.startSmart}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsStartSmart />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.login}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsLogin />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route
              path={availableRoutes.cms.signup}
              element={
                <RedirectToHomeIfNotAdmin>
                  <CmsSignUp />
                </RedirectToHomeIfNotAdmin>
              }
            />
            <Route path={availableRoutes.signup} element={<SignupOptions />} />
            <Route
              path={availableRoutes.coaching}
              element={
                <Scheduling
                  topSection={CmsSection.COACHING_TOP}
                  bottomSection={CmsSection.COACHING_BOTTOM}
                  url={Config.getCalendlySchedulingUrlCoach()}
                />
              }
            />
            <Route
              path={availableRoutes.advice}
              element={
                <Scheduling
                  topSection={CmsSection.ADVICE_TOP}
                  bottomSection={CmsSection.ADVICE_BOTTOM}
                  url={Config.getCalendlySchedulingUrlAdvice()}
                />
              }
            />
            <Route
              path={availableRoutes.mentoring}
              element={
                <Scheduling
                  topSection={CmsSection.MENTORING_TOP}
                  bottomSection={CmsSection.MENTORING_BOTTOM}
                  url={Config.getCalendlySchedulingUrlMentor()}
                />
              }
            />
            <Route
              path={availableRoutes.nathalieBooking}
              element={
                <Scheduling
                  topSection={CmsSection.NATHALIE_BOOKING_TOP}
                  bottomSection={CmsSection.NATHALIE_BOOKING_BOTTOM}
                  url={Config.getCalendlySchedulingUrlNathalie()}
                />
              }
            />
            <Route
              path={availableRoutes.steveBooking}
              element={
                <Scheduling
                  topSection={CmsSection.STEVE_BOOKING_TOP}
                  bottomSection={CmsSection.STEVE_BOOKING_BOTTOM}
                  url={Config.getCalendlySchedulingUrlSteve()}
                />
              }
            />
            <Route path={availableRoutes.academy} element={<Academy />} />
            <Route path={availableRoutes.contact} element={<Contact />} />
            <Route
              path={availableRoutes.privacyPolicy}
              element={<PrivacyPolicy />}
            ></Route>
          </Routes>
          <Footer />
          <ToastContainer />
        </BrowserRouter>
      </CmsCountryLanguageContext.Provider>
    </UserCountryLanguageContext.Provider>
  );
}
