import React, { useState } from "react";
import { Select } from "../Select";
import { countries } from "../../constants/Countries";
import { languages as availableLanguages } from "../../constants/Languages";
import { FaTrash } from "react-icons/fa";
import { CountryLanguages, Language } from "../../api/SettingsApi";
import { Checkbox } from "../Checkbox";

export function CountryLanguageForm({
  save,
  defaultValue,
  currentSelectedCountries,
}: {
  save: (data: CountryLanguages) => void;
  defaultValue?: CountryLanguages;
  currentSelectedCountries: string[];
}) {
  const [country, setCountry] = useState(
    defaultValue ? defaultValue.country : "BE"
  );
  const [languages, setLanguages] = useState<Language[]>(
    defaultValue?.languages || [{ code: "", live: false }]
  );

  return (
    <div>
      <Select
        label={"Select country"}
        value={country}
        onChange={(e) => {
          setCountry(e);
        }}
        options={[{ label: "", value: "" }].concat(
          countries
            .filter((c) => !currentSelectedCountries.includes(c.code))
            .map((c) => {
              return { label: c.name, value: c.code };
            })
        )}
      />
      <br />
      <b>Choose languages</b>
      <div>
        {languages.map((language, index) => {
          return (
            <div key={index} className="flex items-center">
              <Select
                label={"Select language"}
                value={language.code}
                onChange={(e) => {
                  setLanguages(
                    languages.map((l, i) => {
                      if (i === index) {
                        return { code: e, live: l.live };
                      }

                      return l;
                    })
                  );
                }}
                options={[{ label: "", value: "" }].concat(
                  availableLanguages.map((c) => {
                    return { label: c.name, value: c.code };
                  })
                )}
              />
              <Checkbox
                title={"Show language?"}
                value={language.live}
                onClick={(v) => {
                  setLanguages(
                    languages.map((l, i) => {
                      if (i === index) {
                        return { code: l.code, live: v };
                      }

                      return l;
                    })
                  );
                }}
              />
              <FaTrash
                className="ml-4 pointer"
                onClick={() => {
                  setLanguages(languages.filter((_, i) => i !== index));
                }}
              />
            </div>
          );
        })}
      </div>
      <div
        className="mt-4 pointer"
        onClick={() => {
          setLanguages(languages.concat({ code: "", live: false }));
        }}
      >
        + add language
      </div>
      <div
        className="button mt-4"
        onClick={() => {
          save({
            country: country,
            languages: languages.filter((l) => l.code !== ""),
          });
        }}
      >
        Save
      </div>
    </div>
  );
}
