import React from "react";
import LogoText from "../assets/LogoText.svg";
import "./InitialLoading.css";

export function InitialLoading() {
  return (
    <div className="pageloader">
      <div className="div-block-15">
        <div className="gradouter">
          <div className="gradwrap">
            <div className="graddiv" />
          </div>
        </div>
        <img src={LogoText} loading="eager" alt="" className="image-2" />
      </div>
    </div>
  );
}
