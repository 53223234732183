import React, { useState } from "react";
import { toast } from "react-toastify";
import { CmsContent } from "../../api/CmsApi";
import { uploadMedia } from "../../api/MediaApi";
import { CmsContentType } from "../../enums/CmsContentType";
import { CmsItemType, getLabelForItemType } from "../../enums/CmsItemType";
import { Input } from "../Input";
import { Select } from "../Select";
import { OnSaveCmsItem } from "./CmsTable";
import Dialog from "../Dialog";
import { ContentEditor } from "./ContentEditor";
import { Checkbox } from "../Checkbox";
import { Loading } from "../Loading";
import { FaTrash } from "react-icons/fa";
import { CmsSection } from "../../enums/CmsSection";

export interface CmsFormInputData {
  label: string;
  value: string; // state variable
  valueSetter: (v: string) => void; // setter for state variable
  contentType: CmsContentType;
  contentId?: string;
  file?: File;
  setFile?: (f: File | undefined) => void;
  options?: { value: string; label: string }[];
  values?: string[];
  valuesSetter?: (v: string[]) => void;
  edit?: boolean;
  designSetter?: (v: string) => void;
  right?: boolean;
  validator?: (v: string) => string | null; // optional validator which returns error message or null if valid
  required?: boolean;
}

export type CmsFormProps = {
  onSave: OnSaveCmsItem;
  itemType?: CmsItemType;
  availableItemTypes?: CmsItemType[];
  showItemTypeSelect: boolean;
  contents?: CmsContent[]; // not used in CmsForm
  loading: boolean;
  setLoading: (l: boolean) => void;
  cmsSection: CmsSection;
};

function CmsSelect({
  label,
  values,
  valuesSetter,
  options,
}: {
  label: string;
  values: string[];
  valuesSetter: (v: string[]) => void;
  options: { value: string; label: string }[];
}) {
  return (
    <div className="mt-4">
      {values.map((item, index) => {
        return (
          <div className="flex items-center">
            <Select
              label={label}
              value={item}
              onChange={(newItem) => {
                valuesSetter(
                  values.map((v, i) => {
                    if (i === index) {
                      return newItem;
                    }

                    return v;
                  })
                );
              }}
              options={options}
            />
            <FaTrash
              onClick={() => {
                valuesSetter(values.filter((v, vi) => vi !== index));
              }}
              className="ml-4 pointer"
            />
          </div>
        );
      })}
      <div
        className="pointer mt-2"
        onClick={() => {
          valuesSetter(values.concat([""]));
        }}
      >
        + add tag
      </div>
    </div>
  );
}

function CmsContentField({
  inputData,
  editContent,
  setEditContent,
}: {
  inputData: CmsFormInputData;
  editContent: boolean;
  setEditContent: (v: boolean) => void;
}) {
  return (
    <div className="mt-4">
      <div
        className="button purple cta"
        onClick={() => {
          setEditContent(true);
        }}
      >
        Edit content
      </div>
      <Dialog
        isOpen={editContent}
        onRequestClose={() => {
          setEditContent(false);
        }}
        title={""}
        size={"full"}
        padding={false}
      >
        <ContentEditor
          design={JSON.parse(inputData.value)}
          save={(data) => {
            setEditContent(false);

            if (!inputData.designSetter) {
              return;
            }

            inputData.valueSetter(data.html);
            inputData.designSetter(data.design);
          }}
          cancel={() => {
            setEditContent(false);
          }}
        />
      </Dialog>
    </div>
  );
}

function CmsField({
  inputData,
  setEditContent,
  editContent,
}: {
  inputData: CmsFormInputData;
  setEditContent: (v: boolean) => void;
  editContent: boolean;
}) {
  const {
    label,
    value,
    valueSetter,
    contentType,
    setFile,
    options,
    values,
    valuesSetter,
    edit,
  } = inputData;

  if (contentType === CmsContentType.CONTENT) {
    return null;
  }

  if (options && values && valuesSetter) {
    return (
      <CmsSelect
        label={label}
        values={values}
        valuesSetter={valuesSetter}
        options={options}
      />
    );
  }

  if (options) {
    return (
      <div className="mt-4">
        <Select
          label={label}
          value={value}
          onChange={valueSetter}
          options={options}
        />
      </div>
    );
  }

  if (contentType === CmsContentType.CONTENT_OBJECT) {
    if (!edit) {
      return null;
    }

    return (
      <CmsContentField
        inputData={inputData}
        editContent={editContent}
        setEditContent={setEditContent}
      />
    );
  }

  if (
    contentType === CmsContentType.CHECKBOX ||
    contentType === CmsContentType.VIDEO_MUTED ||
    contentType === CmsContentType.VIDEO_CONTROLS
  ) {
    return (
      <Checkbox
        title={label}
        value={value === "true"}
        onClick={(v) => {
          valueSetter(String(v));
        }}
      />
    );
  }

  if (contentType == CmsContentType.DATE) {
    // convert ISO string to date
    const value =
      inputData.value !== ""
        ? new Date(inputData.value).toISOString().split("T")[0]
        : "";
    return (
      <div className="mt-4">
        <Input
          label={label}
          value={value}
          type="date"
          onChange={(val) => {
            // set date as ISO string
            const date = new Date(val);
            valueSetter(date.toISOString());
          }}
        />
      </div>
    );
  }

  return (
    <Input
      label={label}
      value={value}
      onChange={valueSetter}
      key={label}
      setFile={setFile}
      upload={
        contentType === CmsContentType.ASSET_URL ||
        contentType === CmsContentType.LOGO_URL
      }
      textArea={
        contentType === CmsContentType.DESCRIPTION ||
        contentType === CmsContentType.SHORT_DESCRIPTION ||
        contentType === CmsContentType.DESCRIPTION2
      }
    />
  );
}

async function saveCmsForm(
  inputData: CmsFormInputData[],
  cmsItemType: CmsItemType | undefined,
  saveCmsItem: OnSaveCmsItem,
  setLoading: (l: boolean) => void
) {
  for (let i = 0; i < inputData.length; i++) {
    const { validator, required } = inputData[i];
    if (validator && !(required === false && !inputData[i].value)) {
      const invalidMessage = validator(inputData[i].value);
      if (invalidMessage) {
        toast.error(invalidMessage, {
          position: "top-center",
        });
        return;
      }
    }
  }

  setLoading(true);

  const contents = [];
  for (const { value, contentId, contentType, file, values } of inputData) {
    let calculatedValue = value || "";

    if (contentType === CmsContentType.ASSET_URL && file) {
      calculatedValue = await uploadMedia(file);
    }

    if (contentType === CmsContentType.LOGO_URL && file) {
      calculatedValue = await uploadMedia(file);
    }

    if (contentType === CmsContentType.TAGS && values) {
      calculatedValue = JSON.stringify(values.filter((item) => item !== ""));
    }

    contents.push({
      value: calculatedValue,
      contentType: contentType,
      id: contentId,
    });
  }

  saveCmsItem(contents, cmsItemType);
}

export function CmsForm({
  onSave,
  inputData,
  itemType,
  availableItemTypes,
  showItemTypeSelect,
  loading,
  setLoading,
}: CmsFormProps & { inputData: CmsFormInputData[] }) {
  const [selectedItemType, setSelectedItemType] = useState<
    CmsItemType | undefined
  >(itemType || availableItemTypes?.[0]);
  const [editContent, setEditContent] = useState(false);

  return (
    <div>
      <div className="flex">
        <div className="mr-10">
          {inputData
            .filter((i) => !i.right)
            .map((i) => (
              <CmsField
                inputData={i}
                setEditContent={setEditContent}
                editContent={editContent}
              />
            ))}
        </div>
        <div className="ml-10">
          {inputData
            .filter((i) => i.right)
            .map(({ label, value, valueSetter, options }) => {
              if (options) {
                return (
                  <div className="mb-4">
                    <Select
                      label={label}
                      value={value}
                      onChange={valueSetter}
                      options={options}
                    />
                  </div>
                );
              }
            })}
        </div>
      </div>
      {showItemTypeSelect && (
        <Select
          className="mt-4"
          label="Item type"
          value={selectedItemType || CmsItemType.DEFAULT}
          onChange={(itemType) => setSelectedItemType(itemType as CmsItemType)}
          options={
            availableItemTypes?.map((itemType) => ({
              value: itemType,
              label: getLabelForItemType(itemType),
            })) || []
          }
        />
      )}
      {loading ? (
        <Loading />
      ) : (
        <div
          className="button mt-4"
          onClick={async () =>
            await saveCmsForm(inputData, selectedItemType, onSave, setLoading)
          }
        >
          Save
        </div>
      )}
    </div>
  );
}
