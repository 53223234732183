import React, { useEffect, useState } from "react";
import { User } from "../api/AuthApi";
import { Input } from "./Input";
import { Deal } from "../enums/Deal";
import ContactApi from "../api/ContactApi";
import { toast } from "react-toastify";
import { CmsItem } from "../api/CmsApi";
import { getContentValueForType } from "./cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";

export function RequestDealForm({
  user,
  close,
  deal,
  cmsItem,
}: {
  user: User;
  close: () => void;
  deal: Deal;
  cmsItem?: CmsItem;
}) {
  const [firstName, setFirstName] = useState(user.name);
  const [lastName, setLastName] = useState(user.surname);
  const [email, setEmail] = useState(user.email);
  const [phone, setPhone] = useState(user.phoneNumber || "");
  const [company, setCompany] = useState(user.companyInfo?.name || "");
  const [description, setDescription] = useState("");

  useEffect(() => {
    setFirstName(user.name);
    setLastName(user.surname);
    setEmail(user.email);
    setPhone(user.phoneNumber || "");
    setCompany(user.companyInfo?.name || "");
  }, [user]);

  return (
    <form
      className="request-form"
      onSubmit={async (e) => {
        e.preventDefault();

        if (deal === Deal.COMBELL_CLOUD) {
          await ContactApi.requestCombellCloud(
            `${firstName} ${lastName}`,
            phone,
            description,
            email,
            company
          );
        }

        if (deal === Deal.CONNUS) {
          await ContactApi.requestConnus(
            `${firstName} ${lastName}`,
            phone,
            description,
            email,
            company
          );
        }

        if (deal === Deal.BIZZY) {
          await ContactApi.requestBizzy(
            `${firstName} ${lastName}`,
            phone,
            description,
            email,
            company
          );
        }

        if (deal === Deal.GENERAL_DEAL_FORM && cmsItem) {
          await ContactApi.requestGeneralDealForm(
            `${firstName} ${lastName}`,
            phone,
            description,
            email,
            company,
            getContentValueForType(CmsContentType.EMAIL, cmsItem?.cmsContents)
          );
        }

        toast.info("Successfully submitted your request!", {
          position: toast.POSITION.BOTTOM_CENTER,
          hideProgressBar: true,
        });
        close();
      }}
    >
      <Input
        label={"First name"}
        type="text"
        value={firstName}
        onChange={setFirstName}
      />
      <Input
        label={"Last name"}
        type="text"
        value={lastName}
        onChange={setLastName}
      />
      <Input label={"Email"} type="email" value={email} onChange={setEmail} />
      <Input label={"Phone"} type="text" value={phone} onChange={setPhone} />
      <Input
        label={"Company"}
        type="text"
        value={company}
        onChange={setCompany}
      />
      <Input
        textArea={true}
        label={"Short description of the project"}
        value={description}
        onChange={setDescription}
      />
      <button type="submit" className="button cta uppercase mt-4">
        Request access
      </button>
    </form>
  );
}
