import React from "react";
import { isImage } from "../util";

export function ImageOrVideo({
  assetUrl,
  className,
  alt,
  controls = "true",
}: {
  assetUrl: string;
  className: string;
  alt: string;
  controls?: string;
}) {
  if (!assetUrl) {
    return null;
  }

  if (isImage(assetUrl)) {
    return (
      <img src={assetUrl} alt={alt} className={className} draggable={false} />
    );
  }

  return (
    <video
      key={assetUrl}
      className={className}
      controls={controls === "true"}
      autoPlay={true}
      muted={true}
      loop={true}
      playsInline={true}
      webkit-playsinline={true}
    >
      <source src={assetUrl} type="video/mp4" />
    </video>
  );
}
