import React, { HTMLInputTypeAttribute, useRef, useState } from "react";
import "./Input.css";
import { isImage } from "../util";

export function Input({
  label,
  value,
  onChange,
  className,
  type,
  color,
  required = true,
  upload,
  setFile,
  textArea,
  transparent,
  rows,
  pattern,
  title,
  autocomplete,
}: {
  label: string;
  value: string;
  onChange: (value: string) => void;
  className?: string;
  type?: HTMLInputTypeAttribute | undefined;
  color?: string;
  required?: boolean;
  upload?: boolean;
  setFile?: (value: File | undefined) => void;
  textArea?: boolean;
  transparent?: boolean;
  rows?: number;
  pattern?: string;
  title?: string;
  autocomplete?: string;
}) {
  const [hideAssetPreview, setHideAssetPreview] = useState(false);
  const ref = useRef<HTMLInputElement>(null);
  const inputOrTextArea = textArea ? (
    <textarea
      className={`mt-2 form__field ${color ? color : ""} ${
        transparent ? "transparent" : ""
      }`}
      placeholder={label}
      value={value}
      onChange={(e) => {
        if (onChange) onChange(e.target.value);
      }}
      rows={rows || 10}
      cols={55}
      required={required}
    />
  ) : (
    <input
      ref={ref}
      type={type}
      className={`form__field ${color ? color : ""} ${
        transparent ? "transparent" : ""
      }`}
      placeholder={label}
      value={value}
      onChange={(e) => onChange(e.target.value)}
      required={required}
      pattern={pattern}
      title={title}
      autoComplete={autocomplete}
    />
  );

  return (
    <div className={`form__group field ${className ? className : ""} flex`}>
      {upload && (
        <div className="flex flex-column w-100">
          <input
            id="asset-upload"
            className="mt-2"
            type="file"
            accept="image/*,video/*"
            onChange={(e) => {
              if (setFile && e.target.files) {
                // set file
                setFile(e.target.files[0]);

                // read file and set its url as img url
                const fr = new FileReader();
                fr.onload = () => onChange(fr.result?.toString() || "");
                fr.readAsDataURL(e.target.files[0]);
              }
            }}
          />
          <div
            className="button small"
            onClick={() => {
              if (setFile) {
                setFile(undefined);
              }
              onChange("");

              setHideAssetPreview(true);
            }}
          >
            Remove asset
          </div>
          {hideAssetPreview ? null : isImage(value) ? (
            <img className="input-img" src={value} alt="preview" />
          ) : (
            <video className="input-vid mt-4" controls={true}>
              <source src={value} type="video/mp4" />
            </video>
          )}
        </div>
      )}
      {!upload && inputOrTextArea}
      <label
        htmlFor="name"
        className={`form__label ${color ? color : ""}`}
        onClick={() => {
          if (ref.current) {
            ref.current.focus();
          }
        }}
      >
        {label}
      </label>
      {required ? <div className="required-asterix">*</div> : null}
    </div>
  );
}
