import React, { useContext, useEffect, useState } from "react";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { CmsSection } from "../enums/CmsSection";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import { ImageOrVideo } from "../components/ImageOrVideo";
import "./Academy.css";
import {
  getButtonColor,
  getCorrectColorIfSet,
  getTextColor,
} from "../enums/Color";
import { Button } from "../components/Button";
import AnimDiv from "../components/AnimDiv";

export function Academy() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItemSection1, setCmsItemSection1] = useState<CmsItem>();
  const [cmsItemSection2, setCmsItemSection2] = useState<CmsItem>();
  const [cmsItemSection3, setCmsItemSection3] = useState<CmsItem>();
  const [cmsItemSection4, setCmsItemSection4] = useState<CmsItem>();
  const [cmsItemSection5, setCmsItemSection5] = useState<CmsItem>();

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.ACADEMY_SECTION1,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection1(items[0]));
    CmsApi.getCmsItems(
      CmsSection.ACADEMY_SECTION2,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection2(items[0]));
    CmsApi.getCmsItems(
      CmsSection.ACADEMY_SECTION3,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection3(items[0]));
    CmsApi.getCmsItems(
      CmsSection.ACADEMY_SECTION4,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection4(items[0]));
    CmsApi.getCmsItems(
      CmsSection.ACADEMY_SECTION5,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection5(items[0]));
  }, []);

  return (
    <div className="corporates about academy">
      <AnimDiv className="corporate-hero">
        <ImageOrVideo
          assetUrl={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItemSection1?.cmsContents
          )}
          className={"image"}
          alt={"bg"}
        />
        <div className="content">
          <h2
            className={getTextColor(
              getCorrectColorIfSet(
                CmsSection.ACADEMY_SECTION1,
                CmsContentType.COLOR_TITLE,
                cmsItemSection1?.cmsContents
              ),
              true
            )}
          >
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsItemSection1?.cmsContents
            )}
          </h2>
          <div
            className={`text ${getTextColor(
              getCorrectColorIfSet(
                CmsSection.ACADEMY_SECTION1,
                CmsContentType.COLOR_DESCRIPTION,
                cmsItemSection1?.cmsContents
              ),
              false
            )}`}
          >
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemSection1?.cmsContents
            )}
          </div>
          {cmsItemSection1 ? (
            <Button
              section={CmsSection.ACADEMY_SECTION1}
              item={cmsItemSection1}
              className="ml-auto button-wrapper"
            />
          ) : null}
        </div>
      </AnimDiv>
      <AnimDiv className="mid-section">
        <div className="left-content flex flex-column">
          <h2
            className={getTextColor(
              getCorrectColorIfSet(
                CmsSection.ACADEMY_SECTION2,
                CmsContentType.COLOR_TITLE,
                cmsItemSection2?.cmsContents
              ),
              true
            )}
          >
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsItemSection2?.cmsContents
            )}
          </h2>
          <div
            className={`text ${getTextColor(
              getCorrectColorIfSet(
                CmsSection.ACADEMY_SECTION2,
                CmsContentType.COLOR_DESCRIPTION,
                cmsItemSection2?.cmsContents
              ),
              false
            )}`}
          >
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemSection2?.cmsContents
            )}
          </div>
          {cmsItemSection2 ? (
            <Button
              section={CmsSection.ACADEMY_SECTION2}
              item={cmsItemSection2}
              className="cta-button"
            />
          ) : null}
        </div>
        <div className="right-content">
          <div className="image-wrapper">
            <ImageOrVideo
              assetUrl={getContentValueForType(
                CmsContentType.ASSET_URL,
                cmsItemSection2?.cmsContents
              )}
              className={"image"}
              alt={"placeholder"}
            />
          </div>
        </div>
      </AnimDiv>
      <AnimDiv className="bottom">
        <ImageOrVideo
          assetUrl={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItemSection3?.cmsContents
          )}
          className={"image"}
          alt={"bg"}
        />
        <div className="content">
          <h2
            className={`content-title ${getTextColor(
              getCorrectColorIfSet(
                CmsSection.ACADEMY_SECTION3,
                CmsContentType.COLOR_TITLE,
                cmsItemSection3?.cmsContents
              ),
              true
            )}`}
          >
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsItemSection3?.cmsContents
            )}
          </h2>
          <div
            className={`${getTextColor(
              getCorrectColorIfSet(
                CmsSection.ACADEMY_SECTION3,
                CmsContentType.COLOR_DESCRIPTION,
                cmsItemSection3?.cmsContents
              ),
              false
            )}`}
          >
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemSection3?.cmsContents
            )}
          </div>
          <div className="flex buttons">
            {cmsItemSection3 ? (
              <Button
                section={CmsSection.ACADEMY_SECTION3}
                item={cmsItemSection3}
                className="ml-auto button-wrapper"
              />
            ) : null}
          </div>
        </div>
      </AnimDiv>
      <AnimDiv className="mid-section">
        <div className="left-content flex flex-column">
          <h2
            className={getTextColor(
              getCorrectColorIfSet(
                CmsSection.ACADEMY_SECTION4,
                CmsContentType.COLOR_TITLE,
                cmsItemSection4?.cmsContents
              ),
              true
            )}
          >
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsItemSection4?.cmsContents
            )}
          </h2>
          <div
            className={`text ${getTextColor(
              getCorrectColorIfSet(
                CmsSection.ACADEMY_SECTION4,
                CmsContentType.COLOR_DESCRIPTION,
                cmsItemSection4?.cmsContents
              ),
              false
            )}`}
          >
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemSection4?.cmsContents
            )}
          </div>
          {cmsItemSection4 ? (
            <Button
              section={CmsSection.ACADEMY_SECTION4}
              item={cmsItemSection4}
              className="cta-button"
            />
          ) : null}
        </div>
        <div className="right-content">
          <div className="image-wrapper">
            <ImageOrVideo
              assetUrl={getContentValueForType(
                CmsContentType.ASSET_URL,
                cmsItemSection4?.cmsContents
              )}
              className={"image"}
              alt={"placeholder"}
            />
          </div>
        </div>
      </AnimDiv>
      <AnimDiv className="bottom">
        <ImageOrVideo
          assetUrl={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItemSection5?.cmsContents
          )}
          className={"image"}
          alt={"bg"}
        />
        <div className="content">
          <h2
            className={`content-title ${getTextColor(
              getCorrectColorIfSet(
                CmsSection.ACADEMY_SECTION5,
                CmsContentType.COLOR_TITLE,
                cmsItemSection5?.cmsContents
              ),
              true
            )}`}
          >
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsItemSection5?.cmsContents
            )}
          </h2>
          <div
            className={`${getTextColor(
              getCorrectColorIfSet(
                CmsSection.ACADEMY_SECTION5,
                CmsContentType.COLOR_DESCRIPTION,
                cmsItemSection5?.cmsContents
              ),
              false
            )}`}
          >
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemSection5?.cmsContents
            )}
          </div>
          <div className="flex buttons">
            {cmsItemSection5 ? (
              <Button
                section={CmsSection.ACADEMY_SECTION5}
                item={cmsItemSection5}
                className="ml-auto button-wrapper"
              />
            ) : null}
          </div>
        </div>
      </AnimDiv>
    </div>
  );
}
