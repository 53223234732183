export const availableRoutes = {
  home: "/",
  faq: "/faq",
  information: "/information",
  informationDetail: "/information/:id",
  detail: "/detail/:id",
  inspiration: "/inspiration",
  inspirationDetail: "/inspiration/:id",
  training: "/training",
  trainingDetail: "/training/:id",
  adminInformationTable: "/admin/information",
  adminCoachTable: "/admin/coach",
  newAdminInformation: "/admin/information/new",
  editAdminInformation: "/admin/information/:id",
  footer: "/footer",
  header: "/header",
  login: "/login",
  forgotPassword: "/forgot-password",
  resetPassword: "/reset-password",
  confirmEmail: "/confirm",
  checkEmail: "/check-email",
  signup: "/signup",
  signupNext: "signup/:type",
  coaching: "/coaching",
  advice: "/advice",
  mentoring: "/mentoring",
  forCorporates: "/corporates",
  forHigherEdu: "/higher-education",
  forInvestors: "/investors",
  forMentors: "/mentors",
  forCorporatesDetail: "/corporates/:id",
  forHigherEduDetail: "/higher-education/:id",
  forInvestorsDetail: "/investors/:id",
  forMentorsDetail: "/mentors/:id",
  about: "/about-us",
  contact: "/contact",
  nathalieBooking: "/booking/nathalie",
  steveBooking: "/booking/steve",
  academy: "/academy",
  joinForces: "/join-forces",
  funding: "/funding",
  podcasts: "/podcasts",
  podcastsDetails: "/podcasts/:id",
  community: "/community",
  events: "/events",
  eventsDetail: "/events/:idOrSlug",
  startupCafe: "/startup-cafe",
  unbounded: "/unbounded",
  startSmart: "/start-smart",
  startSmartDetail: "/start-smart/:id",
  privacyPolicy: "/privacy-policy",
  auth: {
    calendly: "/oauth/calendly",
  },
  cms: {
    root: "/cms",
    home: "/cms/home",
    settings: "/cms/settings",
    information: "/cms/information",
    inspiration: "/cms/inspiration",
    tags: "/cms/tags",
    faq: "/cms/faq",
    coaching: "/cms/coaching",
    advice: "/cms/advice",
    mentoring: "/cms/mentoring",
    training: "/cms/training",
    corporates: "/cms/corporates",
    higherEdu: "/cms/higher-education",
    investors: "/cms/investors",
    mentors: "/cms/mentors",
    about: "/cms/about-us",
    contact: "/cms/contact",
    booking: "/cms/booking",
    academy: "/cms/academy",
    joinForces: "/cms/join-forces",
    funding: "/cms/funding",
    startSmart: "/cms/start-smart",
    podcasts: "/cms/podcasts",
    community: "/cms/community",
    events: "/cms/events",
    startupCafe: "/cms/startup-cafe",
    unbounded: "/cms/unbounded",
    login: "/cms/login",
    signup: "/cms/signup",
  },
};
