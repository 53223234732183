import React, { useContext, useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { toast } from "react-toastify";
import { AxiosError } from "axios";
import * as Sentry from "@sentry/react";
import "./Login.css";
import { Input } from "../components/Input";
import AuthApi from "../api/AuthApi";
import { availableRoutes } from "../constants/AvailableRoutes";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { CmsSection } from "../enums/CmsSection";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import { ImageOrVideo } from "../components/ImageOrVideo";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";
import AnimDiv from "../components/AnimDiv";

export function Login() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [cmsItem, setCmsItem] = useState<CmsItem>();
  const navigate = useNavigate();
  const { t } = useTranslation(["login", "common"]);

  async function submitLogin() {
    try {
      const { token, refreshToken, type } = await AuthApi.login(
        email,
        password
      );
      localStorage.setItem("token", token);
      localStorage.setItem("refreshToken", refreshToken);
      localStorage.setItem("type", type);
      navigate(availableRoutes.home);
    } catch (e) {
      Sentry.captureException(e);
      const msgI18nKey =
        e instanceof AxiosError && e.response?.status === 401
          ? "invalidCredentials"
          : "common:error";
      toast.error(t(msgI18nKey), {
        position: toast.POSITION.TOP_RIGHT,
        hideProgressBar: true,
      });
    }
  }

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.LOGIN_SECTION,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItem(items[0]));
  }, []);

  return (
    <AnimDiv className="login-container-wrapper flex">
      <div className="left-content">
        <ImageOrVideo
          assetUrl={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItem?.cmsContents
          )}
          className={"image"}
          alt={"bg"}
        />
        <div className="text color-white">
          {getContentValueForType(CmsContentType.TITLE, cmsItem?.cmsContents)}
        </div>
      </div>
      <div className="right-content">
        <div className="login-container">
          <h2>{t("common:login")}</h2>
          <div className="background-shadow flex">
            <form
              className="content"
              onSubmit={(e) => {
                e.preventDefault();
                submitLogin();
              }}
            >
              <Input
                label={t("common:email")}
                type="email"
                value={email}
                onChange={(e) => {
                  setEmail(e);
                }}
                required={true}
                autocomplete={"username"}
              />
              <Input
                label={t("common:password")}
                type="password"
                value={password}
                onChange={(e) => {
                  setPassword(e);
                }}
                required={true}
                autocomplete={"current-password"}
              />
              <div className="mt-4 mr-auto">
                <Link to={"/forgot-password"} className="forgot-password">
                  {t("login:forgotPassword")}
                </Link>
              </div>
              <div className="button-signup flex flex-row">
                <Link to={availableRoutes.signup} className="sign-up mt-auto">
                  {t("common:signup")}
                </Link>
                <button
                  className="button cta purple ml-auto mt-auto"
                  type="submit"
                >
                  {t("login:submit")}
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </AnimDiv>
  );
}
