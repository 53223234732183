import { FaPencilAlt, FaTrash } from "react-icons/fa";

export function ActionsRenderer({
  value,
  edit,
  remove,
  includeRemove,
}: {
  value: string;
  edit: (value: string) => void;
  remove: (value: string) => void;
  includeRemove: boolean;
}) {
  function editItem() {
    edit(value);
  }

  function removeItem() {
    remove(value);
  }

  return (
    <div className="flex">
      <div className="pointer" onClick={editItem}>
        <FaPencilAlt />
      </div>
      {includeRemove && (
        <div className="pointer ml-4" onClick={removeItem}>
          <FaTrash />
        </div>
      )}
    </div>
  );
}
