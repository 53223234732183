import React, { Fragment, PropsWithChildren, useEffect, useState } from "react";
import { Navigate, useLocation } from "react-router-dom";
import { getParsedToken } from "../util";

export function RedirectToPageIfNotLoggedIn({
  link,
  children,
}: PropsWithChildren<{ link: string }>) {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean | null>(null);
  const location = useLocation();

  useEffect(() => {
    setIsLoggedIn(getParsedToken() !== undefined);
  }, [location]);

  if (isLoggedIn === false) {
    return <Navigate to={link} />;
  }

  return <Fragment>{children}</Fragment>;
}
