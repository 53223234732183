import React, { useContext, useEffect, useState } from "react";
import "./InformationDetail.css";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";
import { useParams } from "react-router-dom";

export function InformationDetail() {
  const { id } = useParams();
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItem, setCmsItem] = useState<CmsItem>();

  useEffect(() => {
    if (!id) {
      return;
    }

    CmsApi.getCmsItem(id, selectedCountryAndLanguage.language).then((item) =>
      setCmsItem(item)
    );
  }, [id]);

  if (!cmsItem) {
    return null;
  }

  return (
    <div
      className="mt-4 mb-4 no-break"
      dangerouslySetInnerHTML={{
        __html: getContentValueForType(
          CmsContentType.CONTENT,
          cmsItem.cmsContents
        ),
      }}
    />
  );
}
