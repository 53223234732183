import React from "react";
import { useTranslation } from "react-i18next";
import "./PrivacyPolicy.css";

export function PrivacyPolicy() {
  const { t } = useTranslation("privacy-policy");

  return (
    <div className="privacy-policy flex flex-column">
      <h2>{t("privacyPolicy")}</h2>
      <p>Please read this privacy statement carefully.</p>
      <p>
        <b>Who we are</b>
      </p>
      StudentStartups Bv
      <br />
      Bruanestraat 8<br />
      8800 Roeselare
      <br />
      Belgium BE 0795.141.365
      <br />
      Founders; Steve Stevens (
      <a href="mailto:steve@studentstartups.eu">steve@studentstartups.eu</a>)
      and Nathalie Sintobin (
      <a href="mailto:nathalie@studentstartups.eu">
        nathalie@studentstartups.eu
      </a>
      ).
      <p>
        <b>Purpose of the company</b>
      </p>
      The target audience for whom we offer services are student entrepreneurs,
      PhD’s, alumni, higher education, ecosystems, businesses and investors
      within Europe. We provide services such as training, information and
      matchmaking. We connect young entrepreneurs by organizing online and
      offline events and building a community. We offer specialized acceleration
      programs where funding can also be provided in addition to advice.
      <p>
        <b>What data we collect</b>
      </p>
      The categories of personal data we collect may include:
      <ul>
        <li>
          <b>Identification data and contact information</b>: surname and first
          name, user name, address, telephone and/or mobile phone number, e-mail
          address, national register number, passport number, identity card
          number, copy of passport or identity card, company number.
        </li>
        <li>
          <b>Personal details and data</b>: gender, age, date of birth, place of
          birth, nationality, immigration status.
        </li>
        <li>
          <b>Images and audio-visual recordings</b>: photos, video on our
          website and social media, etc.
        </li>
        <li>
          <b>
            Data related to the provision of our products and services and the
            prospection thereof
          </b>
          : offers, invoices, payment details, products and services delivered,
          contracts, etc.
        </li>
        <li>
          <b>
            Marketing data and data concerning the use of our website and social
            media
          </b>
          : marketing preferences, opt-in and opt-out requests, participation in
          events organised by StudentStartups, posts on the social media
          applications we operate (instagram, blogs, forums, etc.), browser
          type, language preference, IP address, etc.
        </li>
      </ul>
      <p>
        Our products, services and website are not targeted or tailored to
        children.
      </p>
      <p>
        <b>How we collect your data</b>
      </p>
      We collect personal data in various ways, in particular personal data we
      receive:
      <ul>
        <li>from the data subject himself/herself;</li>
        <li>
          from the data subject's employer, for example when our customer or
          supplier is a company and the data subject is designated by this
          company as a contact person, when we conduct a due diligence, or when
          we need to process personal data as part of our services to the data
          subject's employer;
        </li>
        <li>
          from our customers, about their customers, suppliers and other data
          subjects whose personal data they collect;
        </li>
        <li>
          from other parties, e.g. government agencies or the data subject's
          advisers and other consultants, or when our customer provides
          information about their family members e.g. as part of our services;
        </li>
        <li>
          through the data subject's use of our website, social media and other
          tools;
        </li>
        <li>publicly available information.</li>
      </ul>
      <p>
        <b>How we use your data</b>
      </p>
      <p>
        The personal data we process always depends on the specific
        circumstances, e.g. the nature of the service or product we are required
        to provide, the legal obligations we are subject to, the legitimate
        interests we have to do so or the specific consent you have given us to
        do so.
      </p>
      <p>
        We have a legitimate interest in processing personal data of our
        customers or contact persons of our customers for the purpose of
        sending, by electronic means, advertising messages, newsletters,
        invitations to commercial events and the like, provided they relate to
        similar products and services. However, you have the right to object to
        it at any time, without prejudice to the lawfulness of processing based
        on legitimate interest before your exercise of right.
      </p>
      <p>
        In some cases, we have a <span className="bold">legal obligation</span>{" "}
        to process certain personal data in order to carry in the context of our
        professional activities, we have an obligation to collect certain
        information concerning our customers, their management and their
        stakeholders and, if applicable, to pass it on to the competent public
        authorities, e.g. the identification obligation under antimoney
        laundering and anti-fraud legislation.
      </p>
      <p>
        We process the following data on the basis of your{" "}
        <span className="bold">consent</span>:
      </p>
      <ul>
        <li>
          Direct Marketing: with your consent, we process your e-mail address
          and/or telephone number for marketing purposes.
        </li>
        <li>
          Cookie: we use essential cookie data when you navigate the website,
          and with your consent we use non-essential cookie data for marketing
          and analytics purposes.
        </li>
      </ul>
      <p>
        You have the right to withdraw your consent at any time, without
        prejudice to the lawfulness of processing based on consent before your
        withdrawal.
      </p>
      <p>
        <b>To whom do we disclose your personal data</b>
      </p>
      <p>
        As we are part of a global network, your personal data may be processed
        or accessed in the country/region where you use our products and
        services or in other countries/regions where StudentStartups and its
        members, its service providers, processors, consultants, commercial
        partners, or inspection services of governmental and judicial
        authorities are present.
      </p>
      <p>
        <b>How we secure your data</b>
      </p>
      <p>
        We do everything we can to protect your data in the best possible way.
        We use various security technologies and measures to ensure your
        privacy. In addition, we repeatedly review and evaluate these
        technologies and measures to keep your data safe.
      </p>
      <p>
        <b>Our external partners</b>
      </p>
      <p>
        We are technology – Website builder
        <br />
        <a href="https://www.we-are.be/" target="_blank">
          https://www.we-are.be/
        </a>
      </p>
      <p>
        Matomo
        <br />
        <a href="https://matomo.org/privacy-policy/" target="_blank">
          https://matomo.org/privacy-policy/
        </a>
      </p>
      <p>
        Mailchimp
        <br />
        <a href="https://mailchimp.com/about/security/" target="_blank">
          https://mailchimp.com/about/security/
        </a>
        <br />
        HQ Atlanta, GA 30308 USA
      </p>
      <p>
        Scaleway Hosting
        <br />
        <a href="https://www.scaleway.com/en/privacy-policy/" target="_blank">
          https://www.scaleway.com/en/privacy-policy/
        </a>
        <br />
        Amsterdam
      </p>
      <p>
        Hetzer
        <br />
        <a
          href="https://www.tischlerei-hetzer.eu/datenschutz_en.php"
          target="_blank"
        >
          https://www.tischlerei-hetzer.eu/datenschutz_en.php
        </a>
        <br />
        Neuremberg
      </p>
      <p>
        Amazon
        <br />
        <a href="https://aws.amazon.com/privacy/" target="_blank">
          https://aws.amazon.com/privacy/
        </a>
        <br />
        Frankfurt
      </p>
      <p>
        Youtube
        <br />
        <a
          href="https://www.youtube.com/intl/en_be/howyoutubeworks/our-commitments/protecting-user-data/"
          target="_blank"
        >
          https://www.youtube.com/intl/en_be/howyoutubeworks/our-commitments/protecting-user-data/
        </a>
      </p>
      <p>
        Spotify
        <br />
        <a
          href="https://www.spotify.com/nl/legal/live-privacy/"
          target="_blank"
        >
          https://www.spotify.com/nl/legal/live-privacy/
        </a>
      </p>
      <p>
        Retool
        <br />
        <a href="https://docs.retool.com/page/privacy-policy" target="_blank">
          https://docs.retool.com/page/privacy-policy
        </a>
        <br />
        San Francisco, CA 94102 HQ
      </p>
      <p>
        Combell
        <br />
        <a
          href="https://www.combell.com/en/about-combell/privacy-policy"
          target="_blank"
        >
          https://www.combell.com/en/about-combell/privacy-policy
        </a>
      </p>
      <p>
        <b>How to exercise your rights as a data subject</b>
      </p>
      <p>
        Depending on the processing and the legal basis, as a data subject you
        have a number of possibilities to keep control over your personal data:
      </p>
      <ul>
        <li>Right to access your data;</li>
        <li>Right to amend your data;</li>
        <li>Right to object to the processing of your personal data;</li>
        <li>Right to restrict data processing;</li>
        <li>Right to have your data erased;</li>
        <li>Right to withdraw your previously given consent;</li>
        <li>Right to transfer your data;</li>
        <li>
          Right to lodge complaints with the competent data protection
          authority.
        </li>
      </ul>
      <p>
        We should point out to you that these rights are not always absolute,
        that in certain circumstances we are entitled or even required by law to
        further process your personal data and that we may therefore not always
        be able to comply (fully) with your request. In such cases, we will
        inform you accordingly
      </p>
      <p>
        You may exercise these rights free of charge, except in cases of abuse
        and in which case we are entitled to charge an administration fee to
        comply with your request. If in our relationship with our customer, from
        whom we received your data as a data subject, we act only as a
        processor, we will inform you accordingly and you should address your
        request to exercise your rights to our customer.
      </p>
      <p>
        For all questions relating to the exercise of your rights, please
        contact:{" "}
        <a href="mailto:info@studentstartups.eu">info@studentstartups.eu</a>
      </p>
      <p>
        <b>Changes to this privacy statement</b>
      </p>
      <p>
        In connection with the further development of our website, products and
        services, as well as changes in applicable legislation, we may update
        this privacy statement. We therefore recommend that you read this
        privacy statement again from time to time. You can find the most recent
        version of this privacy statement on our website at any time.
      </p>
    </div>
  );
}
