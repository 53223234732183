import React, { useEffect, useState } from "react";
import { AgGridReact } from "ag-grid-react";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import { ActionsRenderer } from "../renderers/ActionsRenderer";
import SettingsApi, { CountryLanguages, Language } from "../../api/SettingsApi";
import Dialog from "../Dialog";
import { CountryLanguageForm } from "./CountryLanguageForm";
import { SETTING_COUNTRY_LANGUAGES } from "../../constants/SettingsKeys";
import { useTranslation } from "react-i18next";

export function CountryLanguageTable() {
  const [countryLanguages, setCountryLanguages] = useState<CountryLanguages[]>(
    []
  );
  const [create, setCreate] = useState(false);
  const [edit, setEdit] = useState<CountryLanguages | null>(null);
  const [deleteItem, setDeleteItem] = useState<CountryLanguages | null>(null);
  const { t } = useTranslation("common");

  useEffect(() => {
    SettingsApi.getCountryLanguages().then((result) =>
      setCountryLanguages(result)
    );
  }, []);

  const columnDefs = [
    {
      field: "country",
      filter: true,
      resizable: true,
      suppressSizeToFit: true,
    },
    {
      field: "languages",
      filter: true,
      resizable: true,
      cellRenderer: ({ value }: { value: Language[] }) =>
        value.map((l) => l.code).join(", "),
    },
    {
      field: "country",
      headerName: "Actions",
      width: 100,
      cellRenderer: ActionsRenderer,
      cellRendererParams: {
        edit: (field: string) => {
          setEdit(countryLanguages.find((cl) => cl.country === field) || null);
        },
        remove: (field: string) => {
          setDeleteItem(
            countryLanguages.find((s) => s.country === field) || null
          );
        },
        includeRemove: true,
      },
      filter: false,
      sortable: false,
      suppressSizeToFit: true,
    },
  ];

  const defaultColDef = {
    sortable: true,
    autosize: true,
  };

  return (
    <div>
      <div className="flex mb-4 items-center mt-4">
        <div className="title small">Countries & languages</div>
        <div
          className="button ml-auto"
          onClick={() => {
            setCreate(true);
          }}
        >
          Add country
        </div>
      </div>
      <div className="ag-theme-alpine" style={{ height: "800px" }}>
        <AgGridReact
          rowData={countryLanguages}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          animateRows={true}
          rowSelection="single"
          onGridReady={(grid) => {
            grid.api.sizeColumnsToFit();
          }}
        />
      </div>
      <Dialog
        isOpen={create || edit !== null}
        onRequestClose={() => {
          setCreate(false);
          setEdit(null);
        }}
        title={`${create ? "Add new" : "Edit"} country`}
        size={"small"}
        padding={true}
      >
        <CountryLanguageForm
          currentSelectedCountries={countryLanguages
            .filter((s) => s.country !== edit?.country)
            .map((s) => s.country)}
          save={async (data) => {
            if (data.languages.length === 0) {
              setEdit(null);
              setCreate(false);
              return;
            }

            try {
              if (edit !== null) {
                const newCountryLanguages = countryLanguages.map((cl) => {
                  if (cl.country === data.country) {
                    return {
                      country: data.country,
                      languages: data.languages.map((l) => ({
                        code: l.code,
                        live: l.live,
                      })),
                    };
                  }

                  return cl;
                });

                await SettingsApi.updateSettings(
                  SETTING_COUNTRY_LANGUAGES,
                  newCountryLanguages
                );
                setCountryLanguages(newCountryLanguages);
              }

              if (create) {
                const concattedSetting = countryLanguages.concat(data);
                await SettingsApi.updateSettings(
                  SETTING_COUNTRY_LANGUAGES,
                  concattedSetting
                );
                setCountryLanguages(concattedSetting);
              }

              setEdit(null);
              setCreate(false);
              window.location.reload();
            } catch (e) {
              Sentry.captureException(e);
              toast.error(t("error"), {
                position: toast.POSITION.TOP_CENTER,
                hideProgressBar: true,
              });
            }
          }}
          defaultValue={edit || undefined}
        />
      </Dialog>
      <Dialog
        isOpen={deleteItem !== null}
        onRequestClose={() => {
          setDeleteItem(null);
        }}
        title={"Are you sure you want to delete this?"}
        size={"small"}
        padding={true}
      >
        <div className="flex">
          <div
            className="button purple"
            onClick={async () => {
              if (!deleteItem) {
                return;
              }

              try {
                const filteredCountryLanguages = countryLanguages.filter(
                  (s) => s.country !== deleteItem.country
                );
                await SettingsApi.updateSettings(
                  SETTING_COUNTRY_LANGUAGES,
                  filteredCountryLanguages
                );
                setCountryLanguages(filteredCountryLanguages);
                setDeleteItem(null);
                window.location.reload();
              } catch (e) {
                Sentry.captureException(e);
                toast.error(t("error"), {
                  position: toast.POSITION.TOP_CENTER,
                  hideProgressBar: true,
                });
              }
            }}
          >
            Delete
          </div>
          <div
            className="button ml-4"
            onClick={() => {
              setDeleteItem(null);
            }}
          >
            Cancel
          </div>
        </div>
      </Dialog>
    </div>
  );
}
