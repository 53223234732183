export const languages = [
  { name: "Afrikaans", code: "af" },
  { name: "Albanian", code: "sq" },
  { name: "Amharic", code: "am" },
  { name: "Arabic", code: "ar" },
  { name: "Aragonese", code: "an" },
  { name: "Armenian", code: "hy" },
  { name: "Asturian", code: "ast" },
  { name: "Azerbaijani", code: "az" },
  { name: "Basque", code: "eu" },
  { name: "Belarusian", code: "be" },
  { name: "Bengali", code: "bn" },
  { name: "Bosnian", code: "bs" },
  { name: "Breton", code: "br" },
  { name: "Bulgarian", code: "bg" },
  { name: "Catalan", code: "ca" },
  { name: "Central Kurdish", code: "ckb" },
  { name: "Chinese", code: "zh" },
  { name: "Chinese (Hong Kong)", code: "zh-HK" },
  { name: "Chinese (Simplified)", code: "zh-CN" },
  { name: "Chinese (Traditional)", code: "zh-TW" },
  { name: "Corsican", code: "co" },
  { name: "Croatian", code: "hr" },
  { name: "Czech", code: "cs" },
  { name: "Danish", code: "da" },
  { name: "Dutch", code: "nl" },
  { name: "English", code: "en" },
  { name: "English (Australia)", code: "en-AU" },
  { name: "English (Canada)", code: "en-CA" },
  { name: "English (India)", code: "en-IN" },
  { name: "English (New Zealand)", code: "en-NZ" },
  { name: "English (South Africa)", code: "en-ZA" },
  { name: "English (United Kingdom)", code: "en-GB" },
  { name: "English (United States)", code: "en-US" },
  { name: "Esperanto", code: "eo" },
  { name: "Estonian", code: "et" },
  { name: "Faroese", code: "fo" },
  { name: "Filipino", code: "fil" },
  { name: "Finnish", code: "fi" },
  { name: "French", code: "fr" },
  { name: "French (Canada)", code: "fr-CA" },
  { name: "French (France)", code: "fr-FR" },
  { name: "French (Switzerland)", code: "fr-CH" },
  { name: "Galician", code: "gl" },
  { name: "Georgian", code: "ka" },
  { name: "German", code: "de" },
  { name: "German (Austria)", code: "de-AT" },
  { name: "German (Germany)", code: "de-DE" },
  { name: "German (Liechtenstein)", code: "de-LI" },
  { name: "German (Switzerland)", code: "de-CH" },
  { name: "Greek", code: "el" },
  { name: "Guarani", code: "gn" },
  { name: "Gujarati", code: "gu" },
  { name: "Hausa", code: "ha" },
  { name: "Hawaiian", code: "haw" },
  { name: "Hebrew", code: "he" },
  { name: "Hindi", code: "hi" },
  { name: "Hungarian", code: "hu" },
  { name: "Icelandic", code: "is" },
  { name: "Indonesian", code: "id" },
  { name: "Interlingua", code: "ia" },
  { name: "Irish", code: "ga" },
  { name: "Italian", code: "it" },
  { name: "Italian (Italy)", code: "it-IT" },
  { name: "Italian (Switzerland)", code: "it-CH" },
  { name: "Japanese", code: "ja" },
  { name: "Kannada", code: "kn" },
  { name: "Kazakh", code: "kk" },
  { name: "Khmer", code: "km" },
  { name: "Korean", code: "ko" },
  { name: "Kurdish", code: "ku" },
  { name: "Kyrgyz", code: "ky" },
  { name: "Lao", code: "lo" },
  { name: "Latin", code: "la" },
  { name: "Latvian", code: "lv" },
  { name: "Lingala", code: "ln" },
  { name: "Lithuanian", code: "lt" },
  { name: "Macedonian", code: "mk" },
  { name: "Malay", code: "ms" },
  { name: "Malayalam", code: "ml" },
  { name: "Maltese", code: "mt" },
  { name: "Marathi", code: "mr" },
  { name: "Mongolian", code: "mn" },
  { name: "Nepali", code: "ne" },
  { name: "Norwegian", code: "no" },
  { name: "Norwegian Bokmål", code: "nb" },
  { name: "Norwegian Nynorsk", code: "nn" },
  { name: "Occitan", code: "oc" },
  { name: "Oriya", code: "or" },
  { name: "Oromo", code: "om" },
  { name: "Pashto", code: "ps" },
  { name: "Persian", code: "fa" },
  { name: "Polish", code: "pl" },
  { name: "Portuguese", code: "pt" },
  { name: "Portuguese (Brazil)", code: "pt-BR" },
  { name: "Portuguese (Portugal)", code: "pt-PT" },
  { name: "Punjabi", code: "pa" },
  { name: "Quechua", code: "qu" },
  { name: "Romanian", code: "ro" },
  { name: "Romanian (Moldova)", code: "mo" },
  { name: "Romansh", code: "rm" },
  { name: "Russian", code: "ru" },
  { name: "Scottish Gaelic", code: "gd" },
  { name: "Serbian", code: "sr" },
  { name: "Serbo", code: "sh" },
  { name: "Shona", code: "sn" },
  { name: "Sindhi", code: "sd" },
  { name: "Sinhala", code: "si" },
  { name: "Slovak", code: "sk" },
  { name: "Slovenian", code: "sl" },
  { name: "Somali", code: "so" },
  { name: "Southern Sotho", code: "st" },
  { name: "Spanish", code: "es" },
  { name: "Spanish (Argentina)", code: "es-AR" },
  { name: "Spanish (Latin America)", code: "es-419" },
  { name: "Spanish (Mexico)", code: "es-MX" },
  { name: "Spanish (Spain)", code: "es-ES" },
  { name: "Spanish (United States)", code: "es-US" },
  { name: "Sundanese", code: "su" },
  { name: "Swahili", code: "sw" },
  { name: "Swedish", code: "sv" },
  { name: "Tajik", code: "tg" },
  { name: "Tamil", code: "ta" },
  { name: "Tatar", code: "tt" },
  { name: "Telugu", code: "te" },
  { name: "Thai", code: "th" },
  { name: "Tigrinya", code: "ti" },
  { name: "Tongan", code: "to" },
  { name: "Turkish", code: "tr" },
  { name: "Turkmen", code: "tk" },
  { name: "Twi", code: "tw" },
  { name: "Ukrainian", code: "uk" },
  { name: "Urdu", code: "ur" },
  { name: "Uyghur", code: "ug" },
  { name: "Uzbek", code: "uz" },
  { name: "Vietnamese", code: "vi" },
  { name: "Walloon", code: "wa" },
  { name: "Welsh", code: "cy" },
  { name: "Western Frisian", code: "fy" },
  { name: "Xhosa", code: "xh" },
  { name: "Yiddish", code: "yi" },
  { name: "Yoruba", code: "yo" },
  { name: "Zulu", code: "zu" },
];
