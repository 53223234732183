import React, { useContext, useEffect, useState } from "react";
import "./CmsSidebar.css";
import { CmsCountryLanguageContext } from "../../context/CmsCountryLanguageContext";
import SettingsApi, { CountryLanguages } from "../../api/SettingsApi";
import { countries } from "../../constants/Countries";

export function CmsSidebar() {
  const [countryLanguages, setCountryLanguages] = useState<CountryLanguages[]>(
    []
  );
  const { selectedCountryAndLanguage, setCountryAndLanguage } = useContext(
    CmsCountryLanguageContext
  );

  useEffect(() => {
    SettingsApi.getCountryLanguages().then((result) =>
      setCountryLanguages(result)
    );
  }, []);

  return (
    <div className="cms-sidebar">
      {countryLanguages.map((cl, index) => {
        const foundCountry = countries.find(
          (country) => country.code === cl.country
        );
        return (
          <div key={index}>
            <div className="country">{foundCountry?.name}</div>
            {cl.languages.map((language, index) => {
              return (
                <div
                  key={index}
                  className={`language ${
                    selectedCountryAndLanguage.language === language.code &&
                    selectedCountryAndLanguage.country === cl.country
                      ? "selected"
                      : null
                  }`}
                  onClick={() => {
                    setCountryAndLanguage({
                      country: cl.country,
                      language: language.code,
                    });
                  }}
                >
                  {language.code}
                </div>
              );
            })}
          </div>
        );
      })}
    </div>
  );
}
