import React, { useState } from "react";
import { CmsHeader } from "../../components/cms/CmsHeader";
import { CmsSidebar } from "../../components/cms/CmsSidebar";
import { CmsTable } from "../../components/cms/CmsTable";
import { CmsContentType } from "../../enums/CmsContentType";
import { CmsSection } from "../../enums/CmsSection";
import { CopyPopup } from "../../components/cms/CopyPopup";

export function CmsCommunity() {
  const [openCopy, setOpenCopy] = useState(false);

  return (
    <div>
      <CmsHeader />
      <div className="flex">
        <CmsSidebar />
        <div className="container">
          <div className="flex mb-4 items-center mt-4">
            <div className="title">Community</div>
            <div
              className="button small cta purple ml-4"
              onClick={() => {
                setOpenCopy(true);
              }}
            >
              Copy content
            </div>
          </div>
          <CmsTable
            sectionName="Section 1"
            section={CmsSection.COMMUNITY_SECTION1}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 2"
            section={CmsSection.COMMUNITY_SECTION2}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 3"
            section={CmsSection.COMMUNITY_SECTION3}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
          <CmsTable
            sectionName="Section 4"
            section={CmsSection.COMMUNITY_SECTION4}
            columnContentNames={["Title"]}
            columnContentTypes={[CmsContentType.TITLE]}
            singleItem={true}
            showItemTypeSelect={false}
          />
        </div>
      </div>
      <CopyPopup
        page={"Community"}
        open={openCopy}
        setOpen={setOpenCopy}
        cmsSections={[
          CmsSection.COMMUNITY_SECTION1,
          CmsSection.COMMUNITY_SECTION2,
          CmsSection.COMMUNITY_SECTION3,
          CmsSection.COMMUNITY_SECTION4,
        ]}
      />
    </div>
  );
}
