import axios, { AxiosError } from "axios";
import { Config } from "../Config";

declare module "axios" {
  export interface AxiosRequestConfig {
    retry?: boolean;
  }
}

class ApiInstance {
  public getApi() {
    const endpointsWithoutAuthentication = [
      "/auth/login",
      "/auth/refresh",
      "/auth/register-account",
      "/auth/confirm",
      "/auth/request-password-reset",
      "/auth/confirm-password-reset",
    ];

    const instance = axios.create({
      baseURL: Config.getBackendUrl(),
      headers: {
        "Content-Type": "application/json",
      },
    });

    instance.interceptors.request.use(async (config) => {
      const token = localStorage.getItem("token");
      const type = localStorage.getItem("type");
      if (!config.url) {
        return config;
      }

      if (
        token &&
        type &&
        !endpointsWithoutAuthentication.includes(config.url) &&
        config.headers
      ) {
        config.headers["Authorization"] = `${type} ${token}`;
      }

      return config;
    });

    instance.interceptors.response.use(
      (response) => response,
      async (error: AxiosError) => {
        const { config } = error;
        const refreshToken = localStorage.getItem("refreshToken");
        if (
          config &&
          !config.retry &&
          refreshToken &&
          error.response?.status === 401
        ) {
          config.retry = true;
          const {
            data: { token, refreshToken: newRefreshToken, type },
          } = await instance.post("/auth/refresh", {
            refreshToken,
          });

          localStorage.setItem("token", token);
          localStorage.setItem("refreshToken", newRefreshToken);

          config.headers["Authorization"] = `${type} ${token}`;

          return instance(config);
        }

        throw error;
      }
    );

    return instance;
  }
}

export default new ApiInstance();
