import React, { useState } from "react";
import { CmsItem } from "../api/CmsApi";
import { getContentValueForType } from "./cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";
import CaretDown from "../assets/caretDown.svg";
import CaretUp from "../assets/caretUp.svg";
import { motion } from "framer-motion";

export function FaqItem({ cmsItem }: { cmsItem: CmsItem }) {
  const [open, setOpen] = useState(false);
  return (
    <motion.div
      layout
      transition={{
        type: "spring",
        stiffness: 100,
        damping: 10,
      }}
      className="faq-item"
      onClick={() => {
        setOpen(!open);
      }}
    >
      <div>
        {getContentValueForType(CmsContentType.TITLE, cmsItem.cmsContents)}
      </div>
      {open ? (
        <div
          className="faq-description"
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {getContentValueForType(
            CmsContentType.DESCRIPTION,
            cmsItem.cmsContents
          )}
        </div>
      ) : null}
      {open ? (
        <img src={CaretUp} alt={"up"} />
      ) : (
        <img src={CaretDown} alt={"down"} />
      )}
    </motion.div>
  );
}
