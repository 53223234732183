import React, { Fragment, useContext, useEffect, useState } from "react";
import { toast } from "react-toastify";
import * as Sentry from "@sentry/react";
import "./StartSmart.css";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { CmsSection } from "../enums/CmsSection";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";
import { ImageOrVideo } from "../components/ImageOrVideo";
import { Link } from "react-router-dom";
import { availableRoutes } from "../constants/AvailableRoutes";
import AuthApi, { User } from "../api/AuthApi";
import { getParsedToken } from "../util";
import CrossIcon from "../assets/cross.svg";
import Dialog from "../components/Dialog";
import DealsApi from "../api/DealsApi";
import { LoginPopup } from "../components/LoginPopup";
import { useTranslation } from "react-i18next";
import { Checkbox } from "../components/Checkbox";
import { RevokedPopup } from "../components/RevokedPopup";
import { RedirectToPageIfNoPermission } from "../components/RedirectToPageIfNoPermission";
import { Role } from "../enums/Role";
import AnimDiv from "../components/AnimDiv";
import { Input } from "../components/Input";

export function StartSmart() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItemSection1, setCmsItemSection1] = useState<CmsItem>();
  const [cmsItemTitle, setCmsItemTitle] = useState<CmsItem>();
  const [cmsDealsPopup, setCmsDealsPopup] = useState<CmsItem>();
  const [cmsItemDeals, setCmsItemDeals] = useState<CmsItem[]>();
  const [user, setUser] = useState<User | null>(null);
  const [requestAccess, setRequestAccess] = useState(false);
  const { t } = useTranslation("common");
  const [loginRequired, setLoginRequired] = useState(false);
  const [revoked, setRevoked] = useState(false);
  const [accept, setAccept] = useState(false);
  const [error, setError] = useState("");
  const [discountInput, setDiscountInput] = useState<boolean>(false);
  const [discountCode, setDiscountCode] = useState<string>("");

  function PurpleBox() {
    return (
      <div className="box alternative">
        <RedirectToPageIfNoPermission
          link={availableRoutes.home}
          permission={
            !user ||
            user?.role === Role.STUDENT ||
            user?.role === Role.PHD ||
            user?.role === Role.ALUMNUS ||
            user?.role === Role.ADMIN ||
            user?.role === Role.HIGHER_EDU
          }
        />
        <div className="box-title color-white">Join our program</div>
        <div className="text">
          By joining our Start Smart program, startups can benefit from a range
          of services that are tailored to their specific needs at reduced
          prices. Ready to take your startup to the next level? We invite you to
          join our program!
        </div>
        <div className="flex items-center mt-auto">
          <div
            className="button cta uppercase color-white ml-auto mr-auto"
            onClick={() => {
              if (user && user.dealsPermissionRevoked) {
                setRevoked(true);
                return;
              }

              if (!getParsedToken()) {
                setLoginRequired(true);
              } else {
                setRequestAccess(true);
              }
            }}
          >
            Join now
          </div>
        </div>
      </div>
    );
  }

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.START_SMART_SECTION1,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection1(items[0]));

    CmsApi.getCmsItems(
      CmsSection.START_SMART_HEAD,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemTitle(items[0]));

    CmsApi.getCmsItems(
      CmsSection.DEAL,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemDeals(items));

    CmsApi.getCmsItems(
      CmsSection.DEALS_POPUP,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsDealsPopup(items[0]));

    AuthApi.getCurrentUser()
      .then((result) => {
        setUser(result);
      })
      .catch((e) => {
        Sentry.captureException(e);
        toast.error(t("error"), {
          position: toast.POSITION.TOP_CENTER,
          hideProgressBar: true,
        });
      });
  }, []);

  useEffect(() => {
    const dealsPopupCheckboxText = getContentValueForType(
      CmsContentType.DESCRIPTION2,
      cmsDealsPopup?.cmsContents
    );

    if (!dealsPopupCheckboxText) {
      setAccept(true);
    } else {
      setAccept(false);
    }
  }, [cmsDealsPopup]);

  const dealsPopupCheckboxText = getContentValueForType(
    CmsContentType.DESCRIPTION2,
    cmsDealsPopup?.cmsContents
  );

  return (
    <div className="start-smart">
      <AnimDiv className="section-1">
        <div className="left">
          <h2>
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsItemSection1?.cmsContents
            )}
          </h2>
          <div className="content">
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemSection1?.cmsContents
            )}
          </div>
        </div>
        <div className="right">
          <ImageOrVideo
            assetUrl={getContentValueForType(
              CmsContentType.ASSET_URL,
              cmsItemSection1?.cmsContents
            )}
            className={"image"}
            alt={"bg"}
          />
        </div>
      </AnimDiv>
      <AnimDiv className="title-wrapper">
        <h2>
          {getContentValueForType(
            CmsContentType.TITLE,
            cmsItemTitle?.cmsContents
          )}
        </h2>
      </AnimDiv>
      <AnimDiv className="box-wrapper">
        {cmsItemDeals?.length === 0 && getParsedToken() ? <PurpleBox /> : null}
        {cmsItemDeals?.map((item, index) => {
          const buttonText = getContentValueForType(
            CmsContentType.CALL_TO_ACTION_TEXT,
            item?.cmsContents
          );

          return (
            <Fragment>
              {index === 2 && !getParsedToken() ? (
                <div className="flex flex-column login-overlay">
                  <Link
                    to={availableRoutes.login}
                    className="button cta color-primary bg-white"
                  >
                    Log in
                  </Link>
                  <div className="or-subtitle">
                    or{" "}
                    <Link
                      className="no-decoration color-black sign-up-subtitle"
                      to={availableRoutes.signup}
                    >
                      Sign up
                    </Link>
                  </div>
                </div>
              ) : null}
              <div
                className={`box ${
                  index !== 0 && index !== 1 && !getParsedToken()
                    ? "blurred"
                    : ""
                }`}
              >
                <div className="top-wrapper">
                  <div>
                    <div className="box-title">
                      {getContentValueForType(
                        CmsContentType.TITLE,
                        item?.cmsContents
                      )}
                    </div>
                    <div className="box-sector">
                      {getContentValueForType(
                        CmsContentType.TOPIC,
                        item?.cmsContents
                      )}
                    </div>
                  </div>
                  <div className="image-wrapper">
                    <ImageOrVideo
                      assetUrl={getContentValueForType(
                        CmsContentType.LOGO_URL,
                        item?.cmsContents
                      )}
                      className={"image"}
                      alt={"bg"}
                    />
                  </div>
                </div>
                <div className="text">
                  {getContentValueForType(
                    CmsContentType.SHORT_DESCRIPTION,
                    item?.cmsContents
                  )}
                </div>
                <div className="flex items-center mt-auto">
                  <div
                    className={`price ${!getParsedToken() ? "blurred" : ""}`}
                  >
                    {getContentValueForType(
                      CmsContentType.SUBTITLE2,
                      item?.cmsContents
                    )}
                  </div>
                  {(user && user.permittedForDeals) ||
                  (user && user.role === Role.HIGHER_EDU) ? (
                    !buttonText ? null : (
                      <Link
                        to={`${availableRoutes.startSmart}/${item.id}`}
                        className="button cta uppercase purple ml-auto"
                      >
                        {getContentValueForType(
                          CmsContentType.CALL_TO_ACTION_TEXT,
                          item?.cmsContents
                        )}
                      </Link>
                    )
                  ) : (
                    <div
                      className={`button cta uppercase purple ml-auto ${
                        index > 1 ? "disable-hover" : ""
                      }`}
                      onClick={() => {
                        if (user && user.dealsPermissionRevoked) {
                          setRevoked(true);
                          return;
                        }

                        if (!getParsedToken() && index > 2) {
                          return;
                        }

                        if (!getParsedToken()) {
                          setLoginRequired(true);
                        } else {
                          setRequestAccess(true);
                        }
                      }}
                    >
                      Join now
                    </div>
                  )}
                </div>
              </div>
              {(index === 2 ||
                (index == cmsItemDeals?.length - 1 &&
                  cmsItemDeals?.length < 4)) &&
              getParsedToken() &&
              !user?.permittedForDeals &&
              user?.role !== Role.HIGHER_EDU ? (
                <PurpleBox />
              ) : null}
            </Fragment>
          );
        })}
      </AnimDiv>
      <Dialog
        isOpen={requestAccess}
        onRequestClose={() => {
          setRequestAccess(false);
        }}
        title={""}
        size={"small"}
        padding={false}
        overflow={false}
      >
        <div className="country-popup">
          <h2>
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsDealsPopup?.cmsContents
            )}
          </h2>
          <img
            className="close pointer"
            src={CrossIcon}
            alt="close"
            onClick={() => {
              setRequestAccess(false);
              setDiscountInput(false);
            }}
          />
          <div className="content program">
            <div>
              {getContentValueForType(
                CmsContentType.DESCRIPTION,
                cmsDealsPopup?.cmsContents
              )}
            </div>
            {dealsPopupCheckboxText ? (
              <Checkbox
                title={dealsPopupCheckboxText}
                value={accept}
                onClick={setAccept}
                className="disclaimer"
              />
            ) : null}
            <div className={"flex flex-column"}>
              <div
                className={"pointer mr-1"}
                onClick={() => {
                  setDiscountInput(!discountInput);
                  setDiscountCode("");
                }}
              >
                {getContentValueForType(
                  CmsContentType.SUBTITLE,
                  cmsDealsPopup?.cmsContents
                )}
              </div>
              {discountInput ? (
                <Input
                  type={"text"}
                  value={discountCode}
                  onChange={(e) => setDiscountCode(e)}
                  label={"Discount code"}
                />
              ) : null}
            </div>

            <div
              className="button uppercase cta purple mt-4 mb-4"
              onClick={async () => {
                if (!accept) {
                  setError("Please accept our terms.");
                  return;
                }

                if (!user) {
                  setError("");
                  setAccept(false);
                  setRequestAccess(false);
                  return;
                }

                let discountCodeValid = false;
                if (discountInput && discountCode.length > 0) {
                  const responseDiscount =
                    await DealsApi.getDiscountCodeValidity(discountCode);
                  discountCodeValid = responseDiscount.valid;
                }

                if (
                  !discountCodeValid &&
                  discountInput &&
                  discountCode.length !== 0
                ) {
                  setError("Invalid discount code");
                  return;
                } else if (
                  !discountCodeValid &&
                  discountInput &&
                  discountCode.length === 0
                ) {
                  setError(
                    "No code was found. Please enter a code or close the input field."
                  );
                  return;
                } else {
                  await DealsApi.updateDealsPermission(
                    true,
                    user?.id,
                    discountInput ? discountCode : undefined
                  );
                  const newUser = await AuthApi.getCurrentUser();
                  setUser(newUser);
                  setRequestAccess(false);
                  setAccept(false);
                  setDiscountCode("");
                  setDiscountInput(false);
                  setError("");
                }
              }}
            >
              {getContentValueForType(
                CmsContentType.CALL_TO_ACTION_TEXT,
                cmsDealsPopup?.cmsContents
              )}
            </div>
            <div className="color-red">{error}</div>
          </div>
        </div>
      </Dialog>
      <LoginPopup open={loginRequired} setOpen={setLoginRequired} />
      <RevokedPopup open={revoked} setOpen={setRevoked} />
    </div>
  );
}
