import React, { useContext, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { CmsSection } from "../enums/CmsSection";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";
import { useNavigate, useSearchParams } from "react-router-dom";
import { snakeCase } from "../util";
import { availableRoutes } from "../constants/AvailableRoutes";
import { Grid } from "../components/Grid";
import AnimDiv from "../components/AnimDiv";
import { jsonLdObjects } from "../constants/JsonLdObjects";
import { Helmet } from "react-helmet";

export function Events() {
  const [params] = useSearchParams();
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItems, setCmsItems] = useState<CmsItem[]>();
  const [cmsTags, setCmsTags] = useState<CmsItem[]>();
  const [selectedTags, setSelectedTags] = useState<string[]>(
    params
      .get("tags")
      ?.split(",")
      .filter((item) => item) || []
  );
  const navigate = useNavigate();
  const { t } = useTranslation();

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.EVENTS,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItems(items));

    CmsApi.getCmsItems(
      CmsSection.EVENTS_TAGS,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsTags(items));
  }, []);

  useEffect(() => {
    const params = new URLSearchParams();
    params.set("tags", selectedTags.join(","));
    navigate(`${availableRoutes.events}?${params.toString()}`, {
      replace: true,
    });
  }, [selectedTags]);

  const sizes = ["w3-3", "w2-3", "w1-3", "w1-3", "w2-3"];

  const eventsJsonLdData =
    cmsItems?.map((item) => jsonLdObjects.event(item.id, item)) || [];

  return (
    <div className="information">
      <AnimDiv className="container flex flex-column">
        <h2>{t("header-footer:events")}</h2>
        <div className=" topics-wrapper">
          {cmsTags?.map((item) => {
            const title = getContentValueForType(
              CmsContentType.TITLE,
              item.cmsContents
            );
            const tag = snakeCase(title);
            const contains = selectedTags.includes(tag);

            return (
              <div
                className={`topic-item ${contains ? " active" : ""}`}
                onClick={() => {
                  if (contains) {
                    setSelectedTags(selectedTags.filter((t) => t !== tag));
                  } else {
                    setSelectedTags(selectedTags.concat(tag));
                  }
                }}
              >
                {title}
              </div>
            );
          })}
        </div>
      </AnimDiv>
      <div className=" bg" />
      <Grid
        pattern={sizes}
        items={
          cmsItems
            ?.filter((item) => {
              const tags: string[] = JSON.parse(
                getContentValueForType(CmsContentType.TAGS, item.cmsContents)
              );
              const mappedTags = tags.map(snakeCase);

              let contains = true;

              if (selectedTags.length > 0) {
                let containsCount = 0;
                contains = false;

                for (const tag of selectedTags) {
                  if (mappedTags.includes(tag)) {
                    containsCount += 1;
                  }
                }

                if (containsCount === selectedTags.length) {
                  contains = true;
                }
              }

              return contains;
            })
            .map((item, i) => {
              const size = sizes[i % sizes.length];
              const ctaButtonText = getContentValueForType(
                CmsContentType.CALL_TO_ACTION_TEXT,
                item.cmsContents
              );
              const ctaButtonUrl = getContentValueForType(
                CmsContentType.CALL_TO_ACTION_URL,
                item.cmsContents
              );
              let assetUrl = getContentValueForType(
                CmsContentType.ASSET_URL,
                item.cmsContents
              );
              const topic = getContentValueForType(
                CmsContentType.TOPIC,
                item.cmsContents
              );
              const title = getContentValueForType(
                CmsContentType.TITLE,
                item.cmsContents
              );
              const description = getContentValueForType(
                CmsContentType.DESCRIPTION,
                item.cmsContents
              );
              const content = getContentValueForType(
                CmsContentType.CONTENT,
                item.cmsContents
              );
              const checkbox =
                getContentValueForType(
                  CmsContentType.CHECKBOX,
                  item.cmsContents
                ) === " true";

              if (size === " w1-3" && !checkbox) {
                assetUrl = "";
              }

              return {
                id: item.id,
                title: title,
                assetUrl: assetUrl,
                buttonText: ctaButtonText,
                buttonUrl: ctaButtonUrl,
                topic: topic,
                description: description,
                content: content,
              };
            }) || []
        }
        defaultRoute={availableRoutes.events}
      />
      <Helmet>
        <script type="application/ld+json">
          {JSON.stringify(eventsJsonLdData)}
        </script>
      </Helmet>
    </div>
  );
}
