import React, { useState } from "react";
import { HeaderLinkItem } from "./Header";
import { NavLink } from "react-router-dom";
import { useTranslation } from "react-i18next";
import "./LinkWithSubmenu.css";
import CaretDown from "../assets/headerCaretDown.svg";
import { HeaderLinkVisibility } from "../api/SettingsApi";
import { getVisibleLinkItems } from "../util";
import { isMobile } from "react-device-detect";
import { motion } from "framer-motion";

export function LinkWithSubmenu({
  item,
  className,
  headerLinkVisibility,
  selectedCountryAndLanguage,
  mobile,
  onMouseEnter,
  onMouseLeave,
}: {
  item: HeaderLinkItem;
  className: string;
  headerLinkVisibility: HeaderLinkVisibility | null;
  selectedCountryAndLanguage: { country: string; language: string };
  mobile?: boolean;
  onMouseEnter?: React.MouseEventHandler<HTMLDivElement>;
  onMouseLeave?: React.MouseEventHandler<HTMLDivElement>;
}) {
  const [open, setOpen] = useState(false);
  const { t } = useTranslation(["header-footer", "common"]);

  const availableSubmenuItems = item.submenu
    ? getVisibleLinkItems(
        headerLinkVisibility,
        item.submenu,
        selectedCountryAndLanguage
      )
    : [];

  if (mobile === undefined) {
    mobile = isMobile;
  }

  return (
    <div
      className={`submenu-wrapper ${className}`}
      onMouseLeave={(event) => {
        if (onMouseLeave) {
          onMouseLeave(event);
        }

        if (availableSubmenuItems.length === 0) {
          return;
        }

        setOpen(false);
      }}
      onMouseEnter={(event) => {
        if (onMouseEnter) {
          onMouseEnter(event);
        }

        if (!mobile && availableSubmenuItems.length > 0) {
          setOpen(true);
        }
      }}
      onClick={() => {
        if (mobile && availableSubmenuItems.length > 0) {
          setOpen(true);
        }
      }}
    >
      {mobile && availableSubmenuItems.length > 0 ? (
        <div
          className={`${className} ${
            availableSubmenuItems.length > 0 ? "no-margin" : ""
          }`}
        >
          {t(item.name)}
        </div>
      ) : (
        <NavLink to={item.route} className={className}>
          {t(item.name)}
        </NavLink>
      )}
      {availableSubmenuItems.length > 0 ? (
        <img src={CaretDown} className="ml-2" alt={"submenu"} />
      ) : null}
      {item.submenu && open ? (
        <motion.div
          className={`submenu${mobile ? " mobile" : ""}`}
          animate={{ height: "fit-content" }}
          transition={{ duration: 0.5 }}
        >
          <div className="submenu-content">
            {getVisibleLinkItems(
              headerLinkVisibility,
              mobile ? [item].concat(item.submenu) : item.submenu,
              selectedCountryAndLanguage
            ).map((menuItem) => {
              return (
                <NavLink className="submenu-item" to={menuItem.route}>
                  {t(menuItem.name)}
                </NavLink>
              );
            })}
          </div>
        </motion.div>
      ) : null}
    </div>
  );
}
