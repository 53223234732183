import React, { Fragment, useContext, useEffect, useState } from "react";
import * as Sentry from "@sentry/react";
import "./Footer.css";
import { Input } from "./Input";
import Logo from "../assets/logo.png";
import { BsInstagram } from "react-icons/bs";
import TiktokLogo from "../assets/tiktok.svg";
import LinkedinLogo from "../assets/linkedin.svg";
import { Link, useNavigate } from "react-router-dom";
import { Trans, useTranslation } from "react-i18next";
import { availableRoutes } from "../constants/AvailableRoutes";
import { HeaderLink } from "../constants/headerLinks";
import SettingsApi, { HeaderLinkVisibility } from "../api/SettingsApi";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import { Checkbox } from "./Checkbox";
import { getParsedToken } from "../util";
import NewsletterApi from "../api/NewsletterApi";
import { Role } from "../enums/Role";
import CountrySelector from "./CountrySelector";
import { toast } from "react-toastify";

export function Footer() {
  const { t } = useTranslation(["header-footer", "common"]);
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [headerLinkVisibility, setHeaderLinkVisibility] =
    useState<HeaderLinkVisibility | null>(null);
  const [acceptPrivacyPolicy, setAcceptPrivacyPolicy] = useState(false);
  const [email, setEmail] = useState("");
  const navigate = useNavigate();

  function getLinkIfVisible(link: HeaderLink, route: string, bold: boolean) {
    return (
      headerLinkVisibility &&
      headerLinkVisibility[selectedCountryAndLanguage.country] &&
      headerLinkVisibility[selectedCountryAndLanguage.country][link] && (
        <Link to={route} className={`text-navigation ${bold ? "bold" : ""}`}>
          {t(link)}
        </Link>
      )
    );
  }

  useEffect(() => {
    SettingsApi.getHeaderLinkVisibility().then((result) =>
      setHeaderLinkVisibility(result)
    );
  }, []);

  async function subscribeToNewsletter() {
    if (acceptPrivacyPolicy) {
      try {
        await NewsletterApi.subscribeToNewsletter(email);
        setEmail("");
        setAcceptPrivacyPolicy(false);
        toast.info("Successfully subscribed to newsletter!", {
          position: toast.POSITION.BOTTOM_CENTER,
          hideProgressBar: true,
        });
      } catch (e) {
        Sentry.captureException(e);
        toast.error(
          "Something went wrong while subscribing to newsletter, please try again.",
          {
            position: toast.POSITION.BOTTOM_CENTER,
            hideProgressBar: true,
          }
        );
      }
    } else {
      toast.error("Please tick the box to accept the privacy policy.", {
        position: toast.POSITION.BOTTOM_CENTER,
        hideProgressBar: true,
      });
    }
  }

  return (
    <div className="footer-container">
      <div className="footer-container-top">
        <div className="footer-container-content">
          <div className="footer-inner-container">
            <div className="footer-container-connect flex flex-column justify-center">
              <Link to={availableRoutes.home}>
                <img className="logo" src={Logo} alt={"Student Startups"} />
              </Link>
              <div className="sub-title">{t("letsConnect")}</div>
              <form
                onSubmit={(e) => {
                  e.preventDefault();
                  subscribeToNewsletter();
                }}
              >
                <Input
                  type="email"
                  value={email}
                  onChange={(e) => setEmail(e)}
                  color="color-white"
                  label="Email"
                  transparent={true}
                />
                <div className="checkbox-button-container">
                  <Checkbox
                    className={"white-checkbox"}
                    title={
                      <Trans i18nKey="acceptPrivacyPolicy" t={t}>
                        <Link
                          to={availableRoutes.privacyPolicy}
                          className="text-checkbox color-white"
                          target={"_blank"}
                          onClick={(e) => {
                            e.stopPropagation();
                          }}
                        />
                        ,
                      </Trans>
                    }
                    value={acceptPrivacyPolicy}
                    onClick={setAcceptPrivacyPolicy}
                  />
                  <button className="button color-white">
                    {t("subscribe")}
                  </button>
                </div>
              </form>
            </div>
            <div className="footer-container-information">
              <div className="social-logo-box flex flex-row">
                <a
                  href={"https://www.instagram.com/studentstartups.eu/"}
                  target="_blank"
                >
                  <BsInstagram className="social-logo" />
                </a>

                <a
                  href={"https://www.tiktok.com/@studentstartups.eu"}
                  target="_blank"
                >
                  <img
                    src={TiktokLogo}
                    className="social-logo"
                    alt={"tiktok"}
                  />
                </a>
                <a
                  href={
                    "https://www.linkedin.com/company/studentstartups-europe/"
                  }
                  target="_blank"
                >
                  <img
                    src={LinkedinLogo}
                    className="social-logo"
                    alt={"linkedin"}
                  />
                </a>
              </div>
              <div className="text-information">Bruanestraat 8</div>
              <div className="text-information mb-4">{t("cityCountry")}</div>
              <a
                className="text-information"
                href="mailto:info@studentstartups.eu"
              >
                info@studentstartups.eu
              </a>
            </div>
          </div>
          <div className="footer-container-navigation-wrapper">
            <div className="footer-container-navigation flex flex-row">
              <div className="navigation-column flex flex-column">
                {getLinkIfVisible(
                  "forCorporates",
                  availableRoutes.forCorporates,
                  false
                )}
                {getLinkIfVisible(
                  "forHigherEdu",
                  availableRoutes.forHigherEdu,
                  false
                )}
                {getLinkIfVisible(
                  "forInvestors",
                  availableRoutes.forInvestors,
                  false
                )}
              </div>
              <div className="navigation-column flex flex-column">
                {getLinkIfVisible(
                  "forMentors",
                  availableRoutes.forMentors,
                  false
                )}
                <Link to={availableRoutes.about} className="text-navigation">
                  {t("aboutUs")}
                </Link>
                <Link to={availableRoutes.faq} className="text-navigation">
                  FAQ
                </Link>
              </div>
              <div className="navigation-column flex flex-column">
                <Link
                  to={availableRoutes.privacyPolicy}
                  className="text-navigation"
                  target={"_blank"}
                >
                  {t("privacy")}
                </Link>
                {getLinkIfVisible("contact", availableRoutes.contact, false)}
              </div>
            </div>
            <div className="footer-container-navigation flex flex-row">
              <div className="navigation-column flex flex-column">
                {getLinkIfVisible(
                  "information",
                  availableRoutes.information,
                  true
                )}
                {getLinkIfVisible(
                  "inspiration",
                  availableRoutes.inspiration,
                  true
                )}
                {getLinkIfVisible("academy", availableRoutes.academy, true)}
              </div>
              <div className="navigation-column flex flex-column">
                {getParsedToken() &&
                getParsedToken()?.role !== Role.STUDENT &&
                getParsedToken()?.role !== Role.PHD &&
                getParsedToken()?.role !== Role.ALUMNUS &&
                getParsedToken()?.role !== Role.ADMIN
                  ? null
                  : getLinkIfVisible(
                      "startSmart",
                      availableRoutes.startSmart,
                      true
                    )}
                {getLinkIfVisible(
                  "matchmaking",
                  availableRoutes.joinForces,
                  true
                )}
                {getLinkIfVisible("unbounded", availableRoutes.unbounded, true)}
              </div>
              <div className="navigation-column flex flex-column">
                {getLinkIfVisible("funding", availableRoutes.funding, true)}
                {getLinkIfVisible("community", availableRoutes.community, true)}
              </div>
              <div className="navigation-column gap flex flex-column">
                {!getParsedToken() ? (
                  <Fragment>
                    <Link
                      to={availableRoutes.login}
                      className="button blue color-primary flex ml-auto"
                    >
                      {t("common:login")}
                    </Link>
                    <Link
                      to={availableRoutes.signup}
                      className="color-white flex ml-auto no-decoration"
                    >
                      {t("common:orSignup")}
                    </Link>
                  </Fragment>
                ) : (
                  <div
                    className="button blue color-primary flex ml-auto"
                    onClick={() => {
                      navigate("/");
                      localStorage.removeItem("token");
                      localStorage.removeItem("refreshToken");
                      localStorage.removeItem("type");
                    }}
                  >
                    {t("common:logout")}
                  </div>
                )}
              </div>
            </div>
          </div>
          <div className="flex justify-end">
            <CountrySelector />
          </div>
        </div>
      </div>
      <div className="footer-container-bottom justify-center items-center flex">
        <div className="copyright">&copy; Student Startups Europe 2023</div>
      </div>
    </div>
  );
}
