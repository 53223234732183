import { HeaderLink } from "../constants/headerLinks";
import { SETTING_COUNTRY_LANGUAGES } from "../constants/SettingsKeys";
import ApiInstance from "./ApiInstance";

type JsonValue =
  | string
  | number
  | boolean
  | { [x: string]: JsonValue }
  | JsonValue[]
  | null;

export type Language = {
  code: string;
  live: boolean;
};

export type CountryLanguages = {
  country: string;
  languages: Language[];
};

export type VisibilityForCountry = {
  [link in HeaderLink]: boolean;
};

export type HeaderLinkVisibility = {
  [country: string]: VisibilityForCountry;
};

class SettingsApi {
  public async updateSettings(key: string, value: JsonValue): Promise<void> {
    await ApiInstance.getApi().put(`/settings/${key}`, {
      value: JSON.stringify(value),
    });
  }

  public async getSetting(key: string): Promise<JsonValue> {
    const result = await ApiInstance.getApi().get(`/settings/${key}`);
    return result.data.value;
  }

  public async getCountryLanguages(): Promise<CountryLanguages[]> {
    return (await this.getSetting("country-languages")) as CountryLanguages[];
  }

  public async getHeaderLinkVisibility(): Promise<HeaderLinkVisibility> {
    return (await this.getSetting(
      "header-link-visibility"
    )) as HeaderLinkVisibility;
  }
}

export default new SettingsApi();
