import React, { useState } from "react";
import { Select } from "../Select";
import { countries } from "../../constants/Countries";
import { VisibilityForCountry } from "../../api/SettingsApi";
import { headerLinks, headerLinkToLabel } from "../../constants/headerLinks";

function getDefaultVisibility(): VisibilityForCountry {
  return {
    forCorporates: true,
    forHigherEdu: true,
    forInvestors: true,
    forMentors: true,
    faq: true,
    information: true,
    academy: true,
    startSmart: true,
    matchmaking: true,
    unbounded: true,
    funding: true,
    events: true,
    inspiration: true,
    mentoring: true,
    coaching: true,
    advice: true,
    training: true,
    community: true,
    podcasts: true,
    startupCafe: true,
    contact: true,
  };
}

export function HeaderLinkForm({
  save,
  initialVisibility,
  initialCountry,
  currentSelectedCountries,
}: {
  save: (visibility: VisibilityForCountry, country: string) => void;
  initialVisibility?: VisibilityForCountry;
  initialCountry?: string;
  currentSelectedCountries: string[];
}) {
  const [visibility, setVisibility] = useState<VisibilityForCountry>(
    initialVisibility || getDefaultVisibility()
  );
  const [country, setCountry] = useState(initialCountry || "BE");

  return (
    <div>
      <Select
        label={"Select country"}
        value={country}
        onChange={(e) => {
          setCountry(e);
        }}
        options={[{ label: "", value: "" }].concat(
          countries
            .filter((c) => !currentSelectedCountries.includes(c.code))
            .map((c) => {
              return { label: c.name, value: c.code };
            })
        )}
      />
      <br />
      <b>Set link visibilities</b>
      <div>
        {headerLinks.map((link, index) => {
          return (
            <div key={index} className="flex items-center">
              <input
                className="mr-2"
                type="checkbox"
                checked={visibility[link]}
                onChange={() => {
                  const newVisibility = { ...visibility };
                  newVisibility[link] = !newVisibility[link];
                  setVisibility(newVisibility);
                }}
              />
              <span>{headerLinkToLabel[link]}</span>
            </div>
          );
        })}
      </div>
      <div
        className="button mt-4"
        onClick={() => {
          save(visibility, country);
        }}
      >
        Save
      </div>
    </div>
  );
}
