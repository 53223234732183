import React from "react";
import { NavLink } from "react-router-dom";
import { availableRoutes } from "../../constants/AvailableRoutes";

export function CmsHeader() {
  const menu = [
    { route: availableRoutes.cms.home, name: "Home" },
    { route: availableRoutes.cms.information, name: "Information" },
    { route: availableRoutes.cms.inspiration, name: "Inspiration" },
    { route: availableRoutes.cms.academy, name: "Academy" },
    { route: availableRoutes.cms.training, name: "Training" },
    { route: availableRoutes.cms.coaching, name: "Coaching" },
    { route: availableRoutes.cms.mentoring, name: "Mentoring" },
    { route: availableRoutes.cms.advice, name: "Advice" },
    { route: availableRoutes.cms.startSmart, name: "Start Smart" },
    { route: availableRoutes.cms.joinForces, name: "Join Forces" },
    { route: availableRoutes.cms.unbounded, name: "Unbounded" },
    { route: availableRoutes.cms.funding, name: "Funding" },
    { route: availableRoutes.cms.community, name: "Community" },
    { route: availableRoutes.cms.events, name: "Events" },
    { route: availableRoutes.cms.podcasts, name: "Podcasts" },
    { route: availableRoutes.cms.startupCafe, name: "Startups Café" },
    { route: availableRoutes.cms.corporates, name: "For corporates" },
    { route: availableRoutes.cms.higherEdu, name: "For higher eduction" },
    { route: availableRoutes.cms.investors, name: "For investors" },
    { route: availableRoutes.cms.mentors, name: "For mentors" },
    { route: availableRoutes.cms.faq, name: "FAQ" },
    { route: availableRoutes.cms.contact, name: "Contact" },
    { route: availableRoutes.cms.about, name: "About us" },
    { route: availableRoutes.cms.booking, name: "Booking" },
    { route: availableRoutes.cms.tags, name: "Tags" },
    { route: availableRoutes.cms.login, name: "Login" },
    { route: availableRoutes.cms.signup, name: "Sign Up" },
    { route: availableRoutes.cms.settings, name: "Settings" },
  ];

  return (
    <div className="header">
      <div
        className="top-navigation flex flex-row"
        style={{ overflow: "auto" }}
      >
        {menu.map((item) => {
          return (
            <NavLink to={item.route} className="text-top-navigation">
              {item.name}
            </NavLink>
          );
        })}
      </div>
      <hr />
    </div>
  );
}
