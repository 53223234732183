import React, { useContext, useEffect, useState } from "react";
import { getContentValueForType } from "../components/cms/getContentForType";
import { CmsContentType } from "../enums/CmsContentType";
import CmsApi, { CmsItem } from "../api/CmsApi";
import { CmsSection } from "../enums/CmsSection";
import { UserCountryLanguageContext } from "../context/UserCountryLanguageContext";
import { ImageOrVideo } from "../components/ImageOrVideo";
import "./AboutUs.css";
import { getCorrectColorIfSet, getTextColor } from "../enums/Color";
import { Button } from "../components/Button";
import AnimDiv from "../components/AnimDiv";

export function AboutUs() {
  const { selectedCountryAndLanguage } = useContext(UserCountryLanguageContext);
  const [cmsItemHero, setCmsItemHero] = useState<CmsItem>();
  const [cmsItemsSection1, setCmsItemsSection1] = useState<CmsItem[]>();
  const [cmsItemSection2, setCmsItemSection2] = useState<CmsItem>();

  useEffect(() => {
    CmsApi.getCmsItems(
      CmsSection.ABOUT_HERO,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemHero(items[0]));

    CmsApi.getCmsItems(
      CmsSection.ABOUT_SECTION1,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemsSection1(items));

    CmsApi.getCmsItems(
      CmsSection.ABOUT_SECTION2,
      selectedCountryAndLanguage.country,
      selectedCountryAndLanguage.language
    ).then((items) => setCmsItemSection2(items[0]));
  }, []);

  return (
    <div className="corporates about">
      <AnimDiv className="corporate-hero">
        <ImageOrVideo
          assetUrl={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItemHero?.cmsContents
          )}
          className={"image"}
          alt={"bg"}
        />
        <div className="content">
          <h2
            className={getTextColor(
              getCorrectColorIfSet(
                CmsSection.ABOUT_HERO,
                CmsContentType.COLOR_TITLE,
                cmsItemHero?.cmsContents
              ),
              true
            )}
          >
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsItemHero?.cmsContents
            )}
          </h2>
          <div
            className={`text ${getTextColor(
              getCorrectColorIfSet(
                CmsSection.ABOUT_HERO,
                CmsContentType.COLOR_DESCRIPTION,
                cmsItemHero?.cmsContents
              ),
              false
            )}`}
          >
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemHero?.cmsContents
            )}
          </div>
          {cmsItemHero ? (
            <Button
              section={CmsSection.ABOUT_HERO}
              item={cmsItemHero}
              className="ml-auto button-wrapper"
            />
          ) : null}
        </div>
      </AnimDiv>
      <AnimDiv className="about-section-1-wrapper">
        <div className="about-section-1">
          {cmsItemsSection1?.map((item) => {
            return (
              <div className="box">
                <h2>
                  {getContentValueForType(
                    CmsContentType.TITLE,
                    item.cmsContents
                  )}
                </h2>
                <div className="content">
                  {getContentValueForType(
                    CmsContentType.DESCRIPTION,
                    item.cmsContents
                  )}
                </div>
              </div>
            );
          })}
        </div>
      </AnimDiv>
      <AnimDiv className="bottom">
        <ImageOrVideo
          assetUrl={getContentValueForType(
            CmsContentType.ASSET_URL,
            cmsItemSection2?.cmsContents
          )}
          className={"image"}
          alt={"bg"}
        />
        <div className="content">
          <div className="content-title">
            {getContentValueForType(
              CmsContentType.TITLE,
              cmsItemSection2?.cmsContents
            )}
          </div>
          <div>
            {getContentValueForType(
              CmsContentType.DESCRIPTION,
              cmsItemSection2?.cmsContents
            )}
          </div>
          <div className="flex buttons">
            <div className="button cta purple color-white uppercase mr-auto">
              contact us
            </div>
          </div>
        </div>
      </AnimDiv>
    </div>
  );
}
